import axios from "axios"

export const LOGIN = 'auth/login'
export const ROLE = 'role/all'
export const USER = 'user'
export const USER_GOAL = 'user-goal'
export const USER_INFO_HIS = 'user-info-history'
export const REGISTER = 'auth/register'
export const COMMON = 'common'
export const LEAGUE = 'league'
export const TEAM = 'team'
export const GROUP = 'group'
export const TEAM_RANK = 'team-rank'
export const MATCH = 'match'
export const PLAYER = 'player'
export const USERTURN = 'user-his-turn'
export const MATCH_RESULT = 'match-result'
export const EVENT1 = 'event1-his'
export const EVENT2 = 'event2'
export const EVENT3 = 'event3-his'
export const MINIGAME_RESULT = 'minigame-result'
export const USER_RANK = 'user-rank'
export const GOAL_HISTORY = 'user-his-goal'
export const POINT_HISTORY = 'user-send-point'
export const EVENT1_QUESTION = 'event1-question'
export const EVENT1_ANSWER = 'event1-answer'
export const USER_EVENT2 = 'user-event2'
export const USER_EVENT1 = 'user-event1'
//--------------

export const Apis = {
  '2q': '',
  // '888b': 'http://localhost:5009/api/v1',
  '888b': 'https://api-pred.syp400.com/api/v1',
  '8day': 'https://api-pred.fnp476.com/api/v1',
};

const API = axios.create({
  baseURL: Apis[process.env.REACT_APP_BRAND],
  timeout: 100000,
});

const auth = localStorage.getItem('auth')
if (auth) {
  API.defaults.headers.common['Authorization'] = JSON.parse(auth).tokenType + ' ' + JSON.parse(auth).accessToken

  API.interceptors.response.use(undefined, err => {
    const error = err.response;

    if (error.status === 401 || error.status === 403 || error.data.message.message === 'LoggedOnAnotherDevice') {
      localStorage.clear()
      window.location.assign('/login')
    }
  });
}

export default API
