import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Select, Spin, Pagination } from 'antd';
import API, { GOAL_HISTORY } from '../../network/api';
import moment from 'moment';
import 'moment-timezone';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';

const { Option } = Select;

const GoalsHistory = () => {

  const auth = JSON.parse(localStorage.getItem('auth'))
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [dataFilter, setDataFilter] = useState()
  const [dataList, setDataList] = useState([])
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState()
  const [dataReport, setDataReport] = useState();

  const locale = {
    emptyText: t('text0035')
  }

  const sttReceived = 
    auth?.role === "supper" ? [
      {
        id: 0,
        value: "1",
        label: 'Đã nhận'
      },
      {
        id: 0,
        value: "0",
        label: 'Bù/trừ'
      },
    ] : [
      {
        id: 0,
        value: "1",
        label: 'Đã nhận'
      },
    ]

  useEffect(() => {
    const getDataReport = async() => {
      await API.get(`${GOAL_HISTORY}/report`).then(response => {
        const data = response.data?.data?.result
        if (data) setDataReport(data)
      }).catch(error => {
        console.log(error)
      })
    }
    
    getDataReport();
  }, []);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    getDataHis()
  }, [page, dataFilter]);

  const onChangeDateFrom = (date, dateString) => {
    setDateFrom(dateString)
  }

  const onChangeDateTo = (date, dateString) => {
    setDateTo(dateString)
  }

  const getDataHis = async () => {
    setLoading(false)

    const keyword = (dataFilter?.username || dataFilter?.startDate || dataFilter?.endDate || dataFilter?.status) ?
      encodeURIComponent(JSON.stringify({ username: dataFilter?.username, startDate: dataFilter?.startDate, endDate: dataFilter?.endDate, status: dataFilter?.status })) : null
    let URL = `${GOAL_HISTORY}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

    const listData1 = API.get(URL)

    await Promise.all([listData1]).then(response => {
      const res1 = response[0].data?.data?.result[0]
      setTotalRecord(response[0].data?.data?.result[1])

      if (res1) {
        let dataCV = []

        res1?.map((item, index) => {
          let itemGame = {
            key: index,
            id: item?.id,
            username: item?.user?.username,
            goal: item?.goal,
            detail: 
              item?.detail === "Bù/trừ" ? 
              item?.goal > 0 ? `Bù ${item?.goal} bàn thắng` : 
              `Trừ ${-1 * item?.goal} bàn thắng` : item?.detail,
            status: item?.detail === "Bù/trừ" ? "Bù/trừ" : "Đã nhận",
            createdAt: item?.createdAt,
          }
          dataCV.push(itemGame)
        });
        setDataList(dataCV)
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const getPredStr = (listFilter, id1, id2) => {
    return listFilter.find(t => t.id === id1)?.name + ', ' + listFilter.find(t => t.id === id2)?.name
  }

  const onSearch = () => {
    let params = {}

    if (form.getFieldValue("usernameFilter")) params.username = form.getFieldValue("usernameFilter").trim();
    if (dateFrom) {
      params.startDate = dateFrom
      if (!dateTo) params.endDate = "2123-12-31"
    }
    if (dateTo) {
      if (!dateFrom) params.startDate = "2024-07-31"
      params.endDate = dateTo
    }
    if (status)
      params.status = status

    setDataFilter(params ? params : undefined)
    setPage(1)
  }

  const tableColums = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      width: 100,
      render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>
    },
    {
      title: t('text0002'),
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: t('text0091'),
      key: 'goal',
      dataIndex: 'goal'
    },
    {
      title: 'Chi tiết',
      key: 'detail',
      dataIndex: 'detail'
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status'
    },
    {
      title: 'Thời gian nhận',
      key: 'createdAt',
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
    },
  ]

  const dollarUS = Intl.NumberFormat("en-US");

  const handleDownloadExcel = async () => {
    setLoading(false)

    const keyword = (dataFilter?.username || dataFilter?.startDate || dataFilter?.endDate || dataFilter?.status) ?
      encodeURIComponent(JSON.stringify({ username: dataFilter?.username, startDate: dataFilter?.startDate, endDate: dataFilter?.endDate, status: dataFilter?.status })) : null
    let URL = `${GOAL_HISTORY}/search?take=10000000&skip=1&order=DESC&keyword=${keyword}`

    const listData1 = API.get(URL)

    await Promise.all([listData1]).then(response => {
      const res1 = response[0].data?.data?.result[0]
      setTotalRecord(response[0].data?.data?.result[1])

      if (res1) {
        let dataCV = []

        res1?.map((item, index) => {
          let itemGame = {
            username: item?.user?.username,
            goal: item?.goal,
            detail: 
              item?.detail === "Bù/trừ" ? 
              item?.goal > 0 ? `Bù ${item?.goal} bàn thắng` : 
              `Trừ ${-1 * item?.goal} bàn thắng` : item?.detail,
            createdAt: moment(item?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
            status: item?.detail === "Bù/trừ" ? "Bù/trừ" : "Đã nhận",
          }
          dataCV.push(itemGame)
        });
        exportToExcel(dataCV);

      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const exportToExcel = (data) => {
    const currentTime = moment().format("DD-MM-YYYY HH-mm-ss");
    const name = `Lịch sử nhận bàn thắng_${currentTime}`;

    const formattedData = data.map(item => ({
      'Tên đăng nhập': item.username,
      'Bàn thắng': item.goal,
      'Chi tiết': item.detail,
      'Trạng thái': item.status,
      'Thời gian nhận': item.createdAt,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  const onChangePage = (page) => {
    setPage(page)
  }

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const getSumGoals = () => {
    let sum = 0;
    dataList?.forEach((e) => {
      sum += +e?.goal;
    });
    return sum;
  };

  return (
    <div className='col-12'>
      <div className='col-12'>
        <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>

          <div className='col-12' style={{ marginBottom: 15, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="usernameFilter"
                label={t('text0002')}
              >
                <Input
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="receiveStt"
                label={'Trạng thái'}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  onChange={(e) => setStatus(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={sttReceived}
                />
              </Form.Item>
            </div>
          </div>

          <div className='col-12' style={{ marginBottom: 8, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="dateFrom"
                label={t('text0032')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateFrom}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                // disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="dateTo"
                label={t('text0033')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateTo}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                // disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
          </div>

          <div className='payment-search'>
            <Button htmlType='submit' type="primary">{t('text0031')}</Button>
            {auth?.role === "supper" &&
              <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button>
            }
          </div>
        </Form>
      </div>
      <div className='col-12' style={{ margin: '15px 0px', fontWeight: 600 }}>
        <div className='col-12 flex'>
          <div className='col-5'>
            <p>{`Tổng HV tham gia có bàn thắng: ${dataReport?.count}`}</p>
          </div>
          <div className='col-6'>
            <p>{`Tổng số bàn thắng ghi được của tất cả các HV tham gia: ${dataReport?.total}`}</p>
          </div>
        </div>
      </div>
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
          <Table
            key='scoreHistory'
            locale={locale}
            columns={tableColums}
            dataSource={dataList}
            pagination={false}
            footer={
              dataFilter?.username ? () => {
                return <span style={{ fontWeight: '650' }}>
                  {`Tổng số bàn thắng: ${getSumGoals()}`}
                </span>
              }
                : undefined}
            bordered

            style={{ width: '100%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </Spin>
    </div>
  )
}

export default GoalsHistory;