import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Select, Spin, Pagination } from 'antd';
import API, { USER_EVENT2, USERTURN } from '../../network/api';
import moment from 'moment';
import 'moment-timezone';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';

const TurnDetail = () => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [dataFilter, setDataFilter] = useState()
  const [dataList, setDataList] = useState([])
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [status, setStatus] = useState()
  const [dataReport, setDataReport] = useState();
  const auth = JSON.parse(localStorage.getItem('auth'))

  const locale = {
    emptyText: t('text0035')
  }

  useEffect(() => {
    const getDataReport = async() => {
      await API.get(`${USERTURN}/report`).then(response => {
        const data = response.data?.data?.result
        if (data) setDataReport(data)
      }).catch(error => {
        console.log(error)
      })
    }
    
    getDataReport();
  }, []);

  const sttReceived = 
    auth?.role === "supper" ?
    [
      {
        id: 0,
        value: -1,
        label: 'Chưa nhận'
      },
      {
        id: 1,
        value: 1,
        label: 'Đã nhận'
      },
      {
        id: 2,
        value: "0",
        label: 'Bù/trừ'
      },
    ] : 
    [
      {
        id: 0,
        value: -1,
        label: 'Chưa nhận'
      },
      {
        id: 1,
        value: 1,
        label: 'Đã nhận'
      },
    ]

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    getDataHis()
  }, [page, dataFilter]);

  const getDataHis = async () => {
    setLoading(false)
    let dataKey = {};
    if (dataFilter?.username) {
      dataKey.username = dataFilter?.username
    }

    if (dataFilter?.startDate) {
      dataKey.startDate = dataFilter?.startDate
    }

    if (dataFilter?.endDate) {
      dataKey.endDate = dataFilter?.endDate
    }

    if (dataFilter?.status) {
      dataKey.status = dataFilter?.status
    }

    const keyword = dataFilter ? encodeURIComponent(JSON.stringify(dataKey)) : null
    const URL = `${USERTURN}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

    const listData1 = API.get(URL)

    await Promise.all([listData1]).then(response => {
      const res1 = response[0].data?.data?.result[0]
      setTotalRecord(response[0].data?.data?.result[1])

      if (res1) {
        let dataCV = []

        res1?.map((item, index) => {
          let itemGame = {
            key: index,
            id: item?.id,
            username: item?.user?.username,
            bet: item?.bet,
            turn: item?.turn,
            isComplete: item?.isComplete,
            isOffset: item?.isOffset,
            createdAt: item?.createdAt,
            updatedAt: item?.updatedAt,
          }
          dataCV.push(itemGame)
        });
        setDataList(dataCV)
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const getPredStr = (listFilter, id1, id2) => {
    return listFilter.find(t => t.id === id1)?.name + ', ' + listFilter.find(t => t.id === id2)?.name
  }

  const onSearch = () => {
    let params = {}

    if (form.getFieldValue("usernameFilter")) params.username = form.getFieldValue("usernameFilter").trim();
    if (dateFrom) {
      params.startDate = dateFrom
      if (!dateTo) params.endDate = "2123-12-31"
    }
    if (dateTo) {
      if (!dateFrom) params.startDate = "2024-07-31"
      params.endDate = dateTo
    }
    if (status) {
      params.status = status
    }

    setDataFilter(params ? params : undefined)
    setPage(1)
  }

  const tableColums = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      width: 100,
      render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>
    },
    {
      title: t('text0002'),
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: "Lượt dự đoán",
      key: 'turn',
      dataIndex: 'turn'
    },
    {
      title: "Trạng thái",
      key: 'turnPred',
      render: (record) => record?.isOffset ? "Bù/trừ" : record?.isComplete ? "Đã nhận" : "Chưa nhận"
    },
    {
      title: "Thời gian quy đổi",
      key: 'createdAt',
      render: (record) => moment(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
    },
    {
      title: "Thời gian nhận",
      key: 'updatedAt',
      render: (record) => 
        record?.isOffset ? moment(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss') :
        record?.isComplete ? moment(record?.updatedAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss') : ""
    },
  ]

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === 'from')
        setDateFrom(moment(e).startOf('day'))
      else
        setDateTo(moment(e).endOf('day'))
    } else {
      if (type === 'from')
        setDateFrom(null)
      else
        setDateTo(null)
    }
  }

  const onChangeDateFrom = (date, dateString) => {
    setDateFrom(dateString)
  }

  const onChangeDateTo = (date, dateString) => {
    setDateTo(dateString)
  }

  const handleDownloadExcel = async () => {
    setLoading(false)
    let dataKey = {};
    if (dataFilter?.username) {
      dataKey.username = dataFilter?.username
    }

    if (dataFilter?.startDate) {
      dataKey.startDate = dataFilter?.startDate
    }

    if (dataFilter?.endDate) {
      dataKey.endDate = dataFilter?.endDate
    }

    if (dataFilter?.status) {
      dataKey.status = dataFilter?.status
    }

    const keyword = dataFilter ? encodeURIComponent(JSON.stringify(dataKey)) : null
    const URL = `${USERTURN}/search?take=100000000&skip=1&order=DESC&keyword=${keyword}`

    const listData1 = API.get(URL)

    await Promise.all([listData1]).then(response => {
      const res1 = response[0].data?.data?.result[0]
      setTotalRecord(response[0].data?.data?.result[1])

      if (res1) {
        let dataCV = []

        res1?.map((item, index) => {
          let itemGame = {
            username: item?.user?.username,
            turn: item?.turn,
            isComplete: item?.isOffset ? "Bù/trừ" : item?.isComplete ? "Đã nhận" : "Chưa nhận",
            createdAt: moment(item?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
            updatedAt: 
              item?.isOffset ? moment(item?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss') :
              item?.isComplete ? moment(item?.updatedAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss') : ""
          }
          dataCV.push(itemGame)
        });
        exportToExcel(dataCV)
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const exportToExcel = (data) => {
    const currentTime = moment().format("DD-MM-YYYY HH-mm-ss");
    const name = `Lịch sử nhận lượt dự đoán_${currentTime}`;

    const formattedData = data.map(item => ({
      'Tên đăng nhập': item.username,
      'Lượt dự đoán': item.turn,
      'Trạng thái': item.isComplete,
      'Thời gian quy đổi': item.createdAt,
      'Thời gian nhận': item.updatedAt,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  const onChangePage = (page) => {
    setPage(page)
  }

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  return (
    <div className='col-12'>
      <div className='col-12'>
        <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
          <div className='col-12' style={{ marginBottom: 15, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="usernameFilter"
                label={t('text0002')}
              >
                <Input
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>

            <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
              <div className='col-4'>
                <Form.Item
                  name="receiveStt"
                  label={'Trạng thái'}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder=""
                    optionFilterProp="children"
                    onChange={(e) => setStatus(e)}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={sttReceived}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className='w-full font-semibold mb-2'>Thời gian nhận: </div>
          <div className='col-12' style={{ marginBottom: 8, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="dateFrom"
                label={t('text0032')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateFrom}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                // disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="dateTo"
                label={t('text0033')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateTo}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                // disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
          </div>

          <div className='payment-search'>
            <Button htmlType='submit' type="primary">{t('text0031')}</Button>
            {auth?.role === "supper" &&
              <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button>
            }
          </div>
        </Form>
      </div>
      <div className='col-12' style={{ margin: '15px 0px', fontWeight: 600 }}>
        <div className='col-12 flex'>
          <div className='col-4'>
            <p>{`Tổng HV tham gia nhận lượt dự đoán: ${dataReport?.count}`}</p>
          </div>
          <div className='col-4'>
            <p>{`Tổng số lượt dự đoán đã nhận: ${dataReport?.sumIsComplete}`}</p>
          </div>
          <div className='col-4'>
            <p>{`Tổng số lượt dự đoán chưa nhận: ${dataReport?.sumUnComplete}`}</p>
          </div>
        </div>
      </div>
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
          <Table
            key='scoreHistory'
            locale={locale}
            columns={tableColums}
            dataSource={dataList}
            pagination={false}
            bordered
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </Spin>
    </div>
  )
}

export default TurnDetail;