import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.css";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Radio, Spin, Pagination } from 'antd';
import Modal from 'react-bootstrap/Modal';
import API, { RANK, USER } from '../../network/api';
import settingIcon from '../../image/setting.png'
import moment from 'moment';
import 'moment-timezone';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Checkbox } from 'antd';
import { dataPermision } from '../../permision/permision';
dayjs.extend(customParseFormat);

const AccountList = () => {

  const auth = JSON.parse(localStorage.getItem('auth'))
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [userList, setUserList] = useState([])
  const [adminList, setAdminList] = useState([])
  const indexTabLocal = localStorage.getItem('indexTabAcc') || 1
  const [indexTab, setIndexTab] = useState(Number(indexTabLocal))
  localStorage.removeItem('indexTabAcc')
  const roleLocal = JSON.parse(localStorage.getItem('roles'))
  const [pageUser, setPageUser] = useState(1);
  const [totalRecordUser, setTotalRecordUser] = useState(0);
  const [pageAdmin, setPageAdmin] = useState(1);
  const [totalRecordAdmin, setTotalRecordAdmin] = useState(0);
  const locale = {
    emptyText: t('text0035')
  }
  const [loading, setLoading] = useState(false);
  const [dataFilter, setDataFilter] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [errorMess, setErrorMess] = useState();
  const passwordDefault = 'jtmbdguF@Jcuf%5dhRMQrjj';

  const [rowEdit, setRowEdit] = useState();
  const [isShowModalPermision, setIsShowModalPermision] = useState(false);
  const [permisionUp, setPermisionUp] = useState()
  const [isUpdatePer, setIsUpdatePer] = useState(false)

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    getListDataUser()
  }, [pageUser, pageAdmin, dataFilter, indexTab]);

  const getListDataUser = async () => {
    setLoading(true)
    const keyword = dataFilter ? dataFilter : encodeURIComponent(JSON.stringify({ role: indexTab === 1 ? "member" : "admin" }))
    const dataList1 = API.get(`${USER}/all?take=10&skip=${indexTab === 1 ? pageUser : pageAdmin}&order=DESC&keyword=${keyword}`)

    await Promise.all([dataList1]).then(response => {
      const res = response[0].data.data.result[0]
      if (res) {
        let dataUserCV = [], dataAdminCV = []
        res?.map(item => {
          if (item?.role === "member") {
            const user = {
              key: item?.id,
              username: item?.username,
              userId: item?.id,
              createdAt: item?.createdAt
            }
            dataUserCV.push(user)
            // dataUserCV = dataUserCV.sort((a, b) => (moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()))
          } else {
            const admin = {
              key: item?.id,
              id: item?.id,
              username: item?.username,
              role: item?.role,
              createdAt: item?.createdAt,
              permision: item?.permision
            }
            dataAdminCV.push(admin)
          }
        })

        setUserList(dataUserCV)
        setTotalRecordUser(response[0].data.data.result[1])

        setAdminList(dataAdminCV)
        setTotalRecordAdmin(response[0].data.data.result[1])
        setLoading(false)
      }
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const onChangePageUser = (page) => {
    setPageUser(page)
  }

  const onChangePageAdmin = (page) => {
    setPageAdmin(page)
  }

  const showTotal = (total) => `Tổng cộng ${total} tài khoản`;

  const onSearch = () => {
    let params = { role: indexTab === 1 ? "member" : "admin" }
    if (form.getFieldValue('usernameFilter')) params.username = form.getFieldValue('usernameFilter').trim()
    if (dateFrom) {
      params.startDate = dateFrom
    }
    if (dateTo) {
      params.endDate = dateTo
    }


    if (indexTab === 1) {
      setPageUser(1)
    }

    if (params?.username || params?.startDate || params?.endDate) {
      setDataFilter(JSON.stringify(params))
    } else {
      setDataFilter(null)
    }
  }

  const userColums = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      render: (text, record, index) => <span>{(pageUser - 1) * 10 + (index + 1)}</span>
    },
    {
      title: t('text0002'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: t('text0013'),
      key: 'createdAt',
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
    }
  ]

  const adminColums = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      render: (text, record, index) => <span>{(pageAdmin - 1) * 10 + (index + 1)}</span>
    },
    {
      title: t('text0002'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: t('text0013'),
      key: 'createdAt',
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
    },
    {
      title: t('text0038'),
      dataIndex: 'role',
      align: 'center',
      key: 'role',
    },
    {
      title: 'Cài đặt quyền hạn',
      key: 'setting',
      align: 'center',
      width: 100,
      render: (record) =>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img style={{ height: 20, cursor: 'pointer' }} src={settingIcon} alt='setting'
            onClick={() => {
              setRowEdit(record);
              setIsShowModalPermision(true);
            }}
          />
        </div>
    },
  ]

  const onChangeDateFrom = (date, dateString) => {
    const dataFm = moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD")
    setDateFrom(dataFm)
  }

  const onChangeDateTo = (date, dateString) => {
    const dataFm = moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD")
    setDateTo(dataFm)
  }

  const addNewBank = () => {
    localStorage.setItem('indexLocalCreate', indexTab);
    navigate('/createAccount')
  }

  const onChangeTab = (e) => {
    setDateFrom(undefined)
    setDateTo(undefined)
    form.setFieldValue("dateFrom", undefined)
    form.setFieldValue("dateTo", undefined)
    setDataFilter(null)
    setPageUser(1)
    setPageAdmin(1)
    setIndexTab(e.target.value)
  }

  const handleDownloadExcel = async () => {
    const keyword = dataFilter ? dataFilter : encodeURIComponent(JSON.stringify({ role: indexTab === 1 ? "member" : "admin" }))
    const dataList1 = API.get(`${USER}/all?take=999999&skip=1&order=DESC&keyword=${keyword}`)

    await Promise.all([dataList1]).then(response => {
      const res = response[0].data.data.result[0]
      if (res) {
        let dataUserCV = []
        res?.map(item => {
          const body = {
            username: item?.username,
            createdAt: moment(item?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
          }
          dataUserCV.push(body)
        })
        exportToExcel(dataUserCV)
      }
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const exportToExcel = (data) => {
    const currentTime = moment().format("DD-MM-YYYY HH-mm-ss");
    const name = `Tài khoản hội viên_${currentTime}`;

    const formattedData = data.map(item => ({
      'Tên đăng nhập': item.username,
      'Ngày đăng ký': item.createdAt
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  // const onShowModal = () => {
  //   setIsModalOpen(true);
  // }

  const onHandleOk = async () => {
    try {
      const values = {
        username: form.getFieldValue('username'),
        password: passwordDefault
      };
      await API.post('/user/create', values).then((resp) => {
        const result = resp.data.data.result;
        if (result?.code === 'ER_DUP_ENTRY') {
          setErrorMess('Tên HV Fake đã tồn tại');
        } else {
          window.location.reload();
          setIsModalOpen(false);
        }
      });
    } catch (err) {
      setErrorMess(err);
    }
  }

  const onHandleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setErrorMess("");
  }

  const handleValuesChange = (_, value) => {
    setIsValid(!!value.username);
  }

  const onHandleCancelPer = () => {
    setIsShowModalPermision(false);
  }

  const updatePermision = async () => {
    setIsUpdatePer(true)
    const params = {
      permission: permisionUp.join(",")
    }

    if (!isUpdatePer) {
      await API.patch(`${USER}/${rowEdit?.id}/permission`, params)
      .then(response => {
        const data = response?.data?.data
        if (data?.code === 20004) {
          getListDataUser()
          setIsShowModalPermision(false);
        }
        setIsUpdatePer(false)
      })
      .catch(error => { setIsUpdatePer(false) })
    }
  }

  const renderAccUser = () => {
    return (
      <>
        <div className='col-12'>
          <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
            <div className='col-12' style={{ marginBottom: 15, display: 'flex' }}>
              <div className='col-4'>
                <Form.Item
                  name="usernameFilter"
                  label={t('text0002')}
                >
                  <Input
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
              <div className='col-4'>
                <Form.Item
                  name="dateFrom"
                  label={t('text0032')}
                >
                  <DatePicker
                    placeholder=''
                    onChange={onChangeDateFrom}
                    format={"DD-MM-YYYY"}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
              <div className='col-4'>
                <Form.Item
                  name="dateTo"
                  label={t('text0033')}
                >
                  <DatePicker
                    placeholder=''
                    onChange={onChangeDateTo}
                    format={"DD-MM-YYYY"}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='payment-search'>
              <Button htmlType='submit' type="primary">{t('text0031')}</Button>
              {auth?.role === "supper" &&
                <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button>
              }
            </div>
          </Form>
        </div>
        {/* {auth?.role === "supper" ? <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
          <Button type="primary" onClick={addNewBank}>
            {t('text0041')}
          </Button>
        </div> : null } */}
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
            <Table
              key='userList'
              locale={locale}
              columns={userColums}
              dataSource={userList}
              pagination={false}
              bordered
              style={{ width: '100%' }}
            />
          </div>
          <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
            <Pagination
              current={pageUser}
              total={totalRecordUser}
              onChange={onChangePageUser}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    )
  }

  const renderAccAdmin = () => {
    return (
      <>
        <div className='col-12'>
          <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
            <div className='col-12' style={{ marginBottom: 15, display: 'flex' }}>
              <div className='col-4'>
                <Form.Item
                  name="usernameFilter"
                  label={t('text0002')}
                >
                  <Input
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
              <div className='col-4'>
                <Form.Item
                  name="dateFrom"
                  label={t('text0032')}
                >
                  <DatePicker
                    placeholder=''
                    onChange={onChangeDateFrom}
                    format={"DD-MM-YYYY"}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
              <div className='col-4'>
                <Form.Item
                  name="dateTo"
                  label={t('text0033')}
                >
                  <DatePicker
                    placeholder=''
                    onChange={onChangeDateTo}
                    format={"DD-MM-YYYY"}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='payment-search'>
              <Button htmlType='submit' type="primary">{t('text0031')}</Button>
            </div>
          </Form>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
          <Button type="primary" onClick={addNewBank}>
            {t('text0010')}
          </Button>
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
          <Table
            key='listAdmin'
            locale={locale}
            columns={adminColums}
            dataSource={adminList}
            pagination={false}
            bordered
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
          <Pagination
            current={pageAdmin}
            total={totalRecordAdmin}
            onChange={onChangePageAdmin}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </>
    )
  }

  return (
    <div className="col-12">
      {auth?.role === 'supper' ? (
        <div className="col-12" style={{ borderBottom: '1px solid #CCC', paddingBottom: '2rem', marginBottom: '2rem' }}>
          <Radio.Group value={indexTab} onChange={onChangeTab}>
            <Radio.Button value={1}>{t('text0036')}</Radio.Button>
            <Radio.Button value={2}>{t('text0037')}</Radio.Button>
          </Radio.Group>
        </div>
      ) : null}

      {indexTab === 1 ? renderAccUser() : renderAccAdmin()}

      {
        <Modal title={t('text0126')} onCancel={onHandleCancel} open={isModalOpen} onOk={onHandleOk} okButtonProps={{ disabled: !isValid }}>
          <Form className="form-create" form={form} layout="inline" autoComplete="off" onValuesChange={handleValuesChange}>
            <div className="col-8">
              <div className="col-12" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col-3">
                  {t('text0002')} <span style={{ color: 'red' }}>*</span>
                </div>
                <div className="col-9">
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: t('text0004'),
                      },
                    ]}
                    style={{ marginRight: 0 }}
                  >
                    <Input maxLength={255} onChange={() => setErrorMess()} />
                  </Form.Item>
                </div>
              </div>
              <div className="col-12" style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                <div className="col-3"></div>
                <div className="col-9">{errorMess}</div>
              </div>
              <div style={{ marginBottom: 15 }} />
            </div>
          </Form>
        </Modal>
      }

      {isShowModalPermision &&
        <ModalSettingPer 
          isShow={isShowModalPermision}
          close={onHandleCancelPer}
          actionNext={updatePermision}
          record={rowEdit}
          permisions={dataPermision}
          onChangeIp1={(e) => setPermisionUp(e)}
        />
      }

      {/* {isShowModalDel &&
        <ModalCommon
          isShow={isShowModalDel}
          message={t('text0031')}
          txtBtnAcc={t('text0010')}
          textBtnClose={t('text0023')}
          actionNext={onDelAcc}
          close={() => setIsShowModalDel(false)}
        />
      }
      {isShowModalDelSuccess &&
        <ModalCommon
          isShow={isShowModalDelSuccess}
          message={t('text0034')}
          txtBtnAcc={t('text0009')}
          actionNext={() => {
            setIsShowModalDelSuccess(false)
            getListDataUser()
          }}
          close={() => {
            setIsShowModalDelSuccess(false)
            getListDataUser()
          }}
        />
      } */}
    </div>
  );
}

export default AccountList;

const ModalSettingPer = ({
  isShow, onChangeIp1, permisions,
  record, actionNext, close
}) => {

  const options = [];
  permisions.map(item => {
    options.push({
      label: item.title,
      value: item.code,
    })
  })

  const permisionUser = record?.permision?.split(",") || []

  return (
    <Modal show={isShow} className='modal-data' onHide={close}>
      <Modal.Header style={{ fontWeight: 700, fontSize: 16 }}>{"Cài đặt quyền hạn"}</Modal.Header>
      <Modal.Body>
        <div className="col-12 flex" style={{ flexDirection: 'column' }}>
          <div className="col-12 flex">
            <div className="col-3 text-left">{"Tên tài khoản"}</div>
            <div className="col-8">
              <Input value={record?.username} />
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{"Quyền hạn"}</div>
            <div className="col-8">
              <Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }} 
                options={options} defaultValue={permisionUser} onChange={onChangeIp1}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button type="primary" onClick={actionNext}>
          Lưu
        </Button>
        <Button type="default" onClick={close}>
          Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  )
}