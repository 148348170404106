import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Select, Upload, Spin, Image, Pagination, Switch } from 'antd';
import API, { PLAYER, EVENT1_QUESTION, EVENT1_ANSWER } from '../../network/api';
import settingIcon from '../../image/setting.png'
import deleteIcon from '../../image/delete.png'
import ModalCommon from '../../components/Modal';
import moment from 'moment';
import 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import dayjs from 'dayjs';

const { Option } = Select;

const Ev1answer = () => {

    const auth = localStorage.getItem('auth')
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const navigate = useNavigate()
    const [dateFrom, setDateFrom] = useState()
    const [dateTo, setDateTo] = useState()
    const [dataList, setDataList] = useState([])
    const [page, setPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowDel, setRowDel] = useState()
    const [isShowModalDel, setIsShowModalDel] = useState(false)
    const [isShowModalDelSuccess, setIsShowModalDelSuccess] = useState(false)
    const [dataFilter, setDataFilter] = useState()
    const [isShowModalCU, setIsShowModalCU] = useState(false)

    const [input1, setInput1] = useState();
    const [input2, setInput2] = useState();
    const [input3, setInput3] = useState();
    const [input4, setInput4] = useState();
    const [input5, setInput5] = useState();
    const [errorCU, setErrorCU] = useState(); 
    const [rowEdit, setRowEdit] = useState();
    const [listQuestion, setListQuestion] = useState();
    const [isCreatedData, setIsCreatedData] = useState(false)

    const locale = {
        emptyText: t('text0035')
    }

    useEffect(() => {
        forceUpdate({});
        getListQuestionUncomplete();
    }, []);

    const getListQuestionUncomplete = async () => {
        await API.get(`${EVENT1_QUESTION}/uncomplete`).then(response => {
            const result = response.data?.data?.result;
            if (result.length > 0) {
                const dataQ = [];
                result?.map(item => {
                    dataQ.push({
                        value: item.id,
                    label: item.content
                    })
                })
                setListQuestion(dataQ);
            }
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    useEffect(() => {
        getDataList()
    }, [page, dataFilter]);

    const getDataList = async () => {
        setLoading(true)
        const keyword = (dataFilter?.name || dataFilter?.startDate || dataFilter?.endDate) ?
            encodeURIComponent(JSON.stringify({ content: dataFilter?.name, startDate: dataFilter?.startDate, endDate: dataFilter?.endDate })) : null
        const URL = `${EVENT1_ANSWER}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

        const listData1 = API.get(URL)
        await Promise.all([listData1]).then(response => {
            const result1 = response[0].data?.data?.result;

            if (result1[0].length > 0) {
                let dataCV = []

                result1[0]?.map((item) => {
                    let itemNew = {
                        id: item?.id,
                        question: item?.event1Question,
                        title: item?.title,
                        listAnswer: item?.listAnswer,
                        createdAt: item?.createdAt
                    }

                    dataCV.push(itemNew)
                })
                setDataList(dataCV)
                setTotalRecord(result1[1])
            } else {
                setDataList([]);
            }
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    const onSearch = () => {
        let params = {}

        if (form.getFieldValue('nameFilter')) params.name = form.getFieldValue('nameFilter');
        if (dateFrom) {
            params.startDate = dateFrom
            if (!dateTo) params.endDate = "2123-12-31"
        }
        if (dateTo) {
            if (!dateFrom) params.startDate = "2024-07-31"
            params.endDate = dateTo
        }

        setDataFilter(params ? params : undefined)
        setPage(1)
    }

    const tableColums = [
        {
            title: 'STT',
            align: 'center',
            key: 'order',
            render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>,
            width: 100
        },
        {
            title: 'Câu hỏi',
            key: 'content',
            width: 300,
            render: (record) => <span>{record?.question?.content}</span>
        },
        {
            title: 'Câu trả lời',
            dataIndex: 'title',
            width: 200,
            key: 'title',
        },
        {
            title: 'D/s câu trả lời',
            key: 'listAnswer',
            width: 200,
            render: (record, idx) => <span>{record?.listAnswer.replaceAll(",", "\n")}</span>
        },
        {
            title: 'Ngày tạo dữ liệu',
            key: 'createdAt',
            render: (record) =>
                moment(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
        },
        {
            title: t('text0012'),
            key: 'setting',
            align: 'center',
            width: 100,
            render: (record) =>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img style={{ height: 20, cursor: 'pointer' }} src={settingIcon} alt='setting'
                        onClick={() => {
                            setRowEdit(record);
                            setIsShowModalCU(true);
                        }}
                    />
                </div>
        },
        {
            title: t('text0011'),
            key: 'delete',
            align: 'center',
            width: 100,
            render: (record) =>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img style={{ height: 20, cursor: 'pointer', textAlign: 'center' }} src={deleteIcon} alt='del'
                        onClick={() => {
                            setRowDel(record)
                            setIsShowModalDel(true)
                        }}
                    />
                </div>
        }
    ]

    const createData = () => {
        setIsShowModalCU(true) 
    }

    const onCreateData = async () => {
        if (!input1 || !input2 || !input3 || input2?.trim().length == 0 || input3?.trim().length == 0) {
            setErrorCU("Bạn chưa nhập đầy đủ thông tin")
            return
        }
        setLoading(true)
        setIsCreatedData(true)

        if(!isCreatedData) {
            const params = {
                questionId: input1,
                title: input2.trim(),
                listAnswer: input3.trim()
            }
            await API.post(`${EVENT1_ANSWER}/create`, params).then(response => {
                const data = response.data?.data;    
                if (data?.code === 20001) {
                    onSearch();
                    setIsShowModalCU(false);
                }
                setLoading(false)
                setIsCreatedData(false)
            }).catch(error => {
                console.log(error)
                setLoading(false)
                setIsCreatedData(false)
            })
        }
    }

    const onUpdateData = async () => {
        if (input2?.trim().length == 0 || input3?.trim().length == 0) {
            setErrorCU("Bạn chưa nhập đầy đủ thông tin")
            return
        }
        setIsCreatedData(true)
        setLoading(true)

        if(!isCreatedData) {
            const params = {
                title: input2.trim() || rowEdit?.title,
                listAnswer: input3.trim() || rowEdit?.listAnswer,
            }
            await API.patch(`${EVENT1_ANSWER}/${rowEdit?.id}`, params).then(response => {
                const data = response.data?.data; 
                if (data?.code === 20004) {
                    onSearch();
                    setIsShowModalCU(false);
                    setRowEdit(undefined);
                }
                setLoading(false)
                setIsCreatedData(false)
            }).catch(error => {
                console.log(error)
                setLoading(false)
                setIsCreatedData(false)
            })
        }
    }

    const onDelPoint = async () => {
        setLoading(true)
        await API.delete(`${EVENT1_ANSWER}/${rowDel?.id}`).then(response => {
            const data = response.data?.data;
            if (data?.code === 20005) {
                setIsShowModalDelSuccess(true)
                setIsShowModalDel(false)
            }
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    const onChangePage = (page) => {
        setPage(page)
    }

    const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

    const backScreen = () => { navigate('/settingSys') }

    const onChangeDateFrom = (date, dateString) => {
        setDateFrom(dateString)
    }

    const onChangeDateTo = (date, dateString) => {
        setDateTo(dateString)
    }

    const closeModalCU = () => {
        setIsShowModalCU(false);
        setErrorCU(undefined);
        setRowEdit(undefined)
        setInput1(undefined);
        setInput2(undefined);
        setInput3(undefined);
        setInput4(undefined);
        setInput5(undefined);
    }

    return (
        <div className='col-12'>
            <div className='col-12'>
                <Button
                    type="default"
                    htmlType="button"
                    style={{ marginBottom: 30 }}
                    onClick={backScreen}
                >
                    {t('text0034')}
                </Button>

                <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
                    <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
                        <div className='col-8'>
                            <Form.Item
                                name="nameFilter"
                                label={'Câu hỏi'}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>

                    </div>

                    <div className='col-12' style={{ marginBottom: 8, display: 'flex' }}>
                        <div className='col-4'>
                            <Form.Item
                                name="dateFrom"
                                label={t('text0032')}
                            >
                                <DatePicker
                                    placeholder=''
                                    onChange={onChangeDateFrom}
                                    format={"YYYY-MM-DD"}
                                    style={{ width: '100%' }}
                                // disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </div>
                        <div className='col-4'>
                            <Form.Item
                                name="dateTo"
                                label={t('text0033')}
                            >
                                <DatePicker
                                    placeholder=''
                                    onChange={onChangeDateTo}
                                    format={"YYYY-MM-DD"}
                                    style={{ width: '100%' }}
                                // disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='payment-search'>
                        <Button htmlType='submit' type="primary">{t('text0031')}</Button>
                        {/* <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button> */}
                    </div>
                </Form>
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
                <Button type="primary" onClick={createData}>
                    {t('text0070')}
                </Button>
            </div>
            <Spin spinning={loading} delay={500} size="large">
                <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
                    <Table
                        key='dataLeague'
                        locale={locale}
                        columns={tableColums}
                        dataSource={dataList}
                        pagination={false}
                        bordered
                        style={{ width: '100%' }}
                    />
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
                    <Pagination
                        current={page}
                        total={totalRecord}
                        onChange={onChangePage}
                        defaultPageSize={10}
                        showSizeChanger={false}
                        showTotal={showTotal}
                    />
                </div>
            </Spin>
            {isShowModalDel &&
                <ModalCommon
                    isShow={isShowModalDel}
                    message={t('text0071')}
                    txtBtnAcc={t('text0008')}
                    textBtnClose={t('text0023')}
                    actionNext={onDelPoint}
                    close={() => setIsShowModalDel(false)}
                />
            }
            {isShowModalDelSuccess &&
                <ModalCommon
                    isShow={isShowModalDelSuccess}
                    message={t('text0071')}
                    txtBtnAcc={t('text0008')}
                    actionNext={() => {
                        setIsShowModalDelSuccess(false)
                        getDataList()
                    }}
                    close={() => {
                        setIsShowModalDelSuccess(false)
                        getDataList()
                    }}
                />
            }
            {isShowModalCU &&
                <ModalCreateData 
                    isShow={isShowModalCU}
                    onChangeIp1={(e) => setInput1(e)}
                    onChangeIp2={(e) => setInput2(e.target.value)}
                    onChangeIp3={(e) => setInput3(e.target.value)}
                    record={rowEdit}
                    actionNext={rowEdit ? onUpdateData : onCreateData}
                    close={closeModalCU}
                    mode={rowEdit ? "update" : "create"}
                    error={errorCU}
                    listQ={listQuestion}
                />
            }
        </div>
    )
}

export default Ev1answer

const ModalCreateData = ({
    isShow, onChangeIp1, onChangeIp2, onChangeIp3,
    record, actionNext, close, mode, error, listQ
}) => {

    const listAnswer1 = record?.answer1?.listAnswer?.split(",") || [];

    return (
        <Modal show={isShow} className='modal-data' onHide={close}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="col-12 flex" style={{ flexDirection: 'column' }}>
                    {mode === "create" &&
                        <>
                            <div className="col-12 flex">
                                <div className="col-3 text-left">{"Câu hỏi"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Select 
                                        style={{ width: '100%' }} 
                                        onChange={onChangeIp1}
                                        showSearch
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={listQ}
                                    />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"Câu trả lời"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input onChange={onChangeIp2} />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"D/s câu trả lời"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input.TextArea onChange={onChangeIp3} />
                                </div>
                            </div>
                            <div>
                                <p style={{ color: 'red' }}>NOTE: Mỗi câu trả lời phân cách nhau bởi dấu ","</p>
                            </div>
                        </>
                    }
                    {mode === "update" &&
                        <>
                            <div className="col-12 flex">
                                <div className="col-3 text-left">{"Câu hỏi"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Select 
                                        style={{ width: '100%' }} 
                                        onChange={onChangeIp1}
                                        showSearch
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={listQ}
                                        value={record?.question?.id}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"Câu trả lời"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input defaultValue={record?.title} onChange={onChangeIp2} />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"D/s câu trả lời"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input.TextArea  defaultValue={record?.listAnswer} onChange={onChangeIp3} />
                                </div>
                            </div>
                            <div>
                                <p style={{ color: 'red' }}>NOTE: Mỗi câu trả lời phân cách nhau bởi dấu ","</p>
                            </div>
                        </>
                    }
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="primary" onClick={actionNext}>
                    Lưu
                </Button>
                <Button type="default" onClick={close}>
                    Đóng
                </Button>
            </Modal.Footer>
        </Modal>
    )
}