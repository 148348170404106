import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Select, InputNumber } from 'antd';
import API, { USER, USER_GOAL, USER_EVENT2 } from '../../network/api';
import 'moment-timezone';

const { Option } = Select;

const UpdateGoals = () => {

    const auth = localStorage.getItem('auth')
    const { t } = useTranslation();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [, forceUpdate] = useState({});
    const navigate = useNavigate()
    const [page, setPage] = useState(1);
    const [dataFilter, setDataFilter] = useState()
    const [errorMess, setErrorMess] = useState()
    const [user, setUser] = useState()
    const [userInfo, setUserInfo] = useState()
    const [isOffsetGoal, setIsOffsetGoal] = useState(false)
    const [isOffsetTurn, setIsOffsetTurn] = useState(false)

    const locale = {
        emptyText: t('text0035')
    }

    useEffect(() => {
        forceUpdate({});
    }, []);

    const onStep1 = async () => {
        const url = `${USER}/username/${form1.getFieldValue("username").trim().toLowerCase()}`
        await API.get(url)
            .then(response => {
                const result = response.data?.data?.result
                if (result) {
                    setUser(result)
                    alert(`Username chính xác`)
                } else {
                    setErrorMess('Tên đăng nhập không tồn tại')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const onStep2Goal = async () => {
        if (!user) {
            alert(`Vui lòng nhập username trước khi bù/trừ bàn thắng!`)
        } else {
            updateGoal(user?.id)
        }
    }

    const onStep2Turn = async () => {
        if (!user) {
            alert(`Vui lòng nhập username trước khi bù/trừ lượt dự đoán!`)
        } else {
            updateTurn(user?.id)
        }
    }

    const updateTurn = async (userId) => {
        setIsOffsetTurn(true)
        const param = {
            userId: userId,
            turn: form3.getFieldValue("turn")
        }
        if (!isOffsetTurn) {
            await API.post(`${USER_EVENT2}/offsetTurn`, param)
            .then(response => {
                const data = response.data?.data; 
                if (data?.code === 20004) {
                    alert(`Bù/trừ thành công!`)
                } else {
                    alert(`Bù/trừ thất bại!`)
                }
                setIsOffsetTurn(false)
            })
            .catch(error => {
                console.log(error)
                setIsOffsetTurn(false)
            })
        }
    }

    const updateGoal = async (userId) => {
        setIsOffsetGoal(true)
        const param = {
            userId: userId,
            goal: form2.getFieldValue("goal")
        }
        if (!isOffsetGoal) {
            await API.post(`${USER_EVENT2}/offsetGoal`, param)
                .then(response => {
                    const data = response.data?.data;
                    if (data?.code === 20004) {
                        alert(`Bù/trừ thành công!`)
                    } else {
                        alert(`Bù/trừ thất bại!`)
                    }
                    setIsOffsetGoal(false)
                })
                .catch(error => {
                    console.log(error)
                    setIsOffsetGoal(false)
                })
        }
    }

    const updateUserInfo = async (userId) => {
        const param = {
            userId: userId,
            goal: form2.getFieldValue("goal")
        }

        await API.post(`${USER_GOAL}/update-for-admin`, param)
            .then(response => {
                const data = response.data?.data?.result

                if (data?.id) {
                    alert(`Cập nhật bàn thắng thành công!`)
                    navigate('/goalsHis')
                } else {
                    if (data.includes('not enough')) {
                        alert(`Số bàn thắng user không đủ !`)
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className='col-12'>
            <Form className='form-create' form={form1} layout="inline" autoComplete='off' onFinish={onStep1}>
                <div className='col-8'>
                    <div className='col-12'>
                        <strong>{`Bước 1: Check user`}</strong>
                    </div>
                    <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='col-3'>
                            {t('text0002')} <span style={{ color: 'red' }}>*</span>
                        </div>
                        <div className='col-6'>
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: t('text0004'),
                                    },
                                ]}
                                style={{ marginRight: 0 }}
                            >
                                <Input maxLength={255} onChange={() => setErrorMess()} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='col-12' style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                        <div className='col-3'></div>
                        <div className='col-6'>{errorMess}</div>
                    </div>
                    <Form.Item shouldUpdate style={{ width: '100%' }}>
                        {() => (
                            <div className='lg-btn'>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !form1.isFieldsTouched(true) ||
                                        !!form1.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                >
                                    {`Check user`}
                                </Button>
                            </div>
                        )}
                    </Form.Item>
                </div>
            </Form>

            <div style={{ marginBottom: 30 }} />

            <Form className='form-create' form={form2} layout="inline" autoComplete='off' onFinish={onStep2Goal}>
                <div className='col-8'>
                    <div className='col-12'>
                        <strong>{`Bù/trừ bàn thắng`}</strong>
                    </div>
                    <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='col-3'>
                            {`Số bàn thắng`} <span style={{ color: 'red' }}>*</span>
                        </div>
                        <div className='col-6'>
                            <Form.Item
                                name="goal"
                                rules={[
                                    {
                                        required: true,
                                        message: t('text0004'),
                                    },
                                ]}
                                className='col-12'
                            >
                                <InputNumber min={-100} style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item shouldUpdate style={{ width: '100%' }}>
                        {() => (
                            <div className='lg-btn'>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !form2.isFieldsTouched(true) ||
                                        !!form2.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                    loading={isOffsetGoal}
                                >
                                    {`Cập nhật bàn thắng`}
                                </Button>
                            </div>
                        )}
                    </Form.Item>
                </div>
            </Form>

            <div style={{ marginBottom: 30 }} />

            <Form className='form-create' form={form3} layout="inline" autoComplete='off' onFinish={onStep2Turn}>
                <div className='col-8'>
                    <div className='col-12'>
                        <strong>{`Bù/trừ lượt dự đoán`}</strong>
                    </div>
                    <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='col-3'>
                            {`Số lượt dự đoán`} <span style={{ color: 'red' }}>*</span>
                        </div>
                        <div className='col-6'>
                            <Form.Item
                                name="turn"
                                rules={[
                                    {
                                        required: true,
                                        message: t('text0004'),
                                    },
                                ]}
                                className='col-12'
                            >
                                <InputNumber min={-100} style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item shouldUpdate style={{ width: '100%' }}>
                        {() => (
                            <div className='lg-btn'>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !form3.isFieldsTouched(true) ||
                                        !!form3.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                    loading={isOffsetTurn}
                                >
                                    {`Cập nhật lượt dự đoán`}
                                </Button>
                            </div>
                        )}
                    </Form.Item>
                </div>
            </Form>
            {/* <div style={{ marginBottom: 30 }} /> */}


        </div>
    )
}

export default UpdateGoals;