import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Select, Spin, Pagination } from 'antd';
import API, { POINT_HISTORY, COMMON } from '../../network/api';
import moment from 'moment';
import 'moment-timezone';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


const { Option } = Select;

const ScoreHistory = () => {

  const auth = JSON.parse(localStorage.getItem('auth'))
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [dataFilter, setDataFilter] = useState()
  const [dataList, setDataList] = useState([])
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataReport, setDataReport] = useState();
  const [listPointGold, setListPointGold] = useState();
  const [pointGold, setPointGold] = useState();

  const locale = {
    emptyText: t('text0035')
  }

  useEffect(() => {
    const getPointGold = async () => {
      await API.get(`${COMMON}/key/list_point`).then(response => {
        const data = response.data?.data?.result
        if (data) {
          const dataCommon = data?.common_value.split(", ")
          const listPointGoal = [];
          dataCommon.map(item => {
            if (Number(item.split("-")[2]) > 0) {
              listPointGoal.push({
                id: item.split("-")[1],
                value: Number(item.split("-")[1]),
                label: item.split("-")[1]
              })
            }
          })
          setListPointGold(listPointGoal);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getPointGold();
  }, []);

  useEffect(() => {
    const getDataReport = async() => {
      await API.get(`${POINT_HISTORY}/report`).then(response => {
        const data = response.data?.data?.result
        if (data) setDataReport(data)
      }).catch(error => {
        console.log(error)
      })
    }
    
    getDataReport();
  }, []);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    getDataHis()
  }, [page, dataFilter]);

  const getDataHis = async () => {
    setLoading(false)

    const keyword = (dataFilter?.username || dataFilter?.startDate || dataFilter?.endDate || dataFilter?.pointRequest ) ?
      encodeURIComponent(JSON.stringify({ username: dataFilter?.username, startDate: dataFilter?.startDate, endDate: dataFilter?.endDate, pointRequest: dataFilter?.pointRequest })) : null
    let URL = `${POINT_HISTORY}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

    const listData1 = API.get(URL)

    await Promise.all([listData1]).then(response => {
      const res1 = response[0].data?.data?.result[0]
      setTotalRecord(response[0].data?.data?.result[1])

      if (res1) {
        let dataCV = []

        res1?.map((item, index) => {
          let itemGame = {
            key: index,
            id: item?.id,
            username: item?.user?.username,
            recharge: Number(item?.recharge),
            numOfGoal: item?.numOfGoal,
            pointRequest: item?.pointRequest,
            status: item?.status,
            createdAt: item?.createdAt,
          }
          dataCV.push(itemGame)
        });

        setDataList(dataCV)
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const getPredStr = (listFilter, id1, id2) => {
    return listFilter.find(t => t.id === id1)?.name + ', ' + listFilter.find(t => t.id === id2)?.name
  }

  const onSearch = () => {
    let params = {}

    if (form.getFieldValue("usernameFilter")) params.username = form.getFieldValue("usernameFilter").trim();
    if (dateFrom) {
      params.startDate = dateFrom
      if (!dateTo) params.endDate = "2123-12-31"
    }
    if (dateTo) {
      if (!dateFrom) params.startDate = "2024-07-31"
      params.endDate = dateTo
    }
    if (pointGold) {
      params.pointRequest = pointGold
    }

    setDataFilter(params ? params : undefined)
    setPage(1)
  }

  const tableColums = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      width: 100,
      render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>
    },
    {
      title: t('text0002'),
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Bàn thắng quy đổi',
      key: 'numOfGoal',
      dataIndex: 'numOfGoal'
    },
    {
      title: 'Điểm nhận',
      key: 'pointRequest',
      dataIndex: 'pointRequest'
    },
    {
      title: 'Tổng nạp tại thời điểm nhận thưởng',
      dataIndex: 'recharge',
      key: 'recharge'
    },
    {
      title: 'Trạng thái',
      key: 'status',
      render: (record) => <span>{record?.status ? 'Thành công' : 'Thất bại'}</span>
    },
    {
      title: t('text0099'),
      key: 'createdAt',
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
    },
  ]

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDateFrom = (date, dateString) => {

    setDateFrom(dateString)
  }

  const onChangeDateTo = (date, dateString) => {
    setDateTo(dateString)
  }

  const handleDownloadExcel = async () => {
    setLoading(false)

    const keyword = (dataFilter?.username || dataFilter?.startDate || dataFilter?.endDate || dataFilter?.pointRequest ) ?
      encodeURIComponent(JSON.stringify({ username: dataFilter?.username, startDate: dataFilter?.startDate, endDate: dataFilter?.endDate, pointRequest: dataFilter?.pointRequest })) : null
    let URL = `${POINT_HISTORY}/search?take=100000000&skip=1&order=DESC&keyword=${keyword}`

    const listData1 = API.get(URL)

    await Promise.all([listData1]).then(response => {
      const res1 = response[0].data?.data?.result[0]
      setTotalRecord(response[0].data?.data?.result[1])

      if (res1) {
        let dataCV = []

        res1?.map((item, index) => {
          let itemGame = {
            username: item?.user?.username,
            recharge: Number(item?.recharge),
            numOfGoal: item?.numOfGoal,
            pointRequest: item?.pointRequest,
            status: item?.status ? "Thành công" : "Thất bại",
            createdAt: moment(item?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
          }
          dataCV.push(itemGame)
        });
        exportToExcel(dataCV)
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const onChangePage = (page) => {
    setPage(page)
  }

  const exportToExcel = (data) => {
    const currentTime = moment().format("DD-MM-YYYY HH-mm-ss");
    const name = `Lịch sử nhận điểm_${currentTime}`;

    const formattedData = data.map(item => ({
      'Tên đăng nhập': item.username,
      'Tổng nạp tại thời điểm nhận thưởng': item.recharge,
      'Bàn thắng quy đổi': item.numOfGoal,
      'Điểm nhận': item.pointRequest,
      'Trạng thái': item.status,
      'Thời gian nhận': item.createdAt,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const getSumPoints = () => {
    let sum = 0;
    dataList?.forEach((e) => {
      if (e?.status) {
        sum += +e?.pointRequest;
      }
    });
    return sum;
  };

  return (
    <div className='col-12'>
      <div className='col-12'>
        <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
          <div className='col-12' style={{ marginBottom: 15, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="usernameFilter"
                label={t('text0002')}
              >
                <Input
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="poitGoldFilter"
                label={'Điểm vàng'}
              >
                <Select
                  allowClear
                  placeholder=""
                  optionFilterProp="children"
                  onChange={(e) => setPointGold(e)}
                  options={listPointGold}
                />
              </Form.Item>
            </div>
          </div>

          <div className='col-12' style={{ marginBottom: 15, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="dateFrom"
                label={t('text0032')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateFrom}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>

            <div className='col-4'>
              <Form.Item
                name="dateTo"
                label={t('text0033')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateTo}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
          </div>

          <div className='payment-search'>
            <Button htmlType='submit' type="primary">{t('text0031')}</Button>
            {auth?.role === "supper" &&
              <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button>
            }
          </div>
        </Form>
      </div>
      <div className='col-12' style={{ margin: '15px 0px', fontWeight: 600 }}>
        <div className='col-12 flex'>
          <div className='col-4'>
            <p>{`Tổng HV tham gia nhận điểm: ${dataReport?.count}`}</p>
          </div>
          <div className='col-5'>
            <p>{`Tổng số bàn thắng đã quy đổi: ${dataReport?.totalGoal}`}</p>
          </div>
        </div>
        <div className='col-12 flex'>
          <div className='col-4'>
            <p>{`Tổng số điểm thưởng đã nhận: ${dataReport?.totalPointRequest}`}</p>
          </div>
          <div className='col-5'>
            <p>{`Tổng nạp của HV đã nhận điểm: ${dataReport?.totalRecharge}`}</p>
          </div>
        </div>
      </div>
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
          <Table
            key='scoreHistory'
            locale={locale}
            columns={tableColums}
            dataSource={dataList}
            pagination={false}
            bordered
            footer={
              dataFilter?.username ? () => {
                return <span style={{ fontWeight: '650' }}>
                  {`Tổng điểm nhận : ${getSumPoints()}`}
                </span>
              }
                : undefined}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </Spin>
    </div>
  )
}

export default ScoreHistory;