import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "./style.css";
import { useTranslation } from "react-i18next";
import { Form, Spin, Button, Select, Table } from 'antd';
import API, { LEAGUE, MATCH } from '../../network/api';
import moment from 'moment';
import 'moment-timezone';

const CreateMatchAuto = () => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [errorMess, setErrorMess] = useState()
  const [dataLeague, setDataLeague] = useState()
  const [leagueId, setLeagueId] = useState()
  const [loading, setLoading] = useState(false)
  const [dataMatchDf, setDataMatchDf] = useState()
  const [dataMatchSl, setDataMatchSl] = useState()

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getAllLeague = async () => {
      await API.get(`${LEAGUE}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.id,
              label: data?.name
            }
            dataCV.push(newI)
          })
          setDataLeague(dataCV);
        } else {
          setDataLeague([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getAllLeague();
  }, []);

  const tableColums = [
    {
      title: t('text0115'),
      key: 'round',
      render: (record) =>
        <span>{record?.round}</span>
    },
    {
      title: t('text0017'),
      key: 'teamName',
      render: (record) =>
        <span>{`${record?.team1Name} - ${record?.team2Name}`}</span>
    },
    {
      title: t('text0020'),
      key: 'timeStart',
      render: (record) =>
        moment(record?.timeStart).utcOffset(0).format('DD-MM-YYYY HH:mm:ss')
    },
  ]

  const onGetListMatch = async () => {
    setLoading(true)
    const params = {
      leagueId: leagueId,
      leagueName: dataLeague.find(x => x.id === leagueId)?.label,
    }

    await API.post(`${MATCH}/getAuto`, params).then((response) => {
      const result = response.data?.data?.result;
      if (result?.length > 0) {
        const resultCv = [];
        result.map((item, index) => {
          const newItem = {
            ...item,
            key: dataLeague.find(x => x.id === leagueId)?.label + ' ' + index
          };
          resultCv.push(newItem)
        })
        setDataMatchDf(resultCv)
        setLoading(false)
      }
    })
  }

  const onSubmitListMatch = async () => {
    setLoading(true)
    const params = {
      leagueId: leagueId,
      leagueName: dataLeague.find(x => x.id === leagueId)?.label,
      matchs: dataMatchSl
    }

    console.log("params: ", params)

    await API.post(`${MATCH}/createAuto`, params).then((response) => {
      const result = response.data?.data;
      if (result?.code === 20001) {
        navigate('/match')
      }
    })
  }

  const backScreen = () => { navigate('/match') }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDataMatchSl(selectedRows)
    }
  };

  const renderForm = () => {
    return (
      <Form className='form-create' form={form} layout="inline" autoComplete='off' onFinish={onGetListMatch}>
        <div className='col-8'>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0065')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="leagues"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  onChange={(e) => setLeagueId(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={dataLeague}
                />
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} />

          <Form.Item shouldUpdate style={{ width: '100%' }}>
            {() => (
              <div className='lg-btn'>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  {'Lấy danh sách trận đấu'}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    )
  }

  return (
    <div className='col-12'>
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {t('text0034')}
      </Button>
      {
        renderForm()
      }
      <h3 style={{ textAlign: 'center', margin: '30px 0' }}>{'DANH SÁCH TRẬN ĐẤU'}</h3>
      <Spin spinning={loading} delay={500} size="large">
        <Table
          // style={{ marginTop: 30 }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={tableColums}
          dataSource={dataMatchDf}
        />
      </Spin>
      <div className='lg-btn'>
        <Button
          type="primary"
          htmlType="format"
          disabled={!dataMatchSl || dataMatchSl?.length === 0}
          onClick={onSubmitListMatch}
        >
          {'Lưu danh sách trận đấu'}
        </Button>
      </div>
    </div>
  )

}

export default CreateMatchAuto;