import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Select, Upload, Spin, Image, Pagination } from 'antd';
import API, { LEAGUE, PLAYER, TEAM } from '../../network/api';
import settingIcon from '../../image/setting.png'
import deleteIcon from '../../image/delete.png'
import ModalCommon from '../../components/Modal';
import moment from 'moment';
import 'moment-timezone';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Player = () => {

  const auth = localStorage.getItem('auth')
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [dataList, setDataList] = useState([])
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowDel, setRowDel] = useState()
  const [isShowModalDel, setIsShowModalDel] = useState(false)
  const [isShowModalDelSuccess, setIsShowModalDelSuccess] = useState(false)
  const [dataFilter, setDataFilter] = useState()
  const [dataTeam, setDataTeam] = useState()
  const [teamId, setTeamId] = useState()

  const locale = {
    emptyText: t('text0035')
  }

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getAllTeam = async () => {
      await API.get(`${TEAM}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            if (data.league.name === "Premier League") {
              const newI = {
                id: data?.id,
                value: data?.id,
                label: data?.name
              }
              dataCV.push(newI)
            }
          })
          setDataTeam(dataCV);
        } else {
          setDataTeam([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getAllTeam();
  }, []);

  useEffect(() => {
    getDataList()
  }, [page, dataFilter]);

  const getDataList = async () => {
    setLoading(true)

    const keyword = (dataFilter?.teamId || dataFilter?.name) ? encodeURIComponent(JSON.stringify({ teamId: dataFilter?.teamId, name: dataFilter?.name })) : null
    const URL = `${PLAYER}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

    await API.get(URL).then(response => {
      const result = response.data?.data?.result;
      if (result.length > 0) {
        let dataCV = result[0];
        setDataList(dataCV);
        setTotalRecord(result[1])
      } else {
        setDataList([]);
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const onSearch = () => {
    let params = {}

    if (teamId) params.teamId = teamId;
    if (form.getFieldValue('nameFilter')) params.name = form.getFieldValue('nameFilter');

    setDataFilter(params ? params : undefined)
    setPage(1)
  }

  const tableColums = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>,
      width: 100
    },
    {
      title: "ID",
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      width: 100
    },
    {
      title: t('text0074'),
      key: 'teamName',
      render: (record) => <span>{record?.team?.name}</span>
    },
    {
      title: t('text0076'),
      dataIndex: 'name',
      key: 'name'
    },
    // {
    //   title: t('text0066'),
    //   key: 'image',
    //   render: (record) =>
    //     <Image width={150} src={record?.image} />
    // },
    {
      title: t('text0012'),
      key: 'setting',
      align: 'center',
      width: 100,
      render: (record) =>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
           <img style={{ height: 20, cursor: 'pointer' }} src={settingIcon} alt='setting'
              onClick={() => {
                navigate(`/updatePlayer/${record.id}`)
              }}
            />
        </div>
    },
    {
      title: t('text0011'),
      key: 'delete',
      align: 'center',
      width: 100,
      render: (record) =>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img style={{ height: 20, cursor: 'pointer' }} src={deleteIcon} alt='del'
            onClick={() => {
              setRowDel(record)
              setIsShowModalDel(true)
            }}
          />
        </div>
    }
  ]

  // const dollarUS = Intl.NumberFormat("en-US");

  // const onChangeDate = (e, type) => {
  //   if (e) {
  //     if (type === 'from')
  //       setDateFrom(moment(e).startOf('day'))
  //     else
  //       setDateTo(moment(e).endOf('day'))
  //   } else {
  //     if (type === 'from')
  //       setDateFrom(null)
  //     else
  //       setDateTo(null)
  //   }
  // }

  // const handleDownloadExcel = () => {
  //   const header = [
  //     t('text0002'), t('text0020'), t('text0017'), t('text0018'), t('text0019'), t('text0014')
  //   ]

  //   const dataEx = []
  //   dataList?.map(item => {
  //     const body = {
  //       username: item?.username, 
  //       time: moment(item?.time).utcOffset(7).format('DD-MM-YYYY HH:mm'),
  //       game: item?.game,
  //       prediction: item?.prediction,
  //       resultGame: item?.resultGame,
  //       score: item?.score
  //     }
  //     dataEx.push(body)
  //   })
  //   downloadExcel({
  //     fileName: "Lịch sử dự đoán",
  //     sheet: "Lịch sử dự đoán",
  //     tablePayload: {
  //       header: header,
  //       body: dataEx
  //     }
  //   });
  // }

  const createData = () => { navigate('/createPlayer') }

  const onDelPoint = async () => {
    setLoading(true)
    await API.delete(`${PLAYER}/${rowDel?.id}`).then(response => {
      const data = response.data?.data;
      if (data?.code === 20005) {
        setIsShowModalDelSuccess(true)
        setIsShowModalDel(false)
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const onChangePage = (page) => {
    setPage(page)
  }

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const backScreen = () => { navigate('/settingSys') }

  return (
    <div className='col-12'>
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {t('text0034')}
      </Button>

      <div className='col-12'>
        <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
          <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
            <div className='col-4'>
              <Form.Item
                name="leagueNameFilter"
                label={t('text0074')}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  onChange={(e) => setTeamId(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={dataTeam}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="nameFilter"
                label={t('text0076')}
              >
                <Input
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            {/* <div className='col-4'>
              <Form.Item
                name="dateFrom"
                label={t('text0032')}
              >
                <DatePicker
                  placeholder=''
                  onChange={(e) => onChangeDate(e, 'from')}
                  format={"DD-MM-YYYY"}
                  style={{ width: '100%' }}
                  disabledDate={d => !d || d.isSameOrAfter(dateTo ? moment(dateTo).add(1, 'd') : moment())}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="dateTo"
                label={t('text0033')}
              >
                <DatePicker
                  placeholder=''
                  onChange={(e) => onChangeDate(e, 'to')}
                  format={"DD-MM-YYYY"}
                  style={{ width: '100%' }}
                  disabledDate={d => !d || d.isSameOrBefore(dateFrom ? dateFrom : null) || d.isSameOrAfter(moment().add(1, 'd'))}
                />
              </Form.Item>
            </div> */}
          </div>
          {/* <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
            <div className='col-6'>
              <div className='col-12' style={{ display: 'flex' }}>
                <div className='col-6'>
                  <span style={{ marginRight: 5 }}>{t('text0067')}:</span>
                  <Upload fileList={fileList} onChange={(fileList) => handleChange(fileList)} onRemove={handleRemove} beforeUpload="/">
                    <Button style={{ display: 'flex', alignItems: 'center' }} icon={<UploadOutlined />}>{t('text0068')}</Button>
                  </Upload>
                </div>
                <div className='col-2'>
                  <Button onClick={uploadData} type="primary">{t('text0069')}</Button>
                </div>
              </div>
            </div>
          </div> */}
          <div className='payment-search'>
            <Button htmlType='submit' type="primary">{t('text0031')}</Button>
            {/* <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button> */}
          </div>
        </Form>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
        <Button type="primary" onClick={createData}>
          {t('text0070')}
        </Button>
      </div>
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
          <Table
            key='dataLeague'
            locale={locale}
            columns={tableColums}
            dataSource={dataList}
            pagination={false}
            bordered
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </Spin>
      {isShowModalDel &&
        <ModalCommon
          isShow={isShowModalDel}
          message={t('text0071')}
          txtBtnAcc={t('text0008')}
          textBtnClose={t('text0023')}
          actionNext={onDelPoint}
          close={() => setIsShowModalDel(false)}
        />
      }
      {isShowModalDelSuccess &&
        <ModalCommon
          isShow={isShowModalDelSuccess}
          message={t('text0072')}
          txtBtnAcc={t('text0008')}
          actionNext={() => {
            setIsShowModalDelSuccess(false)
            getDataList()
          }}
          close={() => {
            setIsShowModalDelSuccess(false)
            getDataList()
          }}
        />
      }
    </div>
  )
}

export default Player;