import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Select, Spin, Pagination, InputNumber } from 'antd';
import API, { EVENT1, TEAM, LEAGUE, PLAYER, EVENT3 } from '../../network/api';
import moment from 'moment';
import 'moment-timezone';
import { downloadExcel } from "react-export-table-to-excel";
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';

const { Option } = Select;

const PredictHistory3 = () => {

    const auth = JSON.parse(localStorage.getItem('auth'))
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const [monthYear, setMonthYear] = useState()
    const [dataFilter, setDataFilter] = useState()
    const [dataList, setDataList] = useState([])
    const [page, setPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [listTypeGame, setListTypeGame] = useState([])
    const [dateFrom, setDateFrom] = useState()
    const [dateTo, setDateTo] = useState()
    const [loading, setLoading] = useState(false);
    const [dataTeam, setDataTeam] = useState()
    const [teamId, setTeamId] = useState()
    const [dataPlayer, setDataPlayer] = useState()
    const [playerIdId, setPlayerId] = useState()

    const locale = {
        emptyText: t('text0035')
    }

    useEffect(() => {
        forceUpdate({});
    }, []);
    useEffect(() => {
        const getAllPlayer = async () => {
            await API.get(`${PLAYER}/all`).then(response => {
                const result = response.data?.data?.result;
                if (result.length > 0) {
                    const dataCV = [];
                    result.map((data) => {
                        const newI = {
                            id: data?.id,
                            value: data?.id,
                            label: data?.name
                        }
                        dataCV.push(newI)
                    })
                    setDataPlayer(dataCV);
                } else {
                    setDataPlayer([]);
                }
            }).catch(error => {
                console.log(error)
            })
        }

        getAllPlayer();

        const getAllTeam = async () => {
            await API.get(`${TEAM}/all`).then(response => {
                const result = response.data?.data?.result;
                if (result.length > 0) {
                    const dataCV = [];
                    result.map((data) => {
                        if (data?.league?.name == "Premier League") {
                            const newI = {
                                id: data?.id,
                                value: data?.id,
                                label: data?.name
                            }
                            dataCV.push(newI)
                        }
                    })
                    setDataTeam(dataCV);
                } else {
                    setDataTeam([]);
                }
            }).catch(error => {
                console.log(error)
            })
        }

        getAllTeam();
    }, []);

    useEffect(() => {
        if (dataPlayer && dataTeam) {
            getDataHis();
        }
    }, [page, dataFilter, dataPlayer, dataTeam]);

    const getDataHis = async () => {
        setLoading(false)

        let dataKey = {};
        if (dataFilter?.username) {
            dataKey.username = dataFilter?.username
        }

        if (dataFilter?.startDate) {
            dataKey.startDate = dataFilter?.startDate
        }

        if (dataFilter?.endDate) {
            dataKey.endDate = dataFilter?.endDate
        }

        if (dataFilter?.teamTop1) {
            dataKey.teamTop1 = dataFilter?.teamTop1
        }

        if (dataFilter?.topGoalScore) {
            dataKey.topGoalScore = dataFilter?.topGoalScore
        }

        if (dataFilter?.pointTop1) {
            dataKey.pointTop1 = dataFilter?.pointTop1
        }

        if (dataFilter?.numbOfScore) {
            dataKey.numbOfScore = dataFilter?.numbOfScore
        }

        const keyword = dataFilter ? encodeURIComponent(JSON.stringify(dataKey)) : null;
        const URL = `${EVENT3}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

        const listData1 = API.get(URL)

        await Promise.all([listData1]).then(response => {
            const res1 = response[0].data?.data?.result[0]
            setTotalRecord(response[0].data?.data?.result[1])

            if (res1) {
                let dataCV = []

                res1?.map((item, index) => {
                    let itemGame = {
                        key: index,
                        id: index,
                        username: item?.user?.username,
                        recharge: Number(item?.recharge),
                        time: item?.createdAt,
                        teamTop1: dataTeam ? dataTeam?.find(x => x.id === item?.teamTop1).label : item?.teamTop1,
                        pointTop1: item?.pointTop1,
                        topGoalScore: dataPlayer ? dataPlayer?.find(x => x.id === item?.topGoalScore).label : item?.topGoalScore,
                        numbOfScore: item?.numbOfScore,
                        createdAt: item?.createdAt,
                    }
                    dataCV.push(itemGame)
                });
                setDataList(dataCV)

                const typeGame = [
                    {
                        id: -1,
                        value: -1,
                        label: t('text0109')
                    },
                    {
                        id: 0,
                        value: 0,
                        label: t('text0110')
                    },
                    {
                        id: 1,
                        value: 1,
                        label: t('text0111')
                    },
                    {
                        id: 2,
                        value: 2,
                        label: t('text0112')
                    }
                ]
                setListTypeGame(typeGame)
            }
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    const getPredStr = (listFilter, id1, id2) => {
        return listFilter.find(t => t.id === id1)?.name + ', ' + listFilter.find(t => t.id === id2)?.name
    }

    const onSearch = () => {
        let params = {}
        if (form.getFieldValue("usernameFilter")) params.username = form.getFieldValue("usernameFilter").trim();

        if (dateFrom) {
            params.startDate = dateFrom
            if (!dateTo) params.endDate = "2123-12-31"
        }
        if (dateTo) {
            if (!dateFrom) params.startDate = "2024-07-31"
            params.endDate = dateTo
        }

        if (teamId) {
            params.teamTop1 = teamId
        }
        if (form.getFieldValue("pointTop1Filter"))
            params.pointTop1 = form.getFieldValue("pointTop1Filter");

        if (playerIdId) {
            params.topGoalScore = playerIdId
        }
        if (form.getFieldValue("numbOfScoreFilter"))
            params.numbOfScore = form.getFieldValue("numbOfScoreFilter");


        setDataFilter(params ? params : undefined)
        setPage(1)
    }

    const tableColums = [
        {
            title: 'STT',
            align: 'center',
            key: 'key',
            dataIndex: 'key',
            width: 100,
            render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>
        },
        {
            title: t('text0002'),
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: 'Tổng nạp',
            dataIndex: 'recharge',
            key: 'recharge'
        },
        {
            title: 'Thời gian dự đoán',
            key: 'time',
            render: (record) =>
                moment(record?.time).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
        },
        {
            title: 'Đội vô địch',
            dataIndex: 'teamTop1',
            key: 'teamTop1'
        },
        {
            title: 'Số điểm',
            dataIndex: 'pointTop1',
            key: 'pointTop1'
        },
        {
            title: 'Vua phá lưới',
            dataIndex: 'topGoalScore',
            key: 'topGoalScore'
        },
        
        {
            title: 'Bàn thắng',
            dataIndex: 'numbOfScore',
            key: 'numbOfScore'
        },
    ]

    const formatData = (record) => {
        // if (record) return

        if (record?.rank) {
            return `Top ${record?.rank} tháng ${record?.month} năm ${record?.year}`;
        } else {
            return listTypeGame?.find(g => g?.value === record?.type)?.label;
        }
    }

    // const getPredict = (type, predict) => {
    //   if (type === 0) {
    //     console.log(listTeam)
    //     console.log(predict)
    //     return listTeam?.find(t => t.value === predict)?.label;
    //   } else {
    //     return listPlayer?.find(t => t.value === predict)?.label;
    //   }
    // }

    const dollarUS = Intl.NumberFormat("en-US");

    const onChangeDate = (e) => {
        if (e) {
            setMonthYear(moment(e))
        } else {
            setMonthYear(null)
        }
    }

    const onChangeDateFrom = (date, dateString) => {
        setDateFrom(dateString)
    }

    const onChangeDateTo = (date, dateString) => {
        setDateTo(dateString)
    }

    const handleDownloadExcel = async () => {
        setLoading(false)

        let dataKey = {};
        if (dataFilter?.username) {
            dataKey.username = dataFilter?.username
        }

        if (dataFilter?.startDate) {
            dataKey.startDate = dataFilter?.startDate
        }

        if (dataFilter?.endDate) {
            dataKey.endDate = dataFilter?.endDate
        }

        if (dataFilter?.teamTop1) {
            dataKey.teamTop1 = dataFilter?.teamTop1
        }

        if (dataFilter?.topGoalScore) {
            dataKey.topGoalScore = dataFilter?.topGoalScore
        }

        const keyword = dataFilter ? encodeURIComponent(JSON.stringify(dataKey)) : null;
        const URL = `${EVENT3}/search?take=1000000&skip=1&order=DESC&keyword=${keyword}`

        const listData1 = API.get(URL)

        await Promise.all([listData1]).then(response => {
            const res1 = response[0].data?.data?.result[0]
            setTotalRecord(response[0].data?.data?.result[1])

            if (res1) {
                let dataCV = []

                res1?.map((item, index) => {
                    let itemGame = {
                        username: item?.user?.username,
                        recharge: Number(item?.recharge),
                        time: moment(item?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
                        teamTop1: dataTeam ? dataTeam?.find(x => x.id === item?.teamTop1).label : item?.teamTop1,
                        pointTop1: item?.pointTop1,
                        topGoalScore: dataPlayer ? dataPlayer?.find(x => x.id === item?.topGoalScore).label : item?.topGoalScore,
                        numbOfScore: item?.numbOfScore,
                    }
                    dataCV.push(itemGame)
                });

                exportToExcel(dataCV)
            }
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    const exportToExcel = (data) => {
        const currentTime = moment().format("DD-MM-YYYY HH-mm-ss");
        const name = `Lịch sử tham gia sự kiện 3_${currentTime}`;
    
        const formattedData = data.map(item => ({
          'Tên đăng nhập': item.username,
          'Tổng nạp': item.recharge,
          'Thời gian dự đoán': item.time,
          'Đội vô địch': item.teamTop1,
          'Số điểm': item.pointTop1,
          'Vua phá lưới': item.topGoalScore,
          'Bàn thắng': item.numbOfScore,
        }));
    
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
      };

    const onChangePage = (page) => {
        setPage(page)
    }

    const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

    return (
        <div className='col-12'>
            <div className='col-12'>
                <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
                    <div className='col-12' style={{ marginBottom: 8, display: 'flex' }}>
                        <div className='col-4'>
                            <Form.Item
                                name="usernameFilter"
                                label={t('text0002')}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
                        <div className='col-4'>
                            <Form.Item
                                name="teamNameFilter"
                                label={'Đội vô địch'}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    onChange={(e) => setTeamId(e)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={dataTeam}
                                />
                            </Form.Item>
                        </div>
                        <div className='col-4'>
                            <Form.Item
                                name="pointTop1Filter"
                                label={'Số điểm'}
                            >
                                <InputNumber
                                    min={1}
                                    max={300}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>

                    </div>

                    <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
                        <div className='col-4'>
                            <Form.Item
                                name="playerNameFilter"
                                label={'Vua phá lưới'}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    onChange={(e) => setPlayerId(e)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={dataPlayer}
                                />
                            </Form.Item>
                        </div>
                        <div className='col-4'>
                            <Form.Item
                                name="numbOfScoreFilter"
                                label={'Bàn thắng'}
                            >
                                <InputNumber
                                    min={1}
                                    max={300}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>

                    </div>

                    <div className='col-12' style={{ marginBottom: 8, display: 'flex' }}>
                        <div className='col-4'>
                            <Form.Item
                                name="dateFrom"
                                label={t('text0032')}
                            >
                                <DatePicker
                                    placeholder=''
                                    onChange={onChangeDateFrom}
                                    format={"YYYY-MM-DD"}
                                    style={{ width: '100%' }}
                                // disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </div>
                        <div className='col-4'>
                            <Form.Item
                                name="dateTo"
                                label={t('text0033')}
                            >
                                <DatePicker
                                    placeholder=''
                                    onChange={onChangeDateTo}
                                    format={"YYYY-MM-DD"}
                                    style={{ width: '100%' }}
                                // disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='payment-search'>
                        <Button htmlType='submit' type="primary">{t('text0031')}</Button>
                        {auth?.role === "supper" &&
                            <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button>
                        }
                    </div>
                </Form>
            </div>
            <div className='col-12' style={{ margin: '15px 0px', fontWeight: 600 }}>
                <div className='col-12 flex'>
                    <div className='col-4'>
                        <p>{`Tổng số HV tham gia SK3: ${totalRecord}`}</p>
                    </div>
                </div>
            </div>
            <Spin spinning={loading} delay={500} size="large">
                <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
                    <Table
                        key='miniGameHis'
                        locale={locale}
                        columns={tableColums}
                        dataSource={dataList}
                        pagination={false}
                        bordered
                        style={{ width: '100%' }}
                    />
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
                    <Pagination
                        current={page}
                        total={totalRecord}
                        onChange={onChangePage}
                        defaultPageSize={10}
                        showSizeChanger={false}
                        showTotal={showTotal}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default PredictHistory3;