import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Select, Upload, Spin, Image, Pagination, InputNumber } from 'antd';
import API, { TEAM_RANK, LEAGUE, GROUP } from '../../network/api';
import settingIcon from '../../image/setting.png'
import deleteIcon from '../../image/delete.png'
import ModalCommon from '../../components/Modal';
import moment from 'moment';
import 'moment-timezone';
import Modal from 'react-bootstrap/Modal';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const TeamRank = () => {

  const auth = localStorage.getItem('auth')
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [dataList, setDataList] = useState([])
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [fileListUp, setFileListUp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowDel, setRowDel] = useState()
  const [isShowModalDel, setIsShowModalDel] = useState(false)
  const [isShowModalDelSuccess, setIsShowModalDelSuccess] = useState(false)
  const [dataFilter, setDataFilter] = useState()
  const [dataLeague, setDataLeague] = useState()
  const [leagueId, setLeagueId] = useState()
  const [isShowModalUpdate, setIsShowModalUpdate] = useState(false)
  const [rowEdit, setRowEdit] = useState()

  const [input1, setInput1] = useState();
  const [input2, setInput2] = useState();
  const [input3, setInput3] = useState();
  const [input4, setInput4] = useState();
  const [input5, setInput5] = useState();
  const [input6, setInput6] = useState();
  const [isUpdate, setIsUpdate] = useState(false)

  const locale = {
    emptyText: t('text0035')
  }

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getAllLeague = async () => {
      await API.get(`${LEAGUE}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.id,
              label: data?.name
            }
            dataCV.push(newI)
          })
          setDataLeague(dataCV);
        } else {
          setDataLeague([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getAllLeague();
  }, []);

  useEffect(() => {
    getDataList()
  }, [page, dataFilter]);

  const getDataList = async () => {
    setLoading(true)

    const keyword = dataFilter?.leagueId ? encodeURIComponent(JSON.stringify({ leagueId: dataFilter?.leagueId })) : null
    const URL = `${TEAM_RANK}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

    await API.get(URL).then(response => {
      const result = response.data?.data?.result;
      if (result.length > 0) {
        let dataCV = result[0];
        setDataList(dataCV);
        setTotalRecord(result[1])
      } else {
        setDataList();
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const onSearch = () => {
    let params = {}

    if (leagueId) params.leagueId = leagueId;

    setDataFilter(params ? params : undefined)
    setPage(1)
  }

  const tableColums = [
    // {
    //   title: 'STT',
    //   align: 'center',
    //   key: 'orderNo',
    //   render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>,
    //   width: 100
    // },
    {
      title: 'ID',
      align: 'center',
      key: 'id',
      dataIndex: 'id',
      width: 100
    },
    // {
    //   title: t('text0065'),
    //   key: 'leagueName',
    //   render: (record) =>
    //     <span>{dataLeague?.find(l => l.id === record?.leagueId)?.label}</span>
    // },
    {
      title: 'TT',
      align: 'center',
      key: 'rank',
      dataIndex: 'rank',
      width: 100
    },
    {
      title: t('text0074'),
      key: 'teamName',
      render: (record) =>
        <span>{record?.team?.name}</span>
    },
    {
      title: t('text0081'),
      align: 'center',
      key: 'numOfMatch',
      dataIndex: 'numOfMatch',
      width: 100
    },
    {
      title: t('text0082'),
      align: 'center',
      key: 'numOfWin',
      dataIndex: 'numOfWin',
      width: 100
    },
    {
      title: t('text0083'),
      align: 'center',
      key: 'numOfDraw',
      dataIndex: 'numOfDraw',
      width: 100
    },
    {
      title: t('text0084'),
      align: 'center',
      key: 'numOfLose',
      dataIndex: 'numOfLose',
      width: 100
    },
    {
      title: t('text0085'),
      align: 'center',
      key: 'score',
      dataIndex: 'score',
      width: 100
    },
    {
      title: t('text0012'),
      key: 'setting',
      align: 'center',
      width: 100,
      render: (record) =>
        <div className='w-full flex justify-center'>
          <img style={{ height: 20, cursor: 'pointer' }} src={settingIcon} alt='del'
            onClick={() => {
              setRowEdit(record)
              setIsShowModalUpdate(true)
            }}
          />
        </div>
    }
    // }
  ]

  const handleRemove = async () => {
    setFileList([]);
  }

  const handleChange = async ({ fileList: newFileList }) => {

    if (!newFileList[0].url && !newFileList[0].preview) {
      newFileList[0].preview = await getBase64(newFileList[0].originFileObj);
    }
    setFileList(newFileList);
  }

  const handleRemoveUp = async () => {
    setFileListUp([]);
  }

  const handleChangeUp = async ({ fileList: newFileList }) => {

    if (!newFileList[0].url && !newFileList[0].preview) {
      newFileList[0].preview = await getBase64(newFileList[0].originFileObj);
    }
    setFileListUp(newFileList);
  }

  const onDelPoint = async () => {
    setLoading(true)
    await API.delete(`${TEAM_RANK}/${rowDel?.id}`).then(response => {
      const data = response.data?.data;
      if (data?.code === 20005) {
        setIsShowModalDelSuccess(true)
        setIsShowModalDel(false)
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const onCloseModalUp = () => {
    setInput1(undefined);
    setInput2(undefined);
    setInput3(undefined);
    setInput4(undefined);
    setInput5(undefined);
    setRowEdit(undefined);
    setIsShowModalUpdate(false);
  }

  const onUpdateRank = async () => {
    setIsUpdate(true)
    const params = {
      rank: input6 ? input6 : rowEdit?.rank,
      numOfMatch: input1 ? input1 : rowEdit?.numOfMatch,
      numOfWin: input2 ? input2 : rowEdit?.numOfWin,
      numOfDraw: input3 ? input3 : rowEdit?.numOfDraw,
      numOfLoss: input4 ? input4 : rowEdit?.numOfLoss,
      score: input5 ? input5 : rowEdit?.score,
    }
    if (!isUpdate) {
      await API.patch(`${TEAM_RANK}/${rowEdit?.id}`, params).then(response => {
        const data = response.data?.data;
        if (data?.code === 20004) {
          setIsShowModalUpdate(false)
          getDataList();
        }
        setIsUpdate(false)
      }).catch(error => {
        console.log(error)
        setIsUpdate(false)
      })
    }
    
  }

  const onChangePage = (page) => {
    setPage(page)
  }

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const createAuto = () => { navigate('/createTeamRankAuto') }

  return (
    <div className='col-12'>
      <div className='col-12'>
        <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
          <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
            <div className='col-4'>
              <Form.Item
                name="leagueNameFilter"
                label={t('text0065')}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  onChange={(e) => setLeagueId(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={dataLeague}
                />
              </Form.Item>
            </div>
            {/* <div className='col-4'>
              <Form.Item
                name="dateFrom"
                label={t('text0032')}
              >
                <DatePicker
                  placeholder=''
                  onChange={(e) => onChangeDate(e, 'from')}
                  format={"DD-MM-YYYY"}
                  style={{ width: '100%' }}
                  disabledDate={d => !d || d.isSameOrAfter(dateTo ? moment(dateTo).add(1, 'd') : moment())}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="dateTo"
                label={t('text0033')}
              >
                <DatePicker
                  placeholder=''
                  onChange={(e) => onChangeDate(e, 'to')}
                  format={"DD-MM-YYYY"}
                  style={{ width: '100%' }}
                  disabledDate={d => !d || d.isSameOrBefore(dateFrom ? dateFrom : null) || d.isSameOrAfter(moment().add(1, 'd'))}
                />
              </Form.Item>
            </div> */}
          </div>

          {/* <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
            <div className='col-6'>
              <div className='col-12' style={{ display: 'flex' }}>
                <div className='col-6'>
                  <span style={{ marginRight: 5 }}>{t('text0067')}:</span>
                  <Upload fileList={fileList} onChange={(fileList) => handleChange(fileList)} onRemove={handleRemove} beforeUpload="/">
                    <Button style={{ display: 'flex', alignItems: 'center' }} icon={<UploadOutlined />}>{t('text0068')}</Button>
                  </Upload>
                </div>
                <div className='col-2'>
                  <Button onClick={uploadData} type="primary">{t('text0069')}</Button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
            <div className='col-6'>
              <div className='col-12' style={{ display: 'flex' }}>
                <div className='col-6'>
                  <span style={{ marginRight: 5 }}>{t('text0086')}:</span>
                  <Upload fileList={fileListUp} onChange={(fileList) => handleChangeUp(fileList)} onRemove={handleRemoveUp} beforeUpload="/">
                    <Button style={{ display: 'flex', alignItems: 'center' }} icon={<UploadOutlined />}>{t('text0068')}</Button>
                  </Upload>
                </div>
                <div className='col-2'>
                  <Button onClick={uploadDataUp} type="primary">{t('text0069')}</Button>
                </div>
              </div>
            </div>
          </div> */}

          <div className='payment-search'>
            <Button htmlType='submit' type="primary">{t('text0031')}</Button>
            {/* <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button> */}
          </div>
        </Form>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
        <Button type="primary" onClick={createAuto} style={{ marginRight: '1rem' }}>
          {t('text0124')}
        </Button>
      </div>
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
          <Table
            key='dataLeague'
            locale={locale}
            columns={tableColums}
            dataSource={dataList}
            pagination={false}
            bordered
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </Spin>
      {isShowModalUpdate &&
        <ModalUpdateData
          isShow={isShowModalUpdate}
          onChangeIp1={(e) => setInput1(e)}
          onChangeIp2={(e) => setInput2(e)}
          onChangeIp3={(e) => setInput3(e)}
          onChangeIp4={(e) => setInput4(e)}
          onChangeIp5={(e) => setInput5(e)}
          onChangeIp6={(e) => setInput6(e)}
          record={rowEdit}
          actionNext={onUpdateRank}
          close={onCloseModalUp}
        />
      }
      {isShowModalDel &&
        <ModalCommon
          isShow={isShowModalDel}
          message={t('text0071')}
          txtBtnAcc={t('text0008')}
          textBtnClose={t('text0023')}
          actionNext={onDelPoint}
          close={() => setIsShowModalDel(false)}
        />
      }
      {isShowModalDelSuccess &&
        <ModalCommon
          isShow={isShowModalDelSuccess}
          message={t('text0071')}
          txtBtnAcc={t('text0008')}
          actionNext={() => {
            setIsShowModalDelSuccess(false)
            getDataList()
          }}
          close={() => {
            setIsShowModalDelSuccess(false)
            getDataList()
          }}
        />
      }
    </div>
  )
}

export default TeamRank;


const ModalUpdateData = ({
  isShow, onChangeIp1, onChangeIp2, onChangeIp3, onChangeIp4, onChangeIp5,
  onChangeIp6, record, actionNext, close, error
}) => {

  return (
    <Modal show={isShow} className='modal-data' onHide={close}>
      <Modal.Header closeButton style={{ fontWeight: 700, fontSize: 16 }}>{"Cập nhật BXH"}</Modal.Header>
      <Modal.Body>
        <div className="col-12 flex" style={{ flexDirection: 'column' }}>
          <div className="col-12 flex">
            <div className="col-3 text-left">{"Giải đấu"}</div>
            <div className="col-8">
              <Input readOnly value={record?.league?.name} />
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{"Đội bóng"}</div>
            <div className="col-8"><Input readOnly value={record?.team?.name} /></div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{"Xếp hạng"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
            <div className="col-8">
              <InputNumber defaultValue={record?.rank} min={1} max={300} onChange={onChangeIp6} />
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{"Số trận"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
            <div className="col-8">
              <InputNumber defaultValue={record?.numOfMatch} min={1} max={300} onChange={onChangeIp1} />
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{"Thắng"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
            <div className="col-8">
              <InputNumber defaultValue={record?.numOfWin} min={0} max={300} onChange={onChangeIp2} />
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{"Hòa"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
            <div className="col-8">
              <InputNumber defaultValue={record?.numOfDraw} min={0} max={300} onChange={onChangeIp3} />
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{"Thua"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
            <div className="col-8">
              <InputNumber defaultValue={record?.numOfLose} min={0} max={300} onChange={onChangeIp4} />
            </div>
          </div>
          <div className="col-12 flex mt-4">
            <div className="col-3 text-left">{"Điểm"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
            <div className="col-8">
              <InputNumber defaultValue={record?.score} min={1} max={300} onChange={onChangeIp5} />
            </div>
          </div>
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button type="primary" onClick={actionNext}>
          Lưu
        </Button>
        <Button type="default" onClick={close}>
          Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  )
}