import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.css";
import { Layout } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import logout from '../../image/logout.png'
import ModalCommon from '../Modal';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

const { Header } = Layout;

const HeaderCustom = ({ username, collapsed, updateCollapsed }) => {

  const { t } = useTranslation();
  const [isShowModal, setIsShowModal] = useState()
  const [titleScreen, setTitleScreen] = useState()
  const location = useLocation()

  useEffect(() => {
    let title
    if (location.pathname.indexOf('/editAccount/') > -1 || location.pathname.indexOf('/createRankByUser/') > -1) {
      location.pathname = '/editAccount/'
    } else if (location.pathname.indexOf('/updateLeague/') > -1) {
      location.pathname = '/updateLeague'
    } else if (location.pathname.indexOf('/updateTeam/') > -1) {
      location.pathname = '/updateTeam'
    } else if (location.pathname.indexOf('/updatePlayer/') > -1) {
      location.pathname = '/updatePlayer'
    } else if (location.pathname.indexOf('/updateGroup/') > -1) {
      location.pathname = '/updateGroup'
    } else if (location.pathname.indexOf('/updateMatch/') > -1) {
      location.pathname = '/updateMatch'
    }

    switch (location.pathname) {
      case '/account': title = t('text0024'); break;
      case '/createAccount': title = t('text0028'); break;
      case '/league':
      case '/createLeague':
      case '/updateLeague': title = t('text0064'); break;
      case '/team':
      case '/createTeam':
      case '/createTeamAuto':
      case '/updateTeam': title = t('text0073'); break;
      case '/player':
      case '/createPlayer':
      case '/updatePlayer': title = t('text0075'); break;
      // case '/group':
      // case '/createGroup':
      // case '/createGroupAuto':
      // case '/updateGroup': title = t('text0077'); break;
      case '/teamRank':
      case '/createTeamRankAuto': title = t('text0079'); break;
      case '/match':
      case '/createMatchAuto':
      case '/updateMatch': title = t('text0087'); break;
      case '/top':
      case '/updateTop': title = t('text0088'); break;
      case '/predHis-1': title = 'Đố vui mỗi tuần'; break;
      case '/predHis-2': title = 'Dự đoán trận đấu'; break;
      case '/predHis-3': title = 'Tiên tri mùa giải'; break;
      case '/goalsHis': title = 'Lịch sử bàn thắng'; break;
      case '/scoreHis': title = t('text0098'); break;
      case '/ev1Result': title = 'Đố vui mỗi tuần'; break;
      case '/ev3Result': title = 'Tiên tri mùa giải'; break;
      case '/matchResult': title = 'Dự đoán trận đấu'; break;
      case '/settingSys': title = t('text0051'); break;
      case '/turnDetail': title = t('text0100'); break;
      case '/updateGoals': title = ' Bù / trừ Bàn thắng - Lượt dự đoán'; break;
      case '/ev1_question': title = 'Câu hỏi Đố Vui Mỗi Tuần'; break;
      case '/ev1_answer': title = 'Câu trả lời Đố Vui Mỗi Tuần'; break;
      case '/ev3_question': title = 'Câu hỏi Tiên Tri Mùa Giải'; break;
      // case '/addTurn': title = t('text0104'); break;
      // case '/log': title = t('text0116'); break;
      // case '/checkDTC': title = t('text0120'); break;
      default: title = ''; break;
    }
    setTitleScreen(title)
  }, [location])

  return (
    <>
      <Header
        className="site-layout-background"
        style={{ padding: 0 }}
      >
        <div className='header-cus'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {collapsed ?
              <MenuUnfoldOutlined className="trigger menu-icon" onClick={updateCollapsed} /> :
              <MenuFoldOutlined className="trigger menu-icon" onClick={updateCollapsed} />
            }
            <span style={{ fontSize: 18, fontWeight: 700 }}>{titleScreen}</span>
          </div>

          <div className='header-right'>
            <span>{username}</span>
            <img className='img-logout' src={logout} alt='logout' onClick={() => setIsShowModal(true)} />
          </div>
        </div>
      </Header>
      {isShowModal &&
        <ModalCommon
          isShow={isShowModal}
          message={t('text0022')}
          txtBtnAcc={t('text0008')}
          textBtnClose={t('text0023')}
          actionNext={() => {
            setIsShowModal(false)
            localStorage.clear()
            window.location.assign('/login')
          }}
          close={() => setIsShowModal(false)}
        />
      }
    </>
  )
}

export default HeaderCustom