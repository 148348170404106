import React, { useState, useEffect } from 'react'
import { Menu } from "antd";
import {
  IdcardFilled,
  FilterFilled,
  SettingFilled,
  DatabaseOutlined,
  TrophyFilled,
  CarryOutFilled,
  EditOutlined,
  BugFilled
} from "@ant-design/icons";
import './style.css'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;

const SideBarCustom = () => {

  const auth = JSON.parse(localStorage.getItem('auth'))
  const permision = auth?.permision;
  const lang = localStorage.getItem('lang');
  const { t, i18n } = useTranslation();
  let location = useLocation()
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/account"
      : location.pathname,
  );

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [])

  useEffect(() => {
    if (location) {
      if (location.pathname === '/createAccount') {
        setCurrent("/account");
      } else if (location.pathname.indexOf('createMatch') > -1) {
        setCurrent("/match");
      } else if (location.pathname.indexOf('League') > -1) {
        setCurrent("/league");
      } else if (location.pathname.indexOf('TeamRank') > -1) {
        setCurrent("/teamRank");
      } else if (location.pathname.indexOf('Team') > -1) {
        setCurrent("/team");
      } else if (location.pathname.indexOf('Player') > -1) {
        setCurrent("/player");
      } else if (location.pathname.indexOf('Group') > -1) {
        setCurrent("/group");
      } else if (location.pathname === '/updateTop') {
        setCurrent("/top");
      } else {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  const handleClick = (e) => {
    setCurrent(e.key);
  }

  return (
    <>
      <div className="logo" >
        Hậu đài dự đoán 2024
      </div>
      <Menu
        onClick={handleClick}
        mode="inline"
        selectedKeys={[current]}
      >
        {auth?.role === "supper" || permision.indexOf("permision001") > -1 ? 
          <Menu.Item style={{ padding: '0 16px' }} key="/account" icon={<IdcardFilled className='menu-icon' />}>
            <Link to="/account">{t('text0024')}</Link>
          </Menu.Item> : null
        }
        {auth?.role === "supper" && 
          <>
            <Menu.Item style={{ padding: '0 16px' }} key="/league" icon={<DatabaseOutlined className='menu-icon' />}>
              <Link to="/league">{t('text0064')}</Link>
            </Menu.Item>
            <Menu.Item style={{ padding: '0 16px' }} key="/team" icon={<DatabaseOutlined className='menu-icon' />}>
              <Link to="/team">{t('text0073')}</Link>
            </Menu.Item>
            <Menu.Item style={{ padding: '0 16px' }} key="/match" icon={<DatabaseOutlined className='menu-icon' />}>
              <Link to="/match">{t('text0087')}</Link>
            </Menu.Item>
            <Menu.Item style={{ padding: '0 16px' }} key="/teamRank" icon={<TrophyFilled className='menu-icon' />}>
              <Link to="/teamRank">{t('text0079')}</Link>
            </Menu.Item>
          </>
        }
        <SubMenu
          key='his'
          title='Lịch Sử'
          style={{ paddingLeft: 0 }}
        >
          {auth?.role === "supper" || permision.indexOf("permision002") > -1 ? 
            <Menu.Item style={{ marginLeft: '8px' }} key="/predHis-1" icon={<FilterFilled className='menu-icon' />}>
              <Link to="/predHis-1">Sự kiện 1</Link>
            </Menu.Item> : null
          }
          {auth?.role === "supper" || permision.indexOf("permision003") > -1 ? 
            <Menu.Item style={{ marginLeft: '8px' }} key="/predHis-2" icon={<FilterFilled className='menu-icon' />}>
              <Link to="/predHis-2">Sự kiện 2</Link>
            </Menu.Item> : null 
          }
          {auth?.role === "supper" || permision.indexOf("permision004") > -1 ? 
            <Menu.Item style={{ marginLeft: '8px' }} key="/predHis-3" icon={<FilterFilled className='menu-icon' />}>
              <Link to="/predHis-3">Sự kiện 3</Link>
            </Menu.Item> : null
          }
          {auth?.role === "supper" || permision.indexOf("permision005") > -1 ? 
            <Menu.Item style={{ marginLeft: '8px' }} key="/turnDetail" icon={<FilterFilled className='menu-icon' />}>
              <Link to="/turnDetail">{t('text0100')}</Link>
            </Menu.Item> : null 
          }
          {auth?.role === "supper" || permision.indexOf("permision006") > -1 ? 
            <Menu.Item style={{ marginLeft: '8px' }} key="/goalsHis" icon={<FilterFilled className='menu-icon' />}>
              <Link to="/goalsHis">Bàn thắng</Link>
            </Menu.Item> : null
          }
          {auth?.role === "supper" || permision.indexOf("permision007") > -1 ? 
            <Menu.Item style={{ marginLeft: '8px' }} key="/scoreHis" icon={<FilterFilled className='menu-icon' />}>
              <Link to="/scoreHis">Nhận điểm</Link>
            </Menu.Item> : null
          }
        </SubMenu>
        {auth?.role === "supper" && 
          <SubMenu
            key='result'
            title='Cập nhật kết quả'
            style={{ paddingLeft: 0 }}
          >
            <Menu.Item style={{ paddingLeft: 18 }} key="/matchResult" icon={<EditOutlined />}>
              <Link to="/matchResult">Dự đoán trận đấu</Link>
            </Menu.Item>
          </SubMenu>
        }

        {auth?.role === "supper" &&
          <>
            <Menu.Item style={{ padding: '0 16px' }} key="/updateGoals" icon={<EditOutlined className='menu-icon' />}>
              <Link to="/updateGoals">{t('text0104')}</Link>
            </Menu.Item>
            <Menu.Item style={{ padding: '0 16px' }} key="/settingSys" icon={<SettingFilled className='menu-icon' />}>
              <Link to="/settingSys">{t('text0051')}</Link>
            </Menu.Item>
          </>
        }
      </Menu >
    </>
  )
}

export default SideBarCustom