import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Select, Upload, Spin, Image, Pagination, Switch } from 'antd';
import API, { PLAYER, EVENT1_QUESTION, EVENT1_ANSWER } from '../../network/api';
import settingIcon from '../../image/setting.png'
import deleteIcon from '../../image/delete.png'
import ModalCommon from '../../components/Modal';
import moment from 'moment';
import 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import dayjs from 'dayjs';

const { Option } = Select;

const Ev1question = () => {

    const auth = localStorage.getItem('auth')
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const navigate = useNavigate()
    const [dateFrom, setDateFrom] = useState()
    const [dateTo, setDateTo] = useState()
    const [dataList, setDataList] = useState([])
    const [page, setPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowDel, setRowDel] = useState()
    const [isShowModalDel, setIsShowModalDel] = useState(false)
    const [isShowModalDelSuccess, setIsShowModalDelSuccess] = useState(false)
    const [dataFilter, setDataFilter] = useState()
    const [isShowModalCU, setIsShowModalCU] = useState(false)
    const [isCreatedData, setIsCreatedData] = useState(false)

    const [input1, setInput1] = useState();
    const [input2, setInput2] = useState();
    const [input3, setInput3] = useState();
    const [input4, setInput4] = useState();
    const [input5, setInput5] = useState();
    const [input6, setInput6] = useState();
    const [input7, setInput7] = useState();
    const [input8, setInput8] = useState();
    const [input9, setInput9] = useState();
    const [errorCU, setErrorCU] = useState(); 
    const [rowEdit, setRowEdit] = useState();

    const locale = {
        emptyText: t('text0035')
    }

    useEffect(() => {
        forceUpdate({});
    }, []);

    useEffect(() => {
        getDataList()
    }, [page, dataFilter]);

    const getDataList = async () => {
        setLoading(true)
        const keyword = (dataFilter?.name || dataFilter?.startDate || dataFilter?.endDate) ?
            encodeURIComponent(JSON.stringify({ content: dataFilter?.name, startDate: dataFilter?.startDate, endDate: dataFilter?.endDate })) : null
        const URL = `${EVENT1_QUESTION}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

        const listData1 = API.get(URL)
        await Promise.all([listData1]).then(response => {
            const result1 = response[0].data?.data?.result;

            if (result1[0].length > 0) {
                let dataCV = []

                result1[0]?.map((item) => {
                    let itemNew = {
                        id: item?.id,
                        content: item?.content,
                        dateEnd: item?.dateEnd,
                        result1: item?.result1,
                        result2: item?.result2,
                        isComplete: item?.isComplete,
                        answer1: item?.answer1,
                        listAnswer1: item?.listAnswer1,
                        answer2: item?.answer2,
                        listAnswer2: item?.listAnswer2,
                        createdAt: item?.createdAt
                    }

                    dataCV.push(itemNew)
                })
                setDataList(dataCV)
                setTotalRecord(result1[1])
            } else {
                setDataList([]);
            }
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    const onSearch = () => {
        let params = {}

        if (form.getFieldValue('nameFilter')) params.name = form.getFieldValue('nameFilter');
        if (dateFrom) {
            params.startDate = dateFrom
            if (!dateTo) params.endDate = "2123-12-31"
        }
        if (dateTo) {
            if (!dateFrom) params.startDate = "2024-07-31"
            params.endDate = dateTo
        }

        setDataFilter(params ? params : undefined)
        setPage(1)
    }

    const tableColums = [
        // {
        //     title: 'STT',
        //     align: 'center',
        //     key: 'order',
        //     render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>,
        //     width: 100
        // },
        {
            title: 'Câu hỏi',
            key: 'content',
            width: 250,
            render: (record) => <span>{record?.content}</span>
        },
        {
            title: 'Ngày kết thúc',
            dataIndex: 'dateEnd',
            key: 'dateEnd',
            render: (record) =>
                moment(record).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
        },
        {
            title: 'Đáp án 1',
            key: 'answer1',
            width: 200,
            render: (record, idx) =>
                <div key={idx} className='w-auto flex flex-col justify-center'>
                    <div className='flex flex-row'>
                        <span className='w-[40%] font-semibold text-start'>Tiêu đề:</span>
                        <span className=' text-start'>
                            {record?.answer1}
                        </span>
                    </div>
                    <div className='flex flex-row'>
                        <span className='font-semibold text-start'>D/s câu trả lời:</span>
                    </div>
                    <div className='flex flex-row'>
                        <span className=' text-start'>
                            {record?.listAnswer1?.replaceAll(",", ", ")}
                        </span>
                    </div>
                </div>
        },
        {
            title: 'Đáp án 2',
            width: 200,
            key: 'answer2',
            render: (record, idx) =>
                <div key={idx} className='w-auto flex flex-col justify-center'>
                    <div className='flex flex-row'>
                        <span className='w-[40%] font-semibold text-start'>Tiêu đề:</span>
                        <span className=' text-start'>
                            {record?.answer2}
                        </span>
                    </div>

                    <div className='flex flex-row'>
                        <span className='font-semibold text-start'>D/s câu trả lời:</span>
                    </div>
                    <div className='flex flex-row'>
                        <span className=' text-start'>
                            {record?.listAnswer2?.replaceAll(",", ", ")}
                        </span>
                    </div>
                </div>
        },
        {
            title: "Kết quả 1",
            align: 'center',
            key: 'result1',
            render: (record) => <span>{record?.result1 ? record?.result1 : "Chưa có"}</span>
        },
        {
            title: "Kết quả 2",
            align: 'center',
            key: 'result2',
            render: (record) => <span>{record?.result2 ? record?.result2 : "Chưa có"}</span>
        },
        {
            title: "Trạng thái",
            align: 'center',
            key: 'complete',
            width: 100,
            render: (record) => <p>{record?.isComplete ? "Công bố" : "Chưa công bố"}</p>
        },
        {
            title: t('text0012'),
            key: 'setting',
            align: 'center',
            width: 100,
            render: (record) =>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img style={{ height: 20, cursor: 'pointer' }} src={settingIcon} alt='setting'
                        onClick={() => {
                            setRowEdit(record);
                            setIsShowModalCU(true);
                        }}
                    />
                </div>
        },
        {
            title: t('text0011'),
            key: 'delete',
            align: 'center',
            width: 100,
            render: (record) =>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img style={{ height: 20, cursor: 'pointer', textAlign: 'center' }} src={deleteIcon} alt='del'
                        onClick={() => {
                            setRowDel(record)
                            setIsShowModalDel(true)
                        }}
                    />
                </div>
        }
    ]

    const createData = () => {
        setIsShowModalCU(true) 
    }

    const onCreateData = async () => {
        if (
            !input1 || !input2 || input1?.trim().length == 0 ||
            !input3 || input3?.trim().length == 0 ||
            !input4 || input4?.trim().length == 0 ||
            !input5 || input5?.trim().length == 0 ||
            !input6 || input6?.trim().length == 0
        ) {
            setErrorCU("Bạn chưa nhập đầy đủ thông tin")
            return
        }
        setIsCreatedData(true)
        setLoading(true)

        if(!isCreatedData) {
            const params = {
                content: input1.trim(),
                dateEnd: moment(input2, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
                answer1: input3,
                listAnswer1: input4,
                answer2: input5,
                listAnswer2: input6,
            }
            await API.post(`${EVENT1_QUESTION}/create`, params).then(response => {
                const data = response.data?.data;    
                if (data?.code === 20001) {
                    onSearch();
                    setIsShowModalCU(false);
                }
                setLoading(false)
                setIsCreatedData(false)
            }).catch(error => {
                console.log(error)
                setLoading(false)
                setIsCreatedData(false)
            })
        }
    }

    const onUpdateData = async () => {
        // if (
        //     input1?.trim().length == 0 ||
        //     input3?.trim().length == 0 ||
        //     input4?.trim().length == 0 ||
        //     input5?.trim().length == 0 ||
        //     input6?.trim().length == 0
        // ) {
        //     setErrorCU("Bạn chưa nhập đầy đủ thông tin")
        //     return
        // }
        setIsCreatedData(true)
        setLoading(true)

        if(!isCreatedData) {
            const params = {
                content: input1 ? input1?.trim() : rowEdit?.content,
                dateEnd: input2 ? moment(input2, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : rowEdit?.dateEnd,
                answer1: input3 ? input3?.trim() : rowEdit?.answer1,
                listAnswer1: input4 ? input4.trim() : rowEdit?.listAnswer1,
                answer2: input5 ? input5.trim() : rowEdit?.answer2,
                listAnswer2: input6 ? input6.trim() : rowEdit?.listAnswer2,
                result1: input7 || rowEdit?.result1,
                result2: input8 || rowEdit?.result2,
                isComplete: input9  || rowEdit?.isComplete
            }
            await API.patch(`${EVENT1_QUESTION}/${rowEdit?.id}`, params).then(response => {
                const data = response.data?.data; 
                if (data?.code === 20004) {
                    onSearch();
                    closeModalCU()
                }
                setLoading(false)
                setIsCreatedData(false)
            }).catch(error => {
                console.log(error)
                setLoading(false)
                setIsCreatedData(false)
            })
        }
        
    }

    const onDelPoint = async () => {
        setLoading(true)
        await API.delete(`${EVENT1_QUESTION}/${rowDel?.id}`).then(response => {
            const data = response.data?.data;
            if (data?.code === 20005) {
                setIsShowModalDelSuccess(true)
                setIsShowModalDel(false)
            }
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    const onChangePage = (page) => {
        setPage(page)
    }

    const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

    const backScreen = () => { navigate('/settingSys') }

    const onChangeDateFrom = (date, dateString) => {
        setDateFrom(dateString)
    }

    const onChangeDateTo = (date, dateString) => {
        setDateTo(dateString)
    }

    const closeModalCU = () => {
        setIsShowModalCU(false);
        setErrorCU(undefined);
        setRowEdit(undefined)
        setInput1(undefined);
        setInput2(undefined);
        setInput3(undefined);
        setInput4(undefined);
        setInput5(undefined);
        setInput6(undefined);
        setInput7(undefined);
        setInput8(undefined);
        setInput9(undefined);
    }

    return (
        <div className='col-12'>
            <div className='col-12'>
                <Button
                    type="default"
                    htmlType="button"
                    style={{ marginBottom: 30 }}
                    onClick={backScreen}
                >
                    {t('text0034')}
                </Button>

                <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
                    <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
                        <div className='col-8'>
                            <Form.Item
                                name="nameFilter"
                                label={'Câu hỏi'}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>

                    </div>

                    <div className='col-12' style={{ marginBottom: 8, display: 'flex' }}>
                        <div className='col-4'>
                            <Form.Item
                                name="dateFrom"
                                label={t('text0032')}
                            >
                                <DatePicker
                                    placeholder=''
                                    onChange={onChangeDateFrom}
                                    format={"YYYY-MM-DD"}
                                    style={{ width: '100%' }}
                                // disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </div>
                        <div className='col-4'>
                            <Form.Item
                                name="dateTo"
                                label={t('text0033')}
                            >
                                <DatePicker
                                    placeholder=''
                                    onChange={onChangeDateTo}
                                    format={"YYYY-MM-DD"}
                                    style={{ width: '100%' }}
                                // disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='payment-search'>
                        <Button htmlType='submit' type="primary">{t('text0031')}</Button>
                        {/* <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button> */}
                    </div>
                </Form>
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
                <Button type="primary" onClick={createData}>
                    {t('text0070')}
                </Button>
            </div>
            <Spin spinning={loading} delay={500} size="large">
                <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
                    <Table
                        key='dataLeague'
                        locale={locale}
                        columns={tableColums}
                        dataSource={dataList}
                        pagination={false}
                        bordered
                        style={{ width: '100%' }}
                    />
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
                    <Pagination
                        current={page}
                        total={totalRecord}
                        onChange={onChangePage}
                        defaultPageSize={10}
                        showSizeChanger={false}
                        showTotal={showTotal}
                    />
                </div>
            </Spin>
            {isShowModalDel &&
                <ModalCommon
                    isShow={isShowModalDel}
                    message={t('text0071')}
                    txtBtnAcc={t('text0008')}
                    textBtnClose={t('text0023')}
                    actionNext={onDelPoint}
                    close={() => setIsShowModalDel(false)}
                />
            }
            {isShowModalDelSuccess &&
                <ModalCommon
                    isShow={isShowModalDelSuccess}
                    message={t('text0072')}
                    txtBtnAcc={t('text0008')}
                    actionNext={() => {
                        setIsShowModalDelSuccess(false)
                        getDataList()
                    }}
                    close={() => {
                        setIsShowModalDelSuccess(false)
                        getDataList()
                    }}
                />
            }
            {isShowModalCU &&
                <ModalCreateData 
                    isShow={isShowModalCU}
                    onChangeIp1={(e) => setInput1(e.target.value)}
                    onChangeIp2={(e, dateString) => setInput2(dateString)}
                    onChangeIp3={(e) => setInput3(e.target.value)}
                    onChangeIp4={(e) => setInput4(e.target.value)}
                    onChangeIp5={(e) => setInput5(e.target.value)}
                    onChangeIp6={(e) => setInput6(e.target.value)}
                    onChangeIp7={(e) => setInput7(e)}
                    onChangeIp8={(e) => setInput8(e)}
                    onChangeIp9={(e) => setInput9(e)}
                    record={rowEdit}
                    actionNext={rowEdit ? onUpdateData : onCreateData}
                    close={closeModalCU}
                    mode={rowEdit ? "update" : "create"}
                    error={errorCU}
                />
            }
        </div>
    )
}

export default Ev1question

const ModalCreateData = ({
    isShow, onChangeIp1, onChangeIp2, onChangeIp3, onChangeIp4, onChangeIp5,
    onChangeIp6, onChangeIp7, onChangeIp8, onChangeIp9,
    record, actionNext, close, mode, error
}) => {

    const [listAnswer1, setListAnswer1] = useState(record?.listAnswer1?.split(","))
    const [listAnswer2, setListAnswer2] = useState(record?.listAnswer2?.split(","))

    const onChangeListAnswer1 = (e) => {
        onChangeIp4(e)
        setListAnswer1(e?.target.value?.split(","))
    }

    const onChangeListAnswer2 = (e) => {
        onChangeIp6(e)
        setListAnswer2(e?.target.value?.split(","))
    }

    return (
        <Modal show={isShow} className='modal-data' onHide={close}>
            <Modal.Header closeButton style={{ fontWeight: 700, fontSize: 16 }}>{mode === "create" ? "Tạo bộ câu hỏi" : "Cập nhật bộ câu hỏi"}</Modal.Header>
            <Modal.Body>
                <div className="col-12 flex" style={{ flexDirection: 'column' }}>
                    {mode === "create" &&
                        <>
                            <div className="col-12 flex">
                                <div className="col-3 text-left">{"Câu hỏi"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input.TextArea defaultValue={record?.record1} onChange={onChangeIp1} />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"Ngày kết thúc"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <DatePicker
                                        placeholder=''
                                        onChange={onChangeIp2}
                                        format={"DD-MM-YYYY HH:mm:ss"}
                                        showTime={true}
                                        style={{ width: '100%' }}
                                        disabledDate={d => d && d < dayjs().add(-1, 'days').endOf('day')}
                                    />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"Câu trả lời 1"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input onChange={onChangeIp3} />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"D/s câu trả lời 1"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input.TextArea onChange={onChangeIp4} />
                                </div>
                            </div>
                            <div className="col-12 flex">
                                <div className="col-3 text-left"></div>
                                <div className="col-8" style={{ color: 'red' }}>NOTE: Mỗi câu trả lời phân cách nhau bởi dấu ","</div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"Câu trả lời 2"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input onChange={onChangeIp5} />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"D/s câu trả lời 2"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input.TextArea onChange={onChangeIp6} />
                                </div>
                            </div>
                            <div className="col-12 flex">
                                <div className="col-3 text-left"></div>
                                <div className="col-8" style={{ color: 'red' }}>NOTE: Mỗi câu trả lời phân cách nhau bởi dấu ","</div>
                            </div>
                        </>
                    }
                    {mode === "update" &&
                        <>
                            <div className="col-12 flex">
                                <div className="col-3 text-left">{"Câu hỏi"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input.TextArea defaultValue={record?.content} onChange={onChangeIp1} />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"Ngày kết thúc"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <DatePicker
                                        defaultValue={dayjs(record?.dateEnd)}
                                        placeholder=''
                                        onChange={onChangeIp2}
                                        format={"DD-MM-YYYY HH:mm:ss"}
                                        showTime={true}
                                        style={{ width: '100%' }}
                                        disabledDate={d => d && d < dayjs().add(-1, 'days').endOf('day')}
                                    />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"Câu trả lời 1"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input defaultValue={record?.answer1} onChange={onChangeIp3} />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"D/s câu trả lời 1"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input.TextArea defaultValue={record?.listAnswer1} onChange={onChangeListAnswer1} />
                                </div>
                            </div>
                            <div className="col-12 flex">
                                <div className="col-3 text-left"></div>
                                <div className="col-8" style={{ color: 'red' }}>NOTE: Mỗi câu trả lời phân cách nhau bởi dấu ","</div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"Câu trả lời 2"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input defaultValue={record?.answer2} onChange={onChangeIp5} />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"D/s câu trả lời 2"}<span style={{ color: 'red', marginLeft: 5 }}>*</span></div>
                                <div className="col-8">
                                    <Input.TextArea defaultValue={record?.listAnswer2} onChange={onChangeListAnswer2} />
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"Kết quả 1"}</div>
                                <div className="col-8">
                                    <Select defaultValue={record?.result1} style={{ width: '100%' }} onChange={onChangeIp7}>
                                        {listAnswer1?.map((item, index) => (
                                            <Option key={`anwser1-${index}`} value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"Kết quả 2"}</div>
                                <div className="col-8">
                                    <Select defaultValue={record?.result2} style={{ width: '100%' }} onChange={onChangeIp8}>
                                        {listAnswer2?.map((item, index) => (
                                            <Option key={`anwser2-${index}`} value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="col-12 flex mt-4">
                                <div className="col-3 text-left">{"Trạng thái"}</div>
                                <div className="col-8">
                                    <Switch
                                        checkedChildren="Công bố"
                                        unCheckedChildren="Chưa công bố"
                                        defaultChecked={record?.isComplete}
                                        style={{ width: 150, marginTop: "5px" }}
                                        onChange={onChangeIp9}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center' }}>
                <Button type="primary" onClick={actionNext}>
                    Lưu
                </Button>
                <Button type="default" onClick={close}>
                    Đóng
                </Button>
            </Modal.Footer>
        </Modal>
    )
}