import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, DatePicker, Table, Radio, Spin, Pagination, Select, InputNumber } from 'antd';
import API, { MATCH, MATCH_RESULT, LEAGUE, TEAM, PLAYER, MINIGAME, MINIGAME_RESULT } from '../../network/api';
import moment from 'moment';
import 'moment-timezone';

const { Option } = Select;

const sttReceived = [
  {
    id: 0,
    value: "1",
    label: 'Đã xác nhận'
  },
  {
    id: 0,
    value: "0",
    label: 'Chưa xác nhận'
  },
]

const MatchResult = () => {

  const auth = JSON.parse(localStorage.getItem('auth'))
  const { t } = useTranslation();
  const [, forceUpdate] = useState({});
  const [listGame, setListGame] = useState([])
  const [resultUpdate, setresultUpdate] = useState()
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const locale = {
    emptyText: t('text0035')
  }
  const [loading, setLoading] = useState(false);
  const [dataFilter, setDataFilter] = useState()
  const [dataLeague, setDataLeague] = useState([])
  const [leagueId, setLeagueId] = useState()
  const [dataTeam, setDataTeam] = useState([])
  const [teamId, setTeamId] = useState()
  const [form] = Form.useForm();
  const [matchIdSl, setMatchIdSl] = useState();
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [status, setStatus] = useState()

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getAllDataLeague = async () => {

      const dataLeague = API.get(`${LEAGUE}/all`)
      const dataTeam = API.get(`${TEAM}/all`)

      await Promise.all([dataLeague, dataTeam]).then(response => {
        const result1 = response[0].data?.data?.result;
        const result2 = response[1].data?.data?.result;
        if (result1.length > 0 && result2.length > 0) {
          const dataLeagueCV = [];
          result1.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.id,
              label: data?.name
            }
            dataLeagueCV.push(newI)
          })
          setDataLeague(dataLeagueCV);

          const dataTeamCV = [];
          result2.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.id,
              label: data?.name
            }
            dataTeamCV.push(newI)
          })
          setDataTeam(dataTeamCV);
        } else {
          setDataLeague([])
          setDataTeam([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getAllDataLeague()
  }, []);

  useEffect(() => {
    getDataMatch()

  }, [page, dataFilter]);

  const getDataMatch = async () => {
    setLoading(true)
    const keyword = (dataFilter?.leagueId || dataFilter?.teamId || dataFilter?.startDate || dataFilter?.endDate || dataFilter?.status) ?
      encodeURIComponent(JSON.stringify({ 
        leagueId: dataFilter?.leagueId, teamId: dataFilter?.teamId,
        startDate: dataFilter?.startDate, endDate: dataFilter?.endDate,
        status: dataFilter?.status
       })) : null
    const URL1 = `${MATCH}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

    const dataList1 = API.get(URL1)
    const dataList2 = API.get(`${MATCH_RESULT}/all`)

    await Promise.all([dataList1, dataList2]).then(response => {
      const res1 = response[0].data?.data?.result[0]
      const res2 = response[1].data?.data?.result
      if (res1 && res2) {
        let dataCV = []
        res1?.map((item) => {
          if (res2) {
            let itemNew = {
              key: item?.id,
              matchId: item?.id,
              matResultId: typeof res2?.find(r => r?.matchId === item?.id)?.isApprove !== 'undefined' ? res2?.find(r => r?.matchId === item?.id)?.id : undefined,
              time: moment(item?.timeStart).utcOffset(7).format('DD-MM-YYYY HH:mm'),
              game: item?.team1?.name + " - " + item?.team2?.name,
              team1Id: item?.team1?.id,
              team1Name: item?.team1?.name,
              team2Id: item?.team2?.id,
              team2Name: item?.team2?.name,
              leagueId: item?.league?.id,
              isApprove: 
                typeof res2?.find(r => r?.matchId === item?.id)?.isApprove !== 'undefined' ? 
                res2?.find(r => r?.matchId === item?.id)?.isApprove 
                : undefined,
              resultAuto: 
                typeof res2?.find(r => r?.matchId === item?.id)?.resultAuto !== 'undefined' ? 
                res2?.find(r => r?.matchId === item?.id)?.resultAuto == 0 ? "Hòa" : 
                dataTeam?.find(l => l.id === res2?.find(r => r?.matchId === item?.id)?.resultAuto)?.label
                : undefined,
              resultByAdmin: 
                typeof res2?.find(r => r?.matchId === item?.id)?.resultByAdmin !== 'undefined' ? 
                res2?.find(r => r?.matchId === item?.id)?.resultByAdmin == 0 ? "Hòa" : 
                dataTeam?.find(l => l.id === res2?.find(r => r?.matchId === item?.id)?.resultByAdmin)?.label
                : undefined
            }

            dataCV.push(itemNew)
          }
        })
        dataCV = dataCV.sort((a, b) => moment(a.time) - moment(b.time))
        // dataCV = dataCV.filter((data) => moment(data?.time).isBefore(moment()))
        setTotalRecord(response[0].data?.data?.result[1])
        setListGame(dataCV)
        console.log('dataListGmae: ', dataCV)
        setLoading(false)
      }
    }).catch(error => {
      setLoading(false)
      console.log(error)
    })
  }

  const onSearch = () => {
    let params = {}
    setPage(1)
    if (leagueId) params.leagueId = leagueId;
    if (teamId) params.teamId = teamId;
    if (dateFrom) {
      params.startDate = dateFrom
      if (!dateTo) params.endDate = "2123-12-31"
    }
    if (dateTo) {
      if (!dateFrom) params.startDate = "2024-07-31"
      params.endDate = dateTo
    }
    if (status) {
      params.status = status
    }

    setDataFilter(params ? params : undefined)
  }

  const onChangeDateFrom = (date, dateString) => {
    setDateFrom(dateString)
  }

  const onChangeDateTo = (date, dateString) => {
    setDateTo(dateString)
  }

  const onUpdateResult = async (gameItem) => {

    if (gameItem?.matchId !== matchIdSl) {
      alert(`Trận đấu cập nhập không chính xác!`)
      return
    }

    if (resultUpdate === undefined) {
      alert(`Chưa nhập kết quả trận đấu!`)
      return
    }

    const params = {
      matchId: gameItem?.matchId,
      result: resultUpdate,
      isApprove: true
    }
    await API.patch(`${MATCH_RESULT}/${gameItem?.matResultId}`, params)
      .then(response => {
        if (response.data?.data?.code === 20004) {
          alert(`Cập nhật kết quả trận đấu ${gameItem?.game} thành công`)
          getDataMatch()
          setresultUpdate(undefined)
        }
      })
  }

  const onInputResult = (e, game) => {
    const value = e;
    setresultUpdate(value)
    setMatchIdSl(game?.matchId)
  }

  const getMatchAuto = async () => {
    const URL = `batch-match/test`

    await API.get(URL).then(response => {
      const result = response.data

      if (result?.statusCode === 200) {
        alert('Lấy kết quả trận đấu thành công !!!')
        getDataMatch()
      }
    }).catch(error => {
      console.log(error)

    })
  }

  const onChangePage = (page) => {
    setPage(page)
  }

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const tableColums = [
    {
      title: t('text0065'),
      key: 'leagueName',
      render: (record) =>
        <span>{dataLeague?.find(l => l.id === record.leagueId)?.label}</span>
    },
    {
      title: t('text0017'),
      key: 'game',
      dataIndex: 'game'
    },
    {
      title: t('text0020'),
      key: 'time',
      dataIndex: 'time',
    },
    {
      title:'Kết quả tự động',
      key: 'resultAuto',
      dataIndex: 'resultAuto'
    },
    {
      title:'Kết quả xác nhận',
      key: 'resultByAdmin',
      // dataIndex: 'resultByAdmin'
      render: (record) =>
        record?.isApprove ?
          <span>{record?.resultByAdmin}</span> :
          <Select
            style={{ width: '100%' }}
            allowClear
            disabled={record?.isApprove || typeof record?.resultAuto === 'undefined'}
            showSearch
            placeholder=""
            optionFilterProp="children"
            onChange={(e) => onInputResult(e, record)}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                id: 0,
                value: 0,
                label: 'Hòa'
              }
              , {
                id: 1,
                value: record?.team1Id,
                label: record?.team1Name
              },
              {
                id: 2,
                value: record?.team2Id,
                label: record?.team2Name
              }]}
          />
    },
    {
      title:'Trạng thái',
      key: 'isApprove',
      render: (record) =>
        <span>{record?.isApprove ? "Đã xác nhận" : "Chưa xác nhận"}</span>
    },
    {
      title: 'Hành động',
      key: 'confirm',
      align: 'center',
      width: 100,
      render: (record) =>
        <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={record?.isApprove || typeof record?.resultAuto === 'undefined'}
            onClick={!record?.isApprove ? () => onUpdateResult(record) : () => { }}
          >
            {t('text0012')}
          </Button>
        </div>
    }
  ]

  const renderMatchResult = () => {
    return (
      <>
        <div className='col-12'>
          <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
            <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
              <div className='col-4'>
                <Form.Item
                  name="leagueNameFilter"
                  label={t('text0065')}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder=""
                    optionFilterProp="children"
                    onChange={(e) => setLeagueId(e)}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={dataLeague}
                  />
                </Form.Item>
              </div>
              <div className='col-4'>
                <Form.Item
                  name="teamFilter"
                  label={t('text0074')}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder=""
                    optionFilterProp="children"
                    onChange={(e) => setTeamId(e)}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={dataTeam}
                  />
                </Form.Item>
              </div>
              <div className='col-4'>
                <Form.Item
                  name="receiveStt"
                  label={'Trạng thái'}
                >
                  <Select
                    allowClear
                    placeholder=""
                    optionFilterProp="children"
                    onChange={(e) => setStatus(e)}
                    options={sttReceived}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
              <div className='col-4'>
                <Form.Item
                  name="dateFrom"
                  label={t('text0032')}
                >
                  <DatePicker
                    placeholder=''
                    onChange={onChangeDateFrom}
                    format={"YYYY-MM-DD"}
                    style={{ width: '100%' }}
                  // disabledDate={disabledDate}
                  />
                </Form.Item>
              </div>
              <div className='col-4'>
                <Form.Item
                  name="dateTo"
                  label={t('text0033')}
                >
                  <DatePicker
                    placeholder=''
                    onChange={onChangeDateTo}
                    format={"YYYY-MM-DD"}
                    style={{ width: '100%' }}
                  // disabledDate={disabledDate}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='payment-search'>
              <Button htmlType='submit' type="primary">{t('text0031')}</Button>
            </div>
          </Form>
        </div>

        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
            <Table
              key='matchResult'
              locale={locale}
              columns={tableColums}
              dataSource={listGame}
              pagination={false}
              bordered
              style={{ width: '100%' }}
            />
          </div>
          <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
            <Pagination
              current={page}
              total={totalRecord}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>

        {/* <div className='col-12'>
          <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
            <Pagination
              current={page}
              total={totalRecord}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </div> */}

        {/* {listGame?.map(game => (
          <div key={game.key} className='col-12' style={{ marginBottom: 15, display: 'flex', flexDirection: 'column' }}>
            <div className='col-12' style={{ marginBottom: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className='col-2'>{t('text0095')} :</div>
              <div className='col-4'>{dataLeague?.find(l => l.id === game.leagueId)?.label}</div>
            </div>
            <div className='col-12' style={{ marginBottom: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className='col-2'>{t('text0017')} :</div>
              <div className='col-4' style={{ fontWeight: '600' }}>{game.game}</div>
            </div>
            <div className='col-12' style={{ marginBottom: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className='col-2'>{t('text0020')} :</div>
              <div className='col-4'>{game.time}</div>
            </div>
            <div className='col-12' style={{ marginBottom: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className='col-6 d-flex'>
                <div className='col-4'>Kết quả tự động : </div>
                <div className='col-4'>
                  {typeof game?.resultAuto !== 'undefined' ?
                    <>
                      {game?.resultAuto === 0 ? 'Hòa' : game?.resultAuto === game?.team1Id ?
                        game?.team1Name : game?.team2Name}
                    </> : ''}

                </div>
              </div>

            </div>
            <div className='col-12' style={{ marginBottom: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className='col-6 d-flex'>
                <div className='col-4'>Kết quả cập nhật :</div>
                <div className='col-4'>
                  {(typeof game?.resultAuto === 'undefined' || !game?.isApprove) ?
                    <Form.Item
                      name="chooseResulMatch"
                    >
                      <Select
                        allowClear
                        disabled={game?.isApprove || typeof game?.resultAuto === 'undefined'}
                        showSearch
                        placeholder=""
                        optionFilterProp="children"
                        onChange={(e) => onInputResult(e, game)}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={[
                          {
                            id: 0,
                            value: 0,
                            label: 'Hòa'
                          }
                          , {
                            id: 1,
                            value: game?.team1Id,
                            label: game?.team1Name
                          },
                          {
                            id: 2,
                            value: game?.team2Id,
                            label: game?.team2Name
                          }]}
                      />
                    </Form.Item>
                    : (
                      typeof game?.resultByAdmin !== 'undefined' ?
                        <span style={{ fontWeight: '700', color: 'red' }}>
                          {game?.resultByAdmin === 0 ? 'Hòa' : game?.resultByAdmin === game?.team1Id ?
                            game?.team1Name : game?.team2Name}
                        </span> : ''
                    )
                  }
                </div>
              </div>
            </div>

            {auth?.role === "supper" &&
              <div className='col-12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={game?.isApprove || typeof game?.resultAuto === 'undefined'}
                  onClick={!game?.isApprove ? () => onUpdateResult(game) : () => { }}
                >
                  {t('text0012')}
                </Button>
              </div>
            }
            <div className='col-12' style={{ border: '1px solid', marginTop: 15 }}></div>
          </div>
        ))} */}
      </>
    )
  }

  return (
    <div className='col-12'>
      {/* <div className='col-12' style={{ borderBottom: '1px solid #CCC', paddingBottom: '2rem', marginBottom: '2rem' }}>
        <Radio.Group value={indexTab} onChange={onChangeTab}>
          <Radio.Button value={1}>{t('text0049')}</Radio.Button>
          <Radio.Button value={2}>{t('text0094')}</Radio.Button>
        </Radio.Group>
      </div> */}

      {renderMatchResult()}

    </div>
  )
}

export default MatchResult;