import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Select, Upload, Spin, Image, Pagination } from 'antd';
import API, { MATCH, LEAGUE, TEAM } from '../../network/api';
import settingIcon from '../../image/setting.png'
import deleteIcon from '../../image/delete.png'
import ModalCommon from '../../components/Modal';
import moment from 'moment';
import 'moment-timezone';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Match = () => {

  const auth = JSON.parse(localStorage.getItem('auth'))
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [dataList, setDataList] = useState([])
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowDel, setRowDel] = useState()
  const [isShowModalDel, setIsShowModalDel] = useState(false)
  const [contentDelMess, setContentDelMess] = useState()
  const [isShowModalDelSuccess, setIsShowModalDelSuccess] = useState(false)
  const [dataFilter, setDataFilter] = useState()
  const [dataLeague, setDataLeague] = useState()
  const [leagueId, setLeagueId] = useState()
  const [dataTeam, setDataTeam] = useState()
  const [teamId, setTeamId] = useState()

  const locale = {
    emptyText: t('text0035')
  }

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getAllLeague = async () => {
      await API.get(`${LEAGUE}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.id,
              label: data?.name
            }
            dataCV.push(newI)
          })
          setDataLeague(dataCV);
        } else {
          setDataLeague([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getAllLeague();

    const getAllTeam = async () => {
      await API.get(`${TEAM}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.id,
              label: data?.name
            }
            dataCV.push(newI)
          })
          setDataTeam(dataCV);
        } else {
          setDataTeam([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getAllTeam();
  }, []);

  useEffect(() => {
    getDataList()
  }, [page, dataFilter]);

  const getDataList = async () => {
    setLoading(true)

    const keyword = (dataFilter?.leagueId || dataFilter?.teamId || dataFilter?.startDate || dataFilter?.endDate) ? 
      encodeURIComponent(JSON.stringify({ 
        leagueId: dataFilter?.leagueId, teamId: dataFilter?.teamId,
        startDate: dataFilter?.startDate, endDate: dataFilter?.endDate
      })) : null
    const URL = `${MATCH}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

    await API.get(URL).then(response => {
      const result = response.data?.data?.result;
      if (result[0].length > 0) {
        setDataList(result[0]);
        setTotalRecord(result[1])
      } else {
        setDataList();
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const onSearch = () => {
    let params = {}

    if (leagueId) params.leagueId = leagueId;
    if (teamId) params.teamId = teamId;
    if (dateFrom) {
      params.startDate = dateFrom
      if (!dateTo) params.endDate = "2123-12-31"
    }
    if (dateTo) {
        if (!dateFrom) params.startDate = "2024-07-31"
        params.endDate = dateTo
    }

    setDataFilter(params ? params : undefined)
    setPage(1)
  }

  const handleDownloadExcel = async () => {
    setLoading(true)

    const keyword = (dataFilter?.leagueId || dataFilter?.teamId || dataFilter?.startDate || dataFilter?.endDate) ? 
      encodeURIComponent(JSON.stringify({ 
        leagueId: dataFilter?.leagueId, teamId: dataFilter?.teamId,
        startDate: dataFilter?.startDate, endDate: dataFilter?.endDate
      })) : null
    const URL = `${MATCH}/search?take=100000000&skip=1&order=DESC&keyword=${keyword}`

    await API.get(URL).then(response => {
      const result = response.data?.data?.result;
      if (result[0].length > 0) {
        const data = result[0];
        const dataFm = [];
        data.map(item => {
          dataFm.push({
            leagueName: dataLeague?.find(l => l.id === item?.league?.id)?.label,
            round: item?.round,
            game: `${item?.team1?.name} - ${item?.team2?.name}`,
            timeStart: moment(item?.timeStart).utcOffset(7).format('DD-MM-YYYY HH:mm')
          })
        })
        exportToExcel(dataFm);
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const exportToExcel = (data) => {
    const currentTime = moment().format("DD-MM-YYYY HH-mm-ss");
    const name = `Lịch thi đấu_${currentTime}`;

    const formattedData = data.map(item => ({
      'Tên giải đấu': item.leagueName,
      'Vòng đấu': item.round,
      'Trận đấu': item.game,
      'Thời gian thi đấu': item.timeStart
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  const onChangeDateFrom = (date, dateString) => {
    setDateFrom(dateString)
  }

  const onChangeDateTo = (date, dateString) => {
    setDateTo(dateString)
  }

  const tableColums = [
    {
      title: 'STT',
      align: 'center',
      key: 'orderNo',
      render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>,
      width: 100
    },
    {
      title: 'ID',
      align: 'center',
      key: 'id',
      dataIndex: 'id',
      width: 100
    },
    {
      title: t('text0065'),
      key: 'leagueName',
      render: (record) =>
        <span>{dataLeague?.find(l => l.id === record?.league?.id)?.label}</span>
    },
    {
      title: t('text0115'),
      key: 'round',
      render: (record) =>
        <span>{record?.round}</span>
    },
    {
      title: t('text0017'),
      key: 'teamName',
      render: (record) =>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', fontWeight: '600', width: '100% ' }}>
          <span style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
            {record?.team1?.name}
            <img src={record?.team1.logo} style={{ margin: '0 5px', width: '3rem', height: '1.5rem' }} />
          </span>
          <span>-</span>
          <span style={{ width: '50%', display: 'flex', justifyContent: 'start' }}>
            <img src={record?.team2.logo} style={{ margin: '0 5px', width: '3rem', height: '1.5rem' }} />
            {record?.team2?.name}
          </span>
        </div>
    },
    {
      title: t('text0020'),
      key: 'timeStart',
      render: (record) =>
        moment(record?.timeStart).utcOffset(7).format('DD-MM-YYYY HH:mm')
    },
    {
      title: t('text0012'),
      key: 'setting',
      align: 'center',
      width: 100,
      render: (record) =>
        <div className='w-full flex justify-center'>
          <img style={{ height: 20, cursor: 'pointer' }} src={settingIcon} alt='setting'
            onClick={() => {
              navigate(`/updateMatch/${record.id}`)
            }}
          />
        </div>,
      hidden: auth?.role === "supper" ? false : true
    },
    {
      title: t('text0011'),
      key: 'delete',
      align: 'center',
      width: 100,
      render: (record) =>
        <div className='w-full flex justify-center'>
          <img style={{ height: 20, cursor: 'pointer' }} src={deleteIcon} alt='del'
            onClick={() => {
              setRowDel(record)
              setIsShowModalDel(true)
            }}
          />
        </div>,
      hidden: auth?.role === "supper" ? false : true
    }
  ]

  const handleRemove = async () => {
    setFileList([]);
  }

  const handleChange = async ({ fileList: newFileList }) => {

    if (!newFileList[0].url && !newFileList[0].preview) {
      newFileList[0].preview = await getBase64(newFileList[0].originFileObj);
    }
    setFileList(newFileList);
  }

  const uploadData = async () => {
    const formData = new FormData()
    formData.append('excel', fileList[0].originFileObj)
    setLoading(true)

    await API.post(`${MATCH}/upload`, formData).then(response => {
      const result = response.data?.data?.result;
      if (result?.success) {
        setFileList([])
        getDataList()
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const onDelPoint = async () => {
    setLoading(true)
    await API.delete(`${MATCH}/${rowDel?.id}`).then(response => {
      const data = response.data?.data;
      if (data?.code === 20005) {
        setContentDelMess(t('text0072'))
        setIsShowModalDelSuccess(true)
        setIsShowModalDel(false)
      } else {
        setContentDelMess("Giải đấu đã tồn tại dữ liệu dự đoán. Không thể xóa.")
        setIsShowModalDelSuccess(true)
        setIsShowModalDel(false)
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const onChangePage = (page) => {
    setPage(page)
  }

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const createMatchAuto = () => { navigate('/createMatchAuto') }

  return (
    <div className='col-12'>
      <div className='col-12'>
        <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
          <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
            <div className='col-4'>
              <Form.Item
                name="leagueNameFilter"
                label={t('text0065')}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  onChange={(e) => setLeagueId(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={dataLeague}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="teamFilter"
                label={t('text0074')}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  onChange={(e) => setTeamId(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={dataTeam}
                />
              </Form.Item>
            </div>
          </div>
          <div className='col-12' style={{ marginBottom: 8, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="dateFrom"
                label={t('text0032')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateFrom}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                // disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="dateTo"
                label={t('text0033')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateTo}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                // disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
          </div>
          <div className='payment-search'>
            <Button htmlType='submit' type="primary">{t('text0031')}</Button>
            <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button>
          </div>
        </Form>
      </div>
      {auth?.role === "supper" &&
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
          <Button type="primary" onClick={createMatchAuto} style={{ marginRight: '1rem' }}>
            {t('text0124')}
          </Button>
        </div>
      }
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
          <Table
            key='dataLeague'
            locale={locale}
            columns={tableColums}
            dataSource={dataList}
            pagination={false}
            bordered
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </Spin>
      {isShowModalDel &&
        <ModalCommon
          isShow={isShowModalDel}
          message={t('text0071')}
          txtBtnAcc={t('text0008')}
          textBtnClose={t('text0023')}
          actionNext={onDelPoint}
          close={() => setIsShowModalDel(false)}
        />
      }
      {isShowModalDelSuccess &&
        <ModalCommon
          isShow={isShowModalDelSuccess}
          message={contentDelMess}
          txtBtnAcc={t('text0008')}
          actionNext={() => {
            setIsShowModalDelSuccess(false)
            getDataList()
          }}
          close={() => {
            setIsShowModalDelSuccess(false)
            getDataList()
          }}
        />
      }
    </div>
  )
}

export default Match;