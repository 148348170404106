import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationVN from "./locales/vn/translation.json";
import translationCN from "./locales/cn/translation.json";
import translationEN from "./locales/en/translation.json";

const resources = {
    vn: {
        translation: translationVN,
    },
    cn: {
        translation: translationCN,
    },
    en: {
        translation: translationEN,
    },

};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "vn",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n