import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "./style.css";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Select } from 'antd';
import API, { LEAGUE } from '../../network/api';

const { Option } = Select;

const CreateLeague = () => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [errorMess, setErrorMess] = useState()
  const [isUpdateData, setIsUpdateData] = useState(false)

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onCreate = async () => {
    setIsUpdateData(true)
    const params = {
      name: form.getFieldValue('name'),
      logo: form.getFieldValue('image')
    }
    
    if (!isUpdateData) {
      await API.post(`${LEAGUE}/create`, params).then((response) => {
        const result = response.data.data.result;
        if (result) {
          if (result?.id) {
            navigate('/league')
          } else {
            const result = response.data.data.result
            if (result?.code === "ER_DUP_ENTRY") {
              setErrorMess('Tên giải đấu đã tồn tại')
            }
          }
        }
        setIsUpdateData(false)
      }).catch(errors => { setIsUpdateData(false) })
      
    }
    
  }

  const backScreen = () => { navigate('/league') }

  const renderForm = () => {
    return (
      <Form className='form-create' form={form} layout="inline" autoComplete='off' onFinish={onCreate}>
        <div className='col-8'>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0065')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} onChange={() => setErrorMess()} />
              </Form.Item>
            </div>
          </div>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
            <div className='col-3'></div>
            <div className='col-9'>{errorMess}</div>
          </div>
          <div style={{ marginBottom: 20 }} />

          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0066')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="image"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 30 }} />

          <Form.Item shouldUpdate style={{ width: '100%' }}>
            {() => (
              <div className='lg-btn'>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                  loading={isUpdateData}
                >
                  {t('text0026')}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    )
  }

  return (
    <div className='col-12'>
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {t('text0034')}
      </Button>
      {
        renderForm()
      }
    </div>
  )

}

export default CreateLeague;