import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "./style.css";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Select } from 'antd';
import API, { LEAGUE, TEAM, GROUP } from '../../network/api';

const UpdateTeam = () => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [dataDetail, setDataDetal] = useState()
  const [errorMess, setErrorMess] = useState()
  const { id } = useParams();
  const [dataLeague, setDataLeague] = useState()
  const [dataGroup, setDataGroup] = useState()
  const [groupId, setGroupId] = useState()
  const [leagueId, setLeagueId] = useState()
  const [leagueNameDf, setLeagueNameDf] = useState()

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
  }, [leagueNameDf]);

  useEffect(() => {
    const getAllLeague = async (dataDetail) => {
      await API.get(`${LEAGUE}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          const leaguesName = []
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.name,
              label: data?.name
            }
            dataCV.push(newI)
            const leaguesId = dataDetail?.league.id
            result.map(item => {
              leaguesName.push(item?.name)
            })
          })

          setDataLeague(dataCV);
          form.setFieldValue("leagues", dataDetail?.league.name)
          setLeagueNameDf(leaguesName)
        } else {
          setDataLeague([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    const getDetail = async () => {
      await API.get(`${TEAM}/${id}`).then((response) => {
        const result = response.data.data.result;

        if (result[0][0]) {
          setDataDetal(result[0][0])
          form.setFieldValue("groups", result[0][0]?.group?.name)
          form.setFieldValue("name", result[0][0]?.name)
          form.setFieldValue("image", result[0][0]?.logo)
          setLeagueId(result[0][0]?.league.id)
          getAllLeague(result[0][0])
        }
      })
    }

    getDetail();
  }, []);

  function filterUniqueLabels(arr) {
    const seenLabels = new Set();
    return arr.filter(item => {
      if (seenLabels.has(item.label)) {
        return false;
      } else {
        seenLabels.add(item.label);
        return true;
      }
    });
  }

  useEffect(() => {
    const getAllGroup = async () => {
      await API.get(`${GROUP}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.id,
              label: data?.name
            }
            dataCV.push(newI)
          })
          console.log("dataCV_Group", filterUniqueLabels(dataCV))
          setDataGroup(filterUniqueLabels(dataCV));
        } else {
          setDataGroup([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getAllGroup();
  }, []);

  const handleChangeGroup = (value) => {
    if (value) {
      setGroupId(value)
    }
  }

  const onUpdate = async () => {
    const params = {
      leagueId: leagueId,
      groupId: groupId,
      name: form.getFieldValue('name'),
      logo: form.getFieldValue('image')
    }

    await API.patch(`${TEAM}/${id}`, params).then((response) => {
      const result = response.data.data;
      if (result.code === 20004) {
        navigate('/team')
      } else {
        const result = response.data.data.result
        if (result?.code === "ER_DUP_ENTRY") {
          setErrorMess('Tên đội bóng đã tồn tại')
        }
      }
    })
  }

  const backScreen = () => { navigate('/team') }

  const handleChangeLeague = (value) => {
    if (value.length > 0) {
      const leagueIdStr = []
      value.map(v => {
        const findLeague = dataLeague.find(l => l.value === v)
        leagueIdStr.push(Number(findLeague?.id))
      })
      setLeagueId(leagueIdStr.join(", "))
    }
  }

  const renderForm = () => {
    return (
      <Form className='form-create' form={form} layout="inline" autoComplete='off' onFinish={onUpdate}>
        <div className='col-8'>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0065')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="leagues"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                {leagueNameDf && <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder=""
                  onChange={handleChangeLeague}
                  defaultValue={leagueNameDf}
                  options={dataLeague}
                />}
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} />

          {/* <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0078')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="groups"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder=""
                  onChange={handleChangeGroup}
                  options={dataGroup}
                />
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} /> */}

          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0074')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} onChange={() => setErrorMess()} />
              </Form.Item>
            </div>
          </div>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
            <div className='col-3'></div>
            <div className='col-9'>{errorMess}</div>
          </div>
          <div style={{ marginBottom: 20 }} />

          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0066')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="image"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 30 }} />

          <Form.Item shouldUpdate style={{ width: '100%' }}>
            {() => (
              <div className='lg-btn'>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    // !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  {t('text0026')}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    )
  }

  return (
    <div className='col-12'>
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {t('text0034')}
      </Button>
      {
        renderForm()
      }
    </div>
  )

}

export default UpdateTeam;