import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Select, Pagination, Spin } from 'antd';
import API, { EVENT2, TEAM, MATCH_RESULT, LEAGUE } from '../../network/api';
import moment from 'moment';
import 'moment-timezone';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { Option } = Select;

const PredictHistory2 = () => {

  const auth = JSON.parse(localStorage.getItem('auth'))
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [dataFilter, setDataFilter] = useState()
  const [dataList, setDataList] = useState([])
  const [teamId, setTeamId] = useState()
  const [dataTeam, setDataTeam] = useState([])
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataLeague, setDataLeague] = useState()
  const [leagueId, setLeagueId] = useState()
  const [dataReport, setDataReport] = useState();

  const disabledDate = (current) => {
    return current && current < dayjs().endOf('day');
  };

  const locale = {
    emptyText: t('text0035')
  }

  useEffect(() => {
    const getDataReport = async() => {
      await API.get(`${EVENT2}/report`).then(response => {
        const data = response.data?.data?.result
        if (data) setDataReport(data)
      }).catch(error => {
        console.log(error)
      })
    }
    
    getDataReport();
  }, []);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getAllTeam = async () => {
      await API.get(`${TEAM}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.id,
              label: data?.name
            }
            dataCV.push(newI)
          })
          setDataTeam(dataCV);
        } else {
          setDataTeam([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getAllTeam()
  }, []);

  useEffect(() => {
    const getAllLeague = async () => {
      await API.get(`${LEAGUE}/all`).then(response => {
        const result = response.data?.data?.result;

        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.id,
              label: data?.name
            }
            dataCV.push(newI)
          })
          setDataLeague(dataCV);
        } else {
          setDataLeague([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getAllLeague();
  }, []);

  useEffect(() => {
    getDataHis()
  }, [page, dataFilter]);

  const getDataHis = async () => {
    setLoading(true)

    const keyword = (dataFilter?.leagueId || dataFilter?.username || dataFilter?.teamId || dataFilter?.startDate || dataFilter?.endDate) ?
      encodeURIComponent(JSON.stringify({ leagueId: dataFilter?.leagueId, username: dataFilter?.username, teamId: dataFilter?.teamId, startDate: dataFilter?.startDate, endDate: dataFilter?.endDate })) : null
    let URL = `${EVENT2}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

    const listData1 = API.get(URL)
    const listData2 = API.get(`${MATCH_RESULT}/all`)

    await Promise.all([listData1, listData2])
      .then(response => {
        const result1 = response[0].data?.data?.result[0]
        const result2 = response[1].data?.data?.result

        if (result1?.length > 0 && result2) {
          let dataCV = []
          result1?.map((item) => {
            let itemNew = {
              key: item?.id,
              id: item?.id,
              username: item?.user ? item?.user.username : '',
              league: item?.match?.league?.name,
              time: item?.match?.timeStart,
              game: item?.match?.team1?.name + " - " + item?.match?.team2?.name,
              prediction: item?.userPrediction === 0 ? 'Hòa' : (item?.userPrediction === item?.match?.team1.id ?
                item?.match?.team1?.name : item?.match?.team2?.name),
              resultGame: 
                !result2?.find(mt => mt.matchId === item?.match?.id) || result2?.find(mt => mt.matchId === item?.match?.id)?.resultByAdmin === null  ? '--'
                : result2?.find(mt => mt.matchId === item?.match?.id)?.resultByAdmin === 0 ? 'Hòa'
                  : ((result2?.find(mt => mt.matchId === item?.match?.id)?.resultByAdmin === item?.match?.team1.id) ? item?.match?.team1?.name
                    : item?.match?.team2?.name),
              result: item?.userWin === 0 ? '--' : item?.userWin === -1 ? 'Sai' : 'Đúng',
              createdAt: item?.createdAt
            }
            dataCV.push(itemNew)
          })
          setDataList(dataCV)
          setTotalRecord(response[0].data?.data?.result[1])
        } else {
          setDataList([])
        }
        setLoading(false)
      }).catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  const onSearch = () => {
    let params = {}
    if (leagueId) params.leagueId = leagueId;
    if (form.getFieldValue("usernameFilter")) params.username = form.getFieldValue("usernameFilter").trim();
    if (teamId) params.teamId = teamId;
    if (dateFrom) {
      params.startDate = dateFrom
      if (!dateTo) params.endDate = "2123-12-31"
    }
    if (dateTo) {
      if (!dateFrom) params.startDate = "2024-07-31"
      params.endDate = dateTo
    }

    setDataFilter(params ? params : undefined)
    setPage(1)
  }

  const tableColums = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      width: 100,
      render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>
    },
    {
      title: t('text0002'),
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: t('text0065'),
      dataIndex: 'league',
      key: 'league',
    },
    {
      title: t('text0020'),
      key: 'time',
      render: (record) =>
        moment(record?.time).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
    },
    {
      title: t('text0017'),
      dataIndex: 'game',
      key: 'game'
    },
    {
      title: t('text0123'),
      key: 'createdAt',
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
    },
    {
      title: t('text0018'),
      dataIndex: 'prediction',
      key: 'prediction'
    },
    {
      title: t('text0019'),
      dataIndex: 'resultGame',
      key: 'resultGame'
    },
    {
      title: 'Kết quả',
      dataIndex: 'result',
      key: 'result',
    },
    // {
    //   title: 'Bàn thắng',
    //   dataIndex: 'goal',
    //   key: 'goal',
    // },
  ]

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDateFrom = (date, dateString) => {
    setDateFrom(dateString)
  }

  const onChangeDateTo = (date, dateString) => {
    setDateTo(dateString)
  }

  // const onChangeDate = (e, type) => {
  //   if (e) {
  //     if (type === 'from')
  //       setDateFrom(moment(e).startOf('day'))
  //     else
  //       setDateTo(moment(e).endOf('day'))
  //   } else {
  //     if (type === 'from')
  //       setDateFrom(null)
  //     else
  //       setDateTo(null)
  //   }
  // }

  const handleDownloadExcel = async () => {
    setLoading(true)

    const keyword = (dataFilter?.leagueId || dataFilter?.username || dataFilter?.teamId || dataFilter?.startDate || dataFilter?.endDate) ?
      encodeURIComponent(JSON.stringify({ leagueId: dataFilter?.leagueId, username: dataFilter?.username, teamId: dataFilter?.teamId, startDate: dataFilter?.startDate, endDate: dataFilter?.endDate })) : null
    let URL = `${EVENT2}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

    const listData1 = API.get(URL)
    const listData2 = API.get(`${MATCH_RESULT}/all`)

    await Promise.all([listData1, listData2])
      .then(response => {
        const result1 = response[0].data?.data?.result[0]
        const result2 = response[1].data?.data?.result

        if (result1?.length > 0 && result2) {
          let dataCV = []
          result1?.map((item) => {
            let itemNew = {
              username: item?.user ? item?.user.username : '',
              league: item?.match?.league?.name,
              time: moment(item?.match?.timeStart).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
              game: item?.match?.team1?.name + " - " + item?.match?.team2?.name,
              prediction: item?.userPrediction === 0 ? 'Hòa' : (item?.userPrediction === item?.match?.team1.id ?
                item?.match?.team1?.name : item?.match?.team2?.name)
              ,
              resultGame: 
                !result2?.find(mt => mt.matchId === item?.match?.id) || result2?.find(mt => mt.matchId === item?.match?.id)?.resultByAdmin === null  ? '--'
                : result2?.find(mt => mt.matchId === item?.match?.id)?.resultByAdmin === 0 ? 'Hòa'
                  : ((result2?.find(mt => mt.matchId === item?.match?.id)?.resultByAdmin === item?.match?.team1.id) ? item?.match?.team1?.name
                    : item?.match?.team2?.name),
              result: item?.userWin === 0 ? '--' : item?.userWin === -1 ? 'Sai' : 'Đúng',
              createdAt: moment(item?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
            }
            // itemNew.goal = itemNew.resultGame === '' ? '' : itemNew.prediction === itemNew.resultGame ? '+1' : '0'
            dataCV.push(itemNew)
          })
          exportToExcel(dataCV)
        }
        setLoading(false)
      }).catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  const exportToExcel = (data) => {
    const currentTime = moment().format("DD-MM-YYYY HH-mm-ss");
    const name = `Lịch sử dự đoán trận đấu_${currentTime}`;

    const formattedData = data.map(item => ({
      'Tên đăng nhập': item.username,
      'Tên giải đấu': item.league,
      'Thời gian thi đấu': item.time,
      'Trận đấu': item.game,
      'Thời gian dự đoán': item.createdAt,
      'Dự đoán': item.prediction,
      'Kết quả trận đấu': item.resultGame,
      'Kết quả': item.result,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  const onChangePage = (page) => {
    setPage(page)
  }

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  return (
    <div className='col-12'>
      <div className='col-12'>
        <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
          <div className='col-12' style={{ marginBottom: 15, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="usernameFilter"
                label={t('text0002')}
              >
                <Input
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="dateFrom"
                label={t('text0032')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateFrom}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                // disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="dateTo"
                label={t('text0033')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateTo}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                // disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
          </div>
          <div className='col-12' style={{ marginBottom: 15, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="leagueNameFilter"
                label={t('text0065')}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  onChange={(e) => setLeagueId(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={dataLeague}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="teamFilter"
                label={t('text0039')}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  onChange={(e) => setTeamId(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={dataTeam}
                />
              </Form.Item>
            </div>
          </div>
          <div className='payment-search'>
            <Button htmlType='submit' type="primary">{t('text0031')}</Button>
            {auth?.role === "supper" &&
              <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button>
            }
          </div>
        </Form>
      </div>
      <div className='col-12' style={{ margin: '15px 0px', fontWeight: 600 }}>
        <div className='col-12 flex'>
          <div className='col-4'>
            <p>{`Tổng số HV tham gia SK2: ${dataReport?.count}`}</p>
          </div>
          <div className='col-4'>
            <p>{`Tổng số lượt đã dự đoán: ${totalRecord}`}</p>
          </div>
          <div className='col-4'>
            <p>{`Tổng số lượt đã dự đoán đúng: ${dataReport?.countUserWin}`}</p>
          </div>
        </div>
      </div>
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
          <Table
            key='dataHis'
            locale={locale}
            columns={tableColums}
            dataSource={dataList}
            pagination={false}
            bordered
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </Spin>
    </div>
  )
}

export default PredictHistory2;