import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "./style.css";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Select, DatePicker } from 'antd';
import API, { LEAGUE, TEAM, MATCH } from '../../network/api';
import moment from 'moment';

const UpdateMatch = () => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [dataDetail, setDataDetal] = useState()
  const [errorMess, setErrorMess] = useState()
  const { id } = useParams();
  const [dataLeague, setDataLeague] = useState()
  const [leagueId, setLeagueId] = useState()
  const [leagueNameDf, setLeagueNameDf] = useState()
  const [team1Id, setTeam1Id] = useState()
  const [team2Id, setTeam2Id] = useState()
  const [dataTeam, setDataTeam] = useState()
  const [team1NameDf, setTeam1NameDf] = useState()
  const [team2NameDf, setTeam2NameDf] = useState()
  const [timeStart, setTimeStart] = useState()

  // const roundList = [
  //   {
  //     id: 0,
  //     value: "Vòng bảng",
  //     label: "Vòng bảng",
  //   },
  //   {
  //     id: 1,
  //     value: "Vòng 1/8",
  //     label: "Vòng 1/8",
  //   },
  //   {
  //     id: 2,
  //     value: "Vòng tứ kết",
  //     label: "Vòng tứ kết",
  //   },
  //   {
  //     id: 3,
  //     value: "Vòng bán kết",
  //     label: "Vòng bán kết",
  //   },
  //   {
  //     id: 4,
  //     value: "Chung kết",
  //     label: "Chung kết",
  //   },
  // ]

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
  }, [leagueNameDf]);

  useEffect(() => {
    const getAllLeague = async (dataDetail) => {
      await API.get(`${LEAGUE}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.name,
              label: data?.name
            }
            dataCV.push(newI)
          })

          setDataLeague(dataCV);
          form.setFieldValue("league", dataCV.find(l => l.id === dataDetail?.league?.id).value)
          setLeagueNameDf(dataCV.find(l => l.id === dataDetail?.league?.id).value)
        } else {
          setDataLeague([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    const getAllTeam = async (dataDetail) => {
      await API.get(`${TEAM}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.name,
              label: data?.name
            }
            dataCV.push(newI)
          })
          setDataTeam(dataCV);

          form.setFieldValue("round", dataDetail?.round)
          form.setFieldValue("team1", dataCV.find(t => t.id === dataDetail?.team1Id).value)
          form.setFieldValue("team2", dataCV.find(t => t.id === dataDetail?.team2Id).value)
          setTeam1NameDf(dataCV.find(l => l.id === dataDetail?.team1Id).value)
          setTeam2NameDf(dataCV.find(l => l.id === dataDetail?.team1Id).value)
        } else {
          setDataTeam([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    const getDetail = async () => {
      await API.get(`${MATCH}/${id}`).then((response) => {
        const result = response.data.data.result;
        if (result) {
          setDataDetal(result)
          setLeagueId(result?.leagueId)
          setTeam1Id(result?.team1Id)
          setTeam2Id(result?.team2Id)
          form.setFieldValue("timeStart",
            moment(moment(result?.timeStart).utcOffset(7), "DD-MM-YYYY HH:mm")
          )
          getAllLeague(result)
          getAllTeam(result)
        }
      })
    }

    getDetail();
  }, []);

  const onUpdate = async () => {

    const params = {
      leagueId: leagueId,
      team1Id: team1Id,
      team2Id: team2Id,
      timeStart: timeStart ? timeStart : dataDetail?.timeStart,
      round: form.getFieldValue('round') ? form.getFieldValue('round') : dataDetail?.round,
    }

    await API.patch(`${MATCH}/${id}`, params).then((response) => {
      const result = response.data.data;

      if (result) {

        if (result?.code === 20004) {
          alert('Cập nhập trận đấu thành công!')
          navigate('/match')
        } else {
          console.log(result)
        }
      }
    })
  }

  const backScreen = () => { navigate('/match') }

  // const handleChangeRound = (value) => {
  //   if (value) {
  //     setRound(value.toString())
  //   }
  // }

  const handleChangeLeague = (value) => {
    if (value) {
      const findLeague = dataLeague.find(l => l.value === value)
      setLeagueId(findLeague?.id)
    }
  }

  const handleTeam1 = (value) => {
    if (value) {
      const findTeam = dataTeam.find(l => l.value === value)
      setTeam1Id(findTeam?.id)
    }
  }

  const handleTeam2 = (value) => {
    if (value) {
      const findTeam = dataTeam.find(l => l.value === value)
      setTeam2Id(findTeam?.id)
    }
  }

  const onChangeDateTime = (value) => {
    if (value) {
      const dateFormat = moment(value).format('YYYY-MM-DD')
      const timeFormat = moment(value).format('HH:mm')
      setTimeStart(`${dateFormat}T${timeFormat}:00Z`)
    }
  }

  const renderForm = () => {
    return (
      <Form className='form-create' form={form} layout="inline" autoComplete='off' onFinish={onUpdate}>
        <div className='col-8'>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0065')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-6'>
              <Form.Item
                name="league"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                {leagueNameDf && <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder=""
                  onChange={handleChangeLeague}
                  defaultValue={leagueNameDf}
                  options={dataLeague}
                />}
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} />

          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0115')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-6'>
              <Form.Item
                name="round"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                {/* <Input
                  onChange={(e) => setRound(e.target.value)}
                /> */}
                {/* {roundList && <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder=""
                  onChange={handleChangeRound}
                  defaultValue={form.getFieldValue('round')}
                  options={roundList}
                />} */}
                <Input />
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} />

          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0074') + " 1"} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-6'>
              <Form.Item
                name="team1"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                {team1NameDf && <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder=""
                  onChange={handleTeam1}
                  defaultValue={team1NameDf}
                  options={dataTeam}
                />}
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} />

          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0074') + " 2"} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-6'>
              <Form.Item
                name="team2"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                {team2NameDf && <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder=""
                  onChange={handleTeam2}
                  defaultValue={team2NameDf}
                  options={dataTeam}
                />}
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} />

          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0020')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="timeStart"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0, fontWeight: 'bold' }}
              >
                {dataDetail &&
                  // <DatePicker
                  //   showTime={{ format: 'HH:mm' }}
                  //   format="DD-MM-YYYY HH:mm"
                  //   onChange={onChangeDateTime}
                  //   value={moment(moment(dataDetail?.timeStart).utcOffset(7), "DD-MM-YYYY HH:mm")}
                  // />
                  moment(dataDetail?.timeStart).format('DD-MM-YYYY HH:mm:ss')
                }
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 30 }} />

          <Form.Item shouldUpdate style={{ width: '100%' }}>
            {() => (
              <div className='lg-btn'>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    // !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  {t('text0026')}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    )
  }

  return (
    <div className='col-12'>
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {t('text0034')}
      </Button>
      {
        renderForm()
      }
    </div>
  )

}

export default UpdateMatch;