import React, { useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.css";
import SideBarCustom from '../../components/Sidebar';
import HeaderCustom from '../../components/Header';
import Login from '../login';
import AccountList from '../account/list';
import CreateAccount from '../account/create';
import League from '../league/list';
import CreateLeague from '../league/create';
import UpdateLeague from '../league/update';
import Team from '../team/list';
import CreateTeam from '../team/create';
import UpdateTeam from '../team/update';
// import Group from '../group/list';
// import CreateGroup from '../group/create';
// import UpdateGroup from '../group/update';
// import CreateGroupAuto from '../group/createAuto';
import Player from '../player/list';
import CreatePlayer from '../player/create';
import UpdatePlayer from '../player/update';
import TeamRank from '../teamRank/list';
import CreateTeamRankAuto from '../teamRank/createAuto';
import Match from '../match/list';
import UpdateMatch from '../match/update';
// import TopList from '../top/list';
// import UpdateTop from '../top/update';
import PredictHistory1 from '../predictHis_1/list';
import PredictHistory2 from '../predictHis_2/list';
import PredictHistory3 from '../predictHis_3/list';
import GoalsHistory from '../goalsHis/list';
import ScoreHistory from '../scoreHis/list';
import MatchResult from '../matchResult';
import TurnDetail from '../turnDetail/list';
import UpdateGoals from '../updateGoal';
import SettingSys from '../settingSys';
import { Layout } from "antd";
import Logs from '../logs/list';
// import CheckDTC from '../checkDTC/list';
import CreateTeamAuto from '../team/createAuto';

import CreateMatchAuto from '../match/createAuto';
import Event1Result from '../event1Result';
import Ev1question from '../settingSys/ev1_question';
import Ev1answer from '../settingSys/ev1_answer';


const { Sider, Content } = Layout;

const Main = () => {

  const auth = localStorage.getItem('auth')
  const [collapsed, setCollapsed] = useState(false);

  const renderPublic = () => {
    return (
      <Routes>
        <Route path='/' element={<Navigate to='/login' />} />
        <Route path='/login' element={<Login />} />
      </Routes>

    )
  }

  const renderContent = () => {
    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="site-body"
        >
          <SideBarCustom />
        </Sider>
        <Layout className="site-layout">
          <HeaderCustom
            collapsed={collapsed}
            updateCollapsed={() => setCollapsed(!collapsed)}
            username={
              JSON.parse(auth).username
            }
          />
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24
            }}
          >
            <Routes>
              <Route path='/' element={<Navigate to='/account' />} />
              <Route path='/account' element={<AccountList />} />
              <Route path='/createAccount' element={<CreateAccount />} />
              <Route path='/league' element={<League />} />
              <Route path='/createLeague' element={<CreateLeague />} />
              <Route path='/updateLeague/:id' element={<UpdateLeague />} />
              <Route path='/team' element={<Team />} />
              <Route path='/createTeam' element={<CreateTeam />} />
              <Route path='/createTeamAuto' element={<CreateTeamAuto />} />
              <Route path='/updateTeam/:id' element={<UpdateTeam />} />
              {/* <Route path='/group' element={<Group />} />
              <Route path='/createGroup' element={<CreateGroup />} />
              <Route path='/createGroupAuto' element={<CreateGroupAuto />} />
              <Route path='/updateGroup/:id' element={<UpdateGroup />} /> */}

              <Route path='/player' element={<Player />} />
              <Route path='/createPlayer' element={<CreatePlayer />} />
              <Route path='/updatePlayer/:id' element={<UpdatePlayer />} />
              <Route path='/teamRank' element={<TeamRank />} />
              <Route path='/createTeamRankAuto' element={<CreateTeamRankAuto />} />

              <Route path='/match' element={<Match />} />
              <Route path='/createMatchAuto' element={<CreateMatchAuto />} />
              <Route path='/updateMatch/:id' element={<UpdateMatch />} />
              {/* <Route path='/top' element={<TopList />} /> */}
              {/* <Route path='/updateTop' element={<UpdateTop />} /> */}

              <Route path='/predHis-1' element={<PredictHistory1 />} />
              <Route path='/predHis-2' element={<PredictHistory2 />} />
              <Route path='/predHis-3' element={<PredictHistory3 />} />
              <Route path='/goalsHis' element={<GoalsHistory />} />
              <Route path='/scoreHis' element={<ScoreHistory />} />

              <Route path='/ev1Result' element={<Event1Result />} />
              <Route path='/matchResult' element={<MatchResult />} />

              <Route path='/turnDetail' element={<TurnDetail />} />
              <Route path='/updateGoals' element={<UpdateGoals />} />
              {/* <Route path='/checkDTC' element={<CheckDTC />} /> */}
              {/* <Route path='/log' element={<Logs />} /> */}
              <Route path='/settingSys' element={<SettingSys />} />
              <Route path='/ev1_question' element={<Ev1question />} />
              <Route path='/ev1_answer' element={<Ev1answer />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    )
  }

  return (
    <>
      {auth ? renderContent() : renderPublic()}
    </>
  )
}

export default Main;