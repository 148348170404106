import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.css";
import { useTranslation } from "react-i18next";
import { Form, Input, Button } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import API, { LOGIN, ROLE } from '../../network/api';

const Login = () => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [errServer, setErrServer] = useState()

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onLogin = async () => {
    setErrServer(null)
    const params = {
      username: form.getFieldValue('username').trim(),
      password: form.getFieldValue('password').trim(),
      isAdmin: true,
    }

    await API.post(LOGIN, params).then((response) => {

      if (response.data) {

        const data = response.data.data
        const dataAuth = {
          tokenType: 'Bearer',
          accessToken: data?.access_token,
          username: data?.user?.username,
          role: data?.user?.role,
          permision: data?.user?.permision
        }
        localStorage.setItem('auth', JSON.stringify(dataAuth))
        // getListRoles(dataAuth)
        navigate('/account')
        window.location.reload()
      } else {
        setErrServer(t('text0006'))
      }
    }).catch((error) => {
      console.log(error)
      setErrServer(t('text0006'))
    })
  }

  const getListRoles = async (dataAuth) => {
    API.defaults.headers.common['Authorization'] = dataAuth.tokenType + ' ' + dataAuth.accessToken
    await API.get(ROLE).then((response) => {
      const data = response.data.data
      if (data) {
        localStorage.setItem('roles', JSON.stringify(data))
        const role = dataAuth?.role
        navigate(role === 'supper' ? '/account' : '/league')
        window.location.reload()
      }
    })
  }

  const clearErr = () => { setErrServer(null) }

  return (
    <div className='col-12 login-bg'>
      <div className='col-4 login-box'>
        <div className='lg-title'>{t('text0001')}</div>
        <div className='lg-content'>
          <Form style={{ width: '100%' }} form={form} layout="inline" autoComplete='off' onFinish={onLogin}>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: t('text0004'),
                },
              ]}
              className='col-12'
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t('text0002')} onInput={clearErr}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t('text0004'),
                },
                {
                  validator: (_, value) => {
                    if (/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*]).{6,32})/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('Some message here');
                    }
                  },
                  message: t('text0005', { 0: t('text0003') }),
                }
              ]}
              className='col-12'
              style={{ paddingTop: 15 }}
            >
              <Input.Password
                min={6}
                maxLength={32}
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={t('text0003')}
                onInput={clearErr}
              />
            </Form.Item>
            {errServer && <span style={{ color: '#ff4d4f' }}>{t('text0006')}</span>}
            <Form.Item shouldUpdate style={{ width: '100%' }}>
              {() => (
                <div className='lg-btn'>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                  >
                    {t('text0001')}
                  </Button>
                </div>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login;