import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "./style.css";
import { useTranslation } from "react-i18next";
import { Form, InputNumber, Button, Select, Spin } from 'antd';
import API, { LEAGUE, TEAM_RANK } from '../../network/api';

const CreateTeamRankAuto = () => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [errorMess, setErrorMess] = useState()
  const [dataLeague, setDataLeague] = useState()
  const [leagueId, setLeagueId] = useState()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getAllLeague = async () => {
      await API.get(`${LEAGUE}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.id,
              label: data?.name
            }
            dataCV.push(newI)
          })
          setDataLeague(dataCV);
        } else {
          setDataLeague([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getAllLeague();
  }, []);

  const onCreateAuto = async () => {
    setLoading(true)
    const params = {
      leagueId: leagueId,
      leagueName: dataLeague.find(x => x.id === leagueId)?.label,
      totalTeam: form.getFieldValue("totalTeam")
    }

    await API.post(`${TEAM_RANK}/createAuto`, params).then((response) => {
      const result = response.data.data;
      if (result?.code === 20001) {
        setLoading(false)
        navigate('/teamRank');
      }
    })
  }

  const onUpdateAuto = async () => {
    setLoading(true)
    const params = {
      leagueId: leagueId,
      leagueName: dataLeague.find(x => x.id === leagueId)?.label,
      totalTeam: form.getFieldValue("totalTeam")
    }

    await API.post(`${TEAM_RANK}/updateAuto`, params).then((response) => {
      const result = response.data.data;
      if (result?.code === 20004) {
        setLoading(false)
        navigate('/teamRank');
      }
    })
  }

  const backScreen = () => { navigate('/teamRank') }

  const renderForm = () => {
    return (
      <Form className='form-create' form={form} layout="inline" autoComplete='off'>
        <div className='col-8'>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0065')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="leagues"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  onChange={(e) => setLeagueId(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={dataLeague}
                />
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 10 }} />
          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0125')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="totalTeam"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} />

          <Form.Item shouldUpdate style={{ width: '100%' }}>
            <div className='lg-btn'>
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  // !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }
                onClick={onCreateAuto}
              >
                {'Lấy dữ liệu'}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  // !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }
                style={{ marginLeft: 20 }}
                onClick={onUpdateAuto}
              >
                {'Cập nhật dữ liệu'}
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    )
  }

  return (
    <div className='col-12'>
      <Spin spinning={loading} delay={500} size="large">
        <Button
          type="default"
          htmlType="button"
          style={{ marginBottom: 30 }}
          onClick={backScreen}
        >
          {t('text0034')}
        </Button>
        {
          renderForm()
        }
      </Spin>
    </div>
  )

}

export default CreateTeamRankAuto;