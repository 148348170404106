import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.css";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Select } from 'antd';
import API, { REGISTER, USER } from '../../network/api';

const { Option } = Select;

const CreateAccount = () => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [listRole, setListRole] = useState([])
  const navigate = useNavigate()
  const indexTabLocal = localStorage.getItem('indexLocalCreate') || 2
  const passwordUserDf = 'User12345!@#'
  const [errorMess, setErrorMess] = useState()
  const [isCreatedData, setIsCreatedData] = useState(false)

  useEffect(() => {
    const roleLocal = ["admin"]
    const roles = []
    const newRl0 = {
      id: 0,
      value: roleLocal[0],
      label: roleLocal[0]
    }
    roles.push(newRl0)

    setListRole(roles)
  }, [])

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onCreate = async () => {
    setIsCreatedData(true)
    const params = {
      username: form.getFieldValue('username'),
      password: indexTabLocal === '1' ? passwordUserDf : form.getFieldValue('password')
    }

    if (!isCreatedData) {
      await API.post(REGISTER, params).then((response) => {
        if (response.data.data.result) {
          if (indexTabLocal === '2') {
            const result = response.data.data.result
            setRoleForUser(result.id)
          } else {
            const result = response.data.data.result
            if (result?.code === "ER_DUP_ENTRY") {
              setErrorMess('Tên đăng nhập đã tồn tại')
            } else {
              localStorage.setItem('indexTabAcc', indexTabLocal);
              navigate('/account')
            }
          }
        }
        setIsCreatedData(false)
      }).catch(errors =>{ setIsCreatedData(false) })
    }
    
  }

  const setRoleForUser = async (userId) => {
    const params = {
      role: form.getFieldValue('role')
    }

    await API.patch(`${USER}/${userId}/role`, params).then((response) => {
      if (response.data.data.code === 20004) {
        localStorage.setItem('indexTabAcc', indexTabLocal);
        navigate('/account')
      }
      setIsCreatedData(false)
    }).catch(errors =>{ setIsCreatedData(false) })
  }

  const backScreen = () => { localStorage.setItem('indexTabAcc', indexTabLocal); navigate('/account') }

  const renderFormByAdmin = () => {
    return (
      <Form className='form-create' form={form} layout="inline" autoComplete='off' onFinish={onCreate}>
        <div className='col-8'>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0002')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} />
              </Form.Item>
            </div>
          </div>

          <div style={{ marginBottom: 15 }} />

          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0003')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                  {
                    validator: (_, value) => {
                      if (/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*]).{6,32})/.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Some message here');
                      }
                    },
                    message: t('text0005', { 0: t('text0003') }),
                  }
                ]}
                className='col-12'
              >
                <Input.Password
                  min={6}
                  maxLength={32}
                />
              </Form.Item>
            </div>
          </div>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'></div>
            <div className='col-9' style={{ color: 'red' }}>
              NOTE: Mật khẩu từ 6 - 32 ký tự. Bắt buộc có ít nhất ký tự viết hoa, ký tự viết thường, số và ký tự đặc biệt (!@#$%&*)
            </div>
          </div>

          <div style={{ marginBottom: 15 }} />

          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0038')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="role"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Select>
                  {listRole.map((item) => (
                    <Option key={item.id} value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div style={{ marginBottom: 30 }} />

          <Form.Item shouldUpdate style={{ width: '100%' }}>
            {() => (
              <div className='lg-btn'>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                  loading={isCreatedData}
                >
                  {t('text0026')}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    )
  }

  const renderFormByUser = () => {
    return (
      <Form className='form-create' form={form} layout="inline" autoComplete='off' onFinish={onCreate}>
        <div className='col-8'>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0002')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} onChange={() => setErrorMess()} />
              </Form.Item>
            </div>
          </div>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
            <div className='col-3'></div>
            <div className='col-9'>{errorMess}</div>
          </div>
          <div style={{ marginBottom: 30 }} />

          <Form.Item shouldUpdate style={{ width: '100%' }}>
            {() => (
              <div className='lg-btn'>
                <Button
                  ghost
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  {t('text0026')}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    )
  }

  return (
    <div className='col-12'>
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {t('text0034')}
      </Button>
      {
        indexTabLocal === '1' ?
          renderFormByUser() :
          renderFormByAdmin()
      }
    </div>
  )

}

export default CreateAccount;