import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

import {
  Input,
  InputNumber,
  Button,
  DatePicker,
  Switch,
  TimePicker,
  Radio,
  Spin
} from 'antd';
import API, { COMMON } from '../../network/api';
import 'moment-timezone';
import ModalCommon from '../../components/Modal';
const { TextArea } = Input
dayjs.extend(customParseFormat);

const SettingSys = () => {

  const { t } = useTranslation();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [vipUpdate, setVipUpdate] = useState()
  //ev1
  const [event1Recharge, setEvent1Recharge] = useState()
  const [event1_Start, setEvent1_Start] = useState()
  const [event1_End, setEvent1_End] = useState()
  const [rules1, setRules1] = useState()
  const [reward1, setReward1] = useState()
  //ev2
  const [event2_Start, setEvent2_Start] = useState()
  const [event2_End, setEvent2_End] = useState()
  const [date2Start, setDate2Start] = useState()
  const [date2End, setDate2End] = useState()
  const [ev2DtcTurn, setEv2DtcTurn] = useState()
  const [ev2Goal, setEv2Goal] = useState()
  const [ev2Show, setEv2Show] = useState()
  const [multiple, setMultiple] = useState()
  const [listPoint, setListPoint] = useState()
  const [listPointUp, setListPointUp] = useState()
  const [rules2, setRules2] = useState()
  const [reward2, setReward2] = useState()
  //ev3
  const [event3Recharge, setEvent3Recharge] = useState()
  const [event3_Start, setEvent3_Start] = useState()
  const [event3_End, setEvent3_End] = useState()
  const [date3Start, setDate3Start] = useState()
  const [date3End, setDate3End] = useState()
  const [rules3, setRules3] = useState()
  const [reward3, setReward3] = useState()

  // const [commons, setCommons] = useState()
  const [isShowListPoint, setIsShowListPoint] = useState(false)
  const [commonList, setCommonList] = useState([])
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false)
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false)
  const [dataUpdate, setDataUpdate] = useState()
  const [indexTab, setIndexTab] = useState(1)

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    getDataCommon()
  }, []);

  const convertTime = (time = '') => {
    return time.slice(0, 4) + '-' + time.slice(4, 6) + '-' + time.slice(6, 8)
  }

  const revertTime = (time = '') => {
    return time.split('-')[0] + time.split('-')[1] + time.split('-')[2]
  }

  const getDataCommon = async () => {
    const common = API.get(`${COMMON}/all`)

    await Promise.all([common]).then(response => {
      const data = response[0].data.data.result

      if (data.length > 0) {
        // const common1 = data.filter(item => item?.common_key.includes('event1_'))
        // const common2 = data.filter(item => (!item?.common_key.includes('event1_')) && !item?.common_key.includes('event3_'))
        // const common3 = data.filter(item => item?.common_key.includes('event3_'))
        console.log("data", data)
        setCommonList(data)

        const dataCommon = {
          event_vip: data.find(c => c.common_key === `vip_${process.env.REACT_APP_BRAND}`).common_value,
          //ev1
          event1_recharge: data.find(c => c.common_key === "event1_recharge").common_value,
          event1_timeStart_Event: data.find(c => c.common_key === "event1_start_event").common_value,
          event1_timeEnd_Event: data.find(c => c.common_key === "event1_end_event").common_value,
          event1_rule: data.find(c => c.common_key === "event1_text_rule").common_value,
          event1_reward: data.find(c => c.common_key === "event1_text_reward").common_value,
          //ev2
          event2_timeStart_Event: data.find(c => c.common_key === "event2_start_event").common_value,
          event2_timeEnd_Event: data.find(c => c.common_key === "event2_end_event").common_value,
          event2_timeStart: data.find(c => c.common_key === "event2_start").common_value,
          event2_timeEnd: data.find(c => c.common_key === "event2_end").common_value,
          event2_DtcTurn: data.find(c => c.common_key === "event2_rate").common_value,
          event2_pred_reward: data.find(c => c.common_key === "event2_pred_reward").common_value,
          event2_lock_point: data.find(c => c.common_key === "lock_point").common_value,
          event2_multiple: data.find(c => c.common_key === "multiple").common_value,
          event2_listPoint: data.find(c => c.common_key === "list_point").common_value,
          event2_rule: data.find(c => c.common_key === "event2_text_rule").common_value,
          event2_reward: data.find(c => c.common_key === "event2_text_reward").common_value,
          //ev3
          event3_recharge: data.find(c => c.common_key === "event3_recharge").common_value,
          event3_timeStart_Event: data.find(c => c.common_key === "event3_start_event").common_value,
          event3_timeEnd_Event: data.find(c => c.common_key === "event3_end_event").common_value,
          event3_timeStart: data.find(c => c.common_key === "event3_start").common_value,
          event3_timeEnd: data.find(c => c.common_key === "event3_end").common_value,
          event3_rule: data.find(c => c.common_key === "event3_text_rule").common_value,
          event3_reward: data.find(c => c.common_key === "event3_text_reward").common_value,
        }

        setVipUpdate(dataCommon.event_vip)
        //ev1
        setEvent1Recharge(dataCommon.event1_recharge)
        setEvent1_Start(dataCommon?.event1_timeStart_Event)
        setEvent1_End(dataCommon?.event1_timeEnd_Event)
        setRules1(dataCommon?.event1_rule)
        setReward1(dataCommon?.event1_reward)
        //ev2
        setEvent2_Start(dataCommon?.event2_timeStart_Event)
        setEvent2_End(dataCommon?.event2_timeEnd_Event)
        setDate2Start(convertTime(dataCommon?.event2_timeStart))
        setDate2End(convertTime(dataCommon?.event2_timeEnd))
        setEv2DtcTurn(dataCommon?.event2_DtcTurn)
        setEv2Goal(dataCommon?.event2_pred_reward)
        setEv2Show(dataCommon?.event2_lock_point)
        setMultiple(dataCommon?.event2_multiple)
        setListPoint(dataCommon?.event2_listPoint)
        setRules2(dataCommon?.event2_rule)
        setReward2(dataCommon?.event2_reward)
        //ev3
        setEvent3Recharge(dataCommon.event3_recharge)
        setEvent3_Start(dataCommon?.event3_timeStart_Event)
        setEvent3_End(dataCommon?.event3_timeEnd_Event)
        setDate3Start(convertTime(dataCommon?.event3_timeStart))
        setDate3End(convertTime(dataCommon?.event3_timeEnd))
        setRules3(dataCommon?.event3_rule)
        setReward3(dataCommon?.event3_reward)

        // setCommons(dataCommon)
      }
    }).catch(error => {
      console.log(error)
    })
  }
  //Event1
  const onChangeDate_Start_Event1 = (date, dateString) => {
    setEvent1_Start(dateString + ' ' + event1_Start.split(' ')[1])
  };

  const onChangeTime_Start_Event1 = (date, timeString) => {
    timeString = timeString !== '' ? timeString : '00:00'
    setEvent1_Start(event1_Start.split(' ')[0] + ' ' + timeString + ':00')
  };

  const onChangeDate_End_Event1 = (date, dateString) => {
    setEvent1_End(dateString + ' ' + event1_End.split(' ')[1])
  };

  const onChangeTime_End_Event1 = (date, timeString) => {
    timeString = timeString !== '' ? timeString : '00:00'
    setEvent1_End(event1_End.split(' ')[0] + ' ' + timeString + ':00')
  };

  //Event2-----------------------------------------------------
  const onChangeDate_Start_Event2 = (date, dateString) => {

    setEvent2_Start(dateString + ' ' + event2_Start.split(' ')[1])
  };

  const onChangeTime_Start_Event2 = (date, timeString) => {
    timeString = timeString !== '' ? timeString : '00:00'
    setEvent2_Start(event2_Start.split(' ')[0] + ' ' + timeString + ':00')
  };

  const onChangeDate_End_Event2 = (date, dateString) => {

    setEvent2_End(dateString + ' ' + event2_End.split(' ')[1])
  };

  const onChangeTime_End_Event2 = (date, timeString) => {
    timeString = timeString !== '' ? timeString : '00:00'
    setEvent2_End(event2_End.split(' ')[0] + ' ' + timeString + ':00')
  };

  const onChangeDate_Start2 = (date, dateString) => {
    setDate2Start(dateString)
  };
  const onChangeDate_End2 = (date, timeString) => {
    setDate2End(timeString)
  };

  //Event3-----------------------------------------------------
  const onChangeDate_Start_Event3 = (date, dateString) => {
    setEvent3_Start(dateString + ' ' + event3_Start.split(' ')[1])
  };

  const onChangeTime_Start_Event3 = (date, timeString) => {
    timeString = timeString !== '' ? timeString : '00:00'
    setEvent3_Start(event3_Start.split(' ')[0] + ' ' + timeString + ':00')
  };

  const onChangeDate_End_Event3 = (date, dateString) => {
    setEvent3_End(dateString + ' ' + event3_End.split(' ')[1])
  };

  const onChangeTime_End_Event3 = (date, timeString) => {
    timeString = timeString !== '' ? timeString : '23:59'
    setEvent3_End(event3_End.split(' ')[0] + ' ' + timeString + ':59')
  };

  const onChangeDate_Start3 = (date, dateString) => {
    setDate3Start(dateString)
  };

  const onChangeDate_End3 = (date, timeString) => {
    setDate3End(timeString)
  };

  const showModalConfirm = (key, value) => {
    setIsShowModalConfirm(true)
    const data = { key: key, value: value }
    setDataUpdate(data)
  }

  const updateListPoint = async () => {
    if (!listPointUp) {
      setIsShowListPoint(false)
      return
    }

    setLoading(true)
    setIsShowModalConfirm(false)
    const paramCv = {
      common_key: "list_point",
      common_value: listPointUp || listPoint
    }

    await API.patch(`${COMMON}/${commonList.find(c => c.common_key === "list_point").id}`, paramCv)
      .then(response => {
        if (response.data?.data?.code === 20004) {
          setIsShowListPoint(false)
          setLoading(false)
          setIsShowModalSuccess(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const updateCommon = async () => {
    setLoading(true)
    setIsShowModalConfirm(false)
    const paramCv = {
      common_key: dataUpdate.key,
      common_value: 
        (dataUpdate.key === 'event2_start' || dataUpdate.key === 'event2_end') || 
        (dataUpdate.key === 'event3_start' || dataUpdate.key === 'event3_end') ? 
        revertTime(dataUpdate.value) : 
        dataUpdate.value
    }

    await API.patch(`${COMMON}/${commonList.find(c => c.common_key === dataUpdate?.key).id}`, paramCv)
      .then(response => {
        if (response.data?.data?.code === 20004) {
          setLoading(false)
          setIsShowModalSuccess(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const onChangeEvent1Recharge = (value) => {
    setEvent1Recharge(value.toString())
  };

  const onChangeEvent3Recharge = (value) => {
    setEvent3Recharge(value.toString())
  };

  const onChangeDTC = (value) => {
    setEv2DtcTurn(value.toString())
  };

  const onChangeMultiple = (value) => {
    setMultiple(value.toString())
  };

  const onChangeEv2Goal = (value) => {
    setEv2Goal(value.toString())
  };

  const onChangeSwitch = (checked) => {
    setEv2Show(checked ? "1" : "0")
  };

  const onChangeListPoint = (index, type, value) => {
    const dataDf = listPointUp ? listPointUp?.split(", ") : listPoint?.split(", ");
    const dataChange = []
    dataDf?.map((item, idx) => {
      const newIt = idx === index ? 
        type === "goal" ? `${value}-${item.split("-")[1]}-${item.split("-")[2]}` :
          type === "point" ? `${item.split("-")[0]}-${value}-${item.split("-")[2]}` :
            `${item.split("-")[0]}-${item.split("-")[1]}-${value}`
        : item;
      dataChange.push(newIt);
    })
    setListPointUp(dataChange.join(", "))
  };

  const renderUITab1 = () => {

    return (
      <>
        <div className="col-12">
          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">Câu hỏi:</div>
            <span className="col-6 text-primary cursor-pointer hover:underline hover:decoration-primary font-semibold" style={{ display: 'flex' }}
              onClick={() => { navigate('/ev1_question') }}
            >{'Chi tiết câu hỏi >>'}</span>
          </div>

          {/* <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">Câu trả lời:</div>
            <span className="col-6 text-primary cursor-pointer hover:underline hover:decoration-primary font-semibold" style={{ display: 'flex' }}
              onClick={() => { navigate('/ev1_answer') }}
            >{'Chi tiết câu trả lời >>'}</span>
          </div> */}

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">Tổng nạp:</div>
            <div className="col-6" style={{ display: 'flex', }}>
              <InputNumber min={0} max={99999999} style={{ width: '30%' }} defaultValue={Number(event1Recharge)}
                onChange={onChangeEvent1Recharge}
              />
            </div>
            <div className="col-3 font-semibold" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary"
                onClick={() => { showModalConfirm('event1_recharge', event1Recharge) }}
              >
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">{t('text0053')}:</div>
            <div className="col-6" style={{ display: 'flex' }}>
              <DatePicker
                placeholder=""
                value={dayjs(event1_Start.split(' ')[0], 'YYYY-MM-DD')}
                onChange={onChangeDate_Start_Event1}
                format={'YYYY-MM-DD'}
                style={{ width: '65%' }}
                allowClear={false}
              />
              <TimePicker placeholder="" value={dayjs(event1_Start.split(' ')[1], 'HH:mm')} onChange={onChangeTime_Start_Event1} style={{ width: '35%' }} format={'HH:mm'} />
            </div>
            <div className="col-3 font-semibold" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event1_start_event', event1_Start)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">{t('text0054')}:</div>
            <div className="col-6" style={{ display: 'flex' }}>
              <DatePicker
                placeholder=""
                value={dayjs(event1_End.split(' ')[0], 'YYYY-MM-DD')}
                onChange={onChangeDate_End_Event1}
                format={'YYYY-MM-DD'}
                style={{ width: '65%' }}
                allowClear={false}
              />
              <TimePicker placeholder="" value={dayjs(event1_End.split(' ')[1], 'HH:mm')} onChange={onChangeTime_End_Event1} style={{ width: '35%' }} format={'HH:mm'} />
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event1_end_event', event1_End)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">Thể lệ:</div>
            <div className="col-6" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <TextArea
                name="textRule1"
                style={{ width: "100%" }}
                value={rules1}
                onChange={(e) => setRules1(e.target.value)}
                rows={10}
                spellCheck="false"
                autoComplete="off"
              />
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event1_text_rule', rules1)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">Giải thưởng:</div>
            <div className="col-6" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <TextArea
                name="textReward1"
                style={{ width: "100%" }}
                value={reward1}
                onChange={(e) => setReward1(e.target.value)}
                rows={10}
                spellCheck="false"
                autoComplete="off"
              />
              <span >
                *
                : với mỗi ký tự "/n" tương ứng đoạn văn sẽ xuống dòng ở USER
              </span>
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event1_text_reward', reward1)}>
                {t('text0012')}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  const renderUITab2 = () => {
    return (
      <>
        <div className='col-12'>
          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">{t('text0053')}:</div>
            <div className="col-6" style={{ display: 'flex' }}>
              <DatePicker
                placeholder=""
                value={dayjs(event2_Start.split(' ')[0], 'YYYY-MM-DD')}
                onChange={onChangeDate_Start_Event2}
                format={'YYYY-MM-DD'}
                style={{ width: '65%' }}
                allowClear={false}
              />
              <TimePicker placeholder="" value={dayjs(event2_Start.split(' ')[1], 'HH:mm')} onChange={onChangeTime_Start_Event2} style={{ width: '35%' }} format={'HH:mm'} />
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event2_start_event', event2_Start)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">{t('text0054')}:</div>
            <div className="col-6" style={{ display: 'flex' }}>
              <DatePicker
                placeholder=""
                value={dayjs(event2_End.split(' ')[0], 'YYYY-MM-DD')}
                onChange={onChangeDate_End_Event2}
                format={'YYYY-MM-DD'}
                style={{ width: '65%' }}
                allowClear={false}
              />
              <TimePicker placeholder="" value={dayjs(event2_End.split(' ')[1], 'HH:mm')} onChange={onChangeTime_End_Event2} style={{ width: '35%' }} format={'HH:mm'} />
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event2_end_event', event2_End)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">{t('text0055')}:</div>
            <div className="col-6" style={{ display: 'flex' }}>
              <DatePicker
                placeholder=""
                value={dayjs(date2Start, 'YYYY-MM-DD')}
                onChange={onChangeDate_Start2}
                format={'YYYY-MM-DD'}
                style={{ width: '65%' }}
                allowClear={false}
              />
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event2_start', date2Start)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">{t('text0056')}:</div>
            <div className="col-6" style={{ display: 'flex' }}>
              <DatePicker
                placeholder=""
                value={dayjs(date2End, 'YYYY-MM-DD')}
                onChange={onChangeDate_End2}
                format={'YYYY-MM-DD'}
                style={{ width: '65%' }}
                allowClear={false}
              />
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event2_end', date2End)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">DTC quy đổi / lượt dự đoán:</div>
            <div className="col-6" style={{ display: 'flex' }}>
              <InputNumber min={1} max={99999999} style={{ width: '25%' }} defaultValue={Number(ev2DtcTurn)}
                onChange={onChangeDTC} />
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event2_rate', ev2DtcTurn)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">Số bàn thắng dự đoán đúng / trận:</div>
            <div className="col-6" style={{ display: 'flex' }}>
              <InputNumber min={1} max={99999999} style={{ width: '25%' }} value={Number(ev2Goal)}
                onChange={onChangeEv2Goal} />
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event2_pred_reward', ev2Goal)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">Mốc quy đổi điểm:</div>
            <span className="col-6 text-primary cursor-pointer hover:underline hover:decoration-primary font-semibold" style={{ display: 'flex' }}
              onClick={() => setIsShowListPoint(true)}
            >Chi tiết bảng quy đổi</span>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">Khóa nhận điểm:</div>
            <div className="col-6" style={{ display: 'flex' }}>
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                checked={ev2Show === '1' ? true : false}
                style={{ width: 60, marginTop: "5px" }}
                onChange={onChangeSwitch}
              />
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('lock_point', ev2Show)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">Số vòng cược:</div>
            <div className="col-6" style={{ display: 'flex' }}>
              <InputNumber min={1} max={99999999} style={{ width: '25%' }} value={Number(multiple)}
                onChange={onChangeMultiple} />
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('multiple', multiple)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">Thể lệ:</div>
            <div className="col-6" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <TextArea
                name="textRule2"
                style={{ width: "100%" }}
                value={rules2}
                onChange={(e) => setRules2(e.target.value)}
                rows={10}
                spellCheck="false"
                autoComplete="off"
              />
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event2_text_rule', rules2)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

          <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
            <div className="col-3 font-semibold">Giải thưởng:</div>
            <div className="col-6" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <TextArea
                name="textRule2"
                style={{ width: "100%" }}
                value={reward2}
                onChange={(e) => setReward2(e.target.value)}
                rows={10}
                spellCheck="false"
                autoComplete="off"
              />
              <span >
                *
                : với mỗi ký tự "/n" tương ứng đoạn văn sẽ xuống dòng ở USER
              </span>
            </div>
            <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event2_text_reward', reward2)}>
                {t('text0012')}
              </Button>
            </div>
          </div>

        </div >
      </>
    )
  }

  const renderListPointConfig = () => {
    // const onChangeBet_1 = (value) => {
    //   setConst_bet_1(const_bet_1.split(', ')[0] + ', ' + value.toString())
    // };
    // const onChangeBet_2 = (value) => {
    //   setConst_bet_2(const_bet_2.split(', ')[0] + ', ' + value.toString())
    // };
    // const onChangeBet_3 = (value) => {
    //   setConst_bet_3(const_bet_3.split(', ')[0] + ', ' + value.toString())
    // };
    // const onChangeBet_4 = (value) => {
    //   setConst_bet_4(const_bet_4.split(', ')[0] + ', ' + value.toString())
    // };

    return (
      <>
        {/* <div className="col-12" style={{ marginBottom: '12px' }}>
        <div className="col-12" style={{ display: 'flex', margin: '30px 0px', alignItems: 'center' }}>
          <div className="col-3">Số bàn thắng từ DTC:</div>
          <div className="col-9" style={{ display: 'flex', flexDirection: 'rows', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'start', justifyContent: 'space-between', padding: '15px 10px 10px 10px' }}>
              <span>DTC: </span>
              <span>Bàn thắng nhận: </span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'rows', textAlign: 'center', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: '5px' }}>
                <span>
                  <TextArea style={{ width: '75%', textAlign: 'center' }} value={const_bet_1.split(', ')[0]}
                    onChange={(e) => setConst_bet_1(e.target.value + ', ' + const_bet_1.split(', ')[1])} />
                </span>
                <span>
                  <InputNumber min={1} max={99999999} style={{ width: '50%', textAlign: 'center' }} value={Number(const_bet_1.split(', ')[1])}
                    onChange={onChangeBet_1} />
                </span>
              </div>

              <span className="col-3 d-flex" style={{ width: '30%' }}>
                <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('const_bet_1', const_bet_1)}>
                  {t('text0012')}
                </Button>
              </span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'rows', textAlign: 'center', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: '5px' }}>
                <span>
                  <TextArea style={{ width: '75%', textAlign: 'center' }} value={const_bet_2.split(', ')[0]}
                    onChange={(e) => setConst_bet_2(e.target.value + ', ' + const_bet_2.split(', ')[1])} />
                </span>
                <span>
                  <InputNumber min={1} max={99999999} style={{ width: '50%', textAlign: 'center' }} value={Number(const_bet_2.split(', ')[1])}
                    onChange={(e) => onChangeBet_2} />
                </span>
              </div>

              <span className="col-3 d-flex" style={{ width: '30%' }}>
                <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('const_bet_2', const_bet_2)}>
                  {t('text0012')}
                </Button>
              </span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'rows', textAlign: 'center', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: '5px' }}>
                <span>
                  <TextArea style={{ width: '75%', textAlign: 'center' }} value={const_bet_3.split(', ')[0]}
                    onChange={(e) => setConst_bet_3(e.target.value + ', ' + const_bet_3.split(', ')[1])} />
                </span>
                <span>
                  <InputNumber min={1} max={99999999} style={{ width: '50%', textAlign: 'center' }} value={Number(const_bet_3.split(', ')[1])}
                    onChange={onChangeBet_3} />
                </span>
              </div>

              <span className="col-3 d-flex" style={{ width: '30%' }}>
                <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('const_bet_3', const_bet_3)}>
                  {t('text0012')}
                </Button>
              </span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'rows', textAlign: 'center', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: '5px' }}>
                <span>
                  <TextArea style={{ width: '75%', textAlign: 'center' }} value={const_bet_4.split(', ')[0]}
                    onChange={(e) => setConst_bet_4(e.target.value + ', ' + const_bet_4.split(', ')[1])} />
                </span>
                <span>
                  <InputNumber min={1} max={99999999} style={{ width: '50%', textAlign: 'center' }} value={Number(const_bet_4.split(', ')[1])}
                    onChange={onChangeBet_4} />
                </span>
              </div>

              <span className="col-3 d-flex" style={{ width: '30%' }}>
                <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('const_bet_4', const_bet_4)}>
                  {t('text0012')}
                </Button>
              </span>
            </div>
          </div>

        </div>
      </div> */}
        <div className=' col-12 !h-[60vh] overflow-y-auto flex flex-col gap-y-2 py-6 px-4 border-y border-black bg-gray-200'>
          {listPoint?.split(', ').length > 0 && listPoint?.split(', ').map((item, idx) => (
            <div className={`w-full flex flex-row items-center justify-between py-1`}>
              <span className='w-[10%] flex flex-row items-center gap-x-2'>
                <span className='font-semibold'>STT:</span>
                <span>{idx + 1}</span>
              </span>

              <span className='w-1/4 flex flex-col items-center gap-y-1'>
                <span className='font-semibold'>Bàn thắng:</span>
                <InputNumber min={1} max={99999999} style={{ width: '50%' }} defaultValue={Number(item.split('-')[0])}
                  onChange={(e) => onChangeListPoint(idx, "goal", e)} />
              </span>

              <span className='w-1/4 flex flex-col items-center gap-y-1'>
                <span className='font-semibold'>Điểm thưởng:</span>
                <InputNumber min={1} max={99999999} style={{ width: '50%' }} defaultValue={Number(item.split('-')[1])}
                  onChange={(e) => onChangeListPoint(idx, "point", e)} />
              </span>

              <span className={`w-1/4 flex flex-col items-center gap-y-1.5 ${Number(item.split('-')[2]) > 0 ? 'text-[#F9C92A]' : ''}`}>
                <span className={`font-semibold `}>Tổng nạp:</span>
                <InputNumber min={0} max={99999999} style={{ width: '100%', color: 'black' }} defaultValue={Number(item.split('-')[2])}
                  onChange={(e) => onChangeListPoint(idx, "recharge", e)} />
              </span>
            </div>
          ))}
        </div>
      </>)
  }

  const renderUITab3 = () => {
    return (
      <div className='col-12'>
        <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
          <div className="col-3 font-semibold">Tổng nạp:</div>
          <div className="col-6" style={{ display: 'flex', }}>
            <InputNumber min={0} max={99999999} style={{ width: '30%' }} defaultValue={Number(event3Recharge)}
              onChange={onChangeEvent3Recharge}
            />
          </div>
          <div className="col-3 font-semibold" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button htmlType="submit" type="primary"
              onClick={() => { showModalConfirm('event3_recharge', event3Recharge) }}
            >
              {t('text0012')}
            </Button>
          </div>
        </div>

        <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
          <div className="col-3 font-semibold">{t('text0053')}:</div>
          <div className="col-6" style={{ display: 'flex' }}>
            <DatePicker
              placeholder=""
              value={dayjs(event3_Start.split(' ')[0], 'YYYY-MM-DD')}
              onChange={onChangeDate_Start_Event3}
              format={'YYYY-MM-DD'}
              style={{ width: '65%' }}
              allowClear={false}
            />
            <TimePicker placeholder="" value={dayjs(event3_Start.split(' ')[1], 'HH:mm')} onChange={onChangeTime_Start_Event3} style={{ width: '35%' }} format={'HH:mm'} />
          </div>
          <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event3_start_event', event3_Start)}>
              {t('text0012')}
            </Button>
          </div>
        </div>

        <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
          <div className="col-3 font-semibold">{t('text0054')}:</div>
          <div className="col-6" style={{ display: 'flex' }}>
            <DatePicker
              placeholder=""
              value={dayjs(event3_End.split(' ')[0], 'YYYY-MM-DD')}
              onChange={onChangeDate_End_Event3}
              format={'YYYY-MM-DD'}
              style={{ width: '65%' }}
              allowClear={false}
            />
            <TimePicker placeholder="" value={dayjs(event3_End.split(' ')[1], 'HH:mm')} onChange={onChangeTime_End_Event3} style={{ width: '35%' }} format={'HH:mm'} />
          </div>
          <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event3_end_event', event3_End)}>
              {t('text0012')}
            </Button>
          </div>
        </div>

        <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
          <div className="col-3 font-semibold">{t('text0055')}:</div>
          <div className="col-6" style={{ display: 'flex' }}>
            <DatePicker
              placeholder=""
              value={dayjs(date3Start, 'YYYY-MM-DD')}
              onChange={onChangeDate_Start3}
              format={'YYYY-MM-DD'}
              style={{ width: '65%' }}
              allowClear={false}
            />
          </div>
          <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event3_start', date3Start)}>
              {t('text0012')}
            </Button>
          </div>
        </div>

        <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
          <div className="col-3 font-semibold">{t('text0056')}:</div>
          <div className="col-6" style={{ display: 'flex' }}>
            <DatePicker
              placeholder=""
              value={dayjs(date3End, 'YYYY-MM-DD')}
              onChange={onChangeDate_End3}
              format={'YYYY-MM-DD'}
              style={{ width: '65%' }}
              allowClear={false}
            />
          </div>
          <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event3_end', date3End)}>
              {t('text0012')}
            </Button>
          </div>
        </div>

        <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
          <div className="col-3 font-semibold">Cầu thủ:</div>
          <span className="col-6 text-primary cursor-pointer hover:underline hover:decoration-primary font-semibold" style={{ display: 'flex' }}
            onClick={() => { navigate('/player') }}
          >{'Cập nhật danh sách cầu thủ >>'}</span>
        </div>

        <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
          <div className="col-3 font-semibold">Thể lệ:</div>
          <div className="col-6" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <TextArea
              name="textRule3"
              style={{ width: "100%" }}
              defaultValue={rules3}
              onChange={(e) => setRules3(e.target.value)}
              rows={10}
              spellCheck="false"
              autoComplete="off"
            />
          </div>
          <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event3_text_rule', rules3)}>
              {t('text0012')}
            </Button>
          </div>
        </div>

        <div className="col-12" style={{ display: 'flex', marginTop: 30, alignItems: 'center' }}>
          <div className="col-3 font-semibold">Giải thưởng:</div>
          <div className="col-6" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <TextArea
              name="textReward1"
              style={{ width: "100%" }}
              defaultValue={reward3}
              onChange={(e) => setReward3(e.target.value)}
              rows={10}
              spellCheck="false"
              autoComplete="off"
            />
            <span >
              *
              : với mỗi ký tự "/n" tương ứng đoạn văn sẽ xuống dòng ở USER
            </span>
          </div>
          <div className="col-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button htmlType="submit" type="primary" onClick={() => showModalConfirm('event3_text_reward', reward3)}>
              {t('text0012')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='col-12'>
      <div className='col-12' style={{ borderBottom: '1px solid #CCC', paddingBottom: '2rem', marginBottom: '2rem' }}>
        {/* VIP setting */}
        <div className="col-12 flex flex-row items-center mb-6" >
          <div className="col-1 font-semibold">Vip yêu cầu:</div>
          <div className="col-6" style={{ display: 'flex' }}>
            <TextArea
              name="textVip1"
              style={{ width: "100%" }}
              value={vipUpdate}
              onChange={(e) => setVipUpdate(e.target.value)}
              rows={3}
              spellCheck="false"
              autoComplete="off"
            />
          </div>
          <div className="col-1" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button htmlType="submit" type="primary"
              onClick={() => { showModalConfirm(`vip_${process.env.REACT_APP_BRAND}`, vipUpdate) }}
            >
              {t('text0012')}
            </Button>
          </div>
        </div>

        <Radio.Group value={indexTab} onChange={(e) => setIndexTab(e.target.value)}>
          <Radio.Button value={1}>Sự kiện 1</Radio.Button>
          <Radio.Button value={2}>Sự kiện 2</Radio.Button>
          <Radio.Button value={3}>Sự kiện 3</Radio.Button>
        </Radio.Group>
      </div>

      {commonList.length > 0 ?
        <>
          {indexTab === 1 ? renderUITab1() : indexTab === 2 ? renderUITab2() : renderUITab3()}
        </>
        : <Spin />
      }

      {isShowListPoint &&
        <Modal show={isShowListPoint} className='modal-all' onHide={() => setIsShowListPoint(false)}>
          <Modal.Header closeButton className='font-bold'>{'BẢNG QUY ĐỔI'}</Modal.Header>
          <Modal.Body>{renderListPointConfig()}</Modal.Body>
          <Modal.Footer>
            <>
              <Button type="primary" onClick={updateListPoint}>
                {'Cập nhật'}
              </Button>
              <Button type="default" onClick={() => setIsShowListPoint(false)}>
                {'Hủy bỏ'}
              </Button>
            </>
          </Modal.Footer>
        </Modal>
      }

      {isShowModalConfirm &&
        <ModalCommon
          isShow={isShowModalConfirm}
          message={t('text0062')}
          txtBtnAcc={t('text0008')}
          textBtnClose={t('text0023')}
          actionNext={updateCommon}
          close={() => setIsShowModalConfirm(false)}
        />
      }
      {isShowModalSuccess &&
        <ModalCommon
          isShow={isShowModalSuccess}
          message={t('text0063')}
          txtBtnAcc={t('text0007')}
          actionNext={() => {
            setIsShowModalSuccess(false)
            getDataCommon()
          }}
          close={() => {
            setIsShowModalSuccess(false)
            getDataCommon()
          }}
        />
      }
    </div>
  )

}

export default SettingSys;