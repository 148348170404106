import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Table, Select, Spin, Pagination } from 'antd';
import API, { EVENT1, EVENT1_QUESTION, USER_EVENT1  } from '../../network/api';
import moment from 'moment';
import 'moment-timezone';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';

const { Option } = Select;

const PredictHistory1 = () => {

  const auth = JSON.parse(localStorage.getItem('auth'))
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [dateEndFrom, setDateEndFrom] = useState()
  const [dateEndTo, setDateEndTo] = useState()
  const [monthYear, setMonthYear] = useState()
  const [dataFilter, setDataFilter] = useState()
  const [dataList, setDataList] = useState([])
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [listTypeGame, setListTypeGame] = useState([])
  const [loading, setLoading] = useState(false);
  const [listOpQuestion, setListOpQuestion] = useState([])
  const [titleSearch, setTitleSearch] = useState();
  const [numbUserValid, setNumUserValid] = useState();

  const locale = {
    emptyText: t('text0035')
  }

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getListQuestion = async () => {
      await API.get(`${EVENT1_QUESTION}/all`).then(response => {
        const data = response.data?.data?.result;
        if (data) {
          const list = [];
          data.map((item) => {
            list.push({
              id: item.id,
              value: item.title,
              label: item.title,
            })
          })
          setListOpQuestion(list)
        }
        
      }).catch(error => {
        console.log(error)
        setLoading(false)
      })
    }

    getListQuestion()

    const countUserValid = async () => {
      await API.get(`${EVENT1}/report`).then(response => {
        const data = response.data?.data?.result;
        if (data) {
          setNumUserValid(Number(data))
        } else {
          setNumUserValid(0)
        }
      }).catch(error => {
        setNumUserValid(0)
        console.log(error)
        setLoading(false)
      })
    }
    countUserValid();
  }, [])

  useEffect(() => {
    getDataHis()
  }, [page, dataFilter]);

  const getDataHis = async () => {
    setLoading(false)

    let dataKey = {};
    if (dataFilter?.username) {
      dataKey.username = dataFilter?.username
    }

    if (dataFilter?.startDate) {
      dataKey.startDate = dataFilter?.startDate
    }

    if (dataFilter?.endDate) {
      dataKey.endDate = dataFilter?.endDate
    }

    if (dataFilter?.title) {
      dataKey.title = dataFilter?.title
    }

    const keyword = dataFilter ? encodeURIComponent(JSON.stringify(dataKey)) : null
    const URL = `${EVENT1}/search?take=10&skip=${page}&order=DESC&keyword=${keyword}`

    const listData1 = API.get(URL)

    await Promise.all([listData1]).then(response => {
      const res1 = response[0].data?.data?.result[0]
      setTotalRecord(response[0].data?.data?.result[1])

      if (res1) {
        let dataCV = []
        res1?.map((item, index) => {
          let itemGame = {
            key: index,
            id: index,
            username: item?.username || item?.user?.username,
            recharge: Number(item?.recharge),
            predict1: item?.answer1,
            predict2: item?.answer2,
            timeEvent: getTimeEvent(item?.event1Question?.title),
            result: 
              !item?.event1Question?.result1 ? "" :   
              item?.answer1 === item?.event1Question?.result1 &&
              item?.answer2 === item?.event1Question?.result2 ?
              "Đúng" : 'Sai',
            time: item?.createdAt,
            timeUpdate: item?.event1Question?.updatedAt
          }
          dataCV.push(itemGame)
        });
        setDataList(dataCV)

        const typeGame = [
          {
            id: -1,
            value: -1,
            label: t('text0109')
          },
          {
            id: 0,
            value: 0,
            label: t('text0110')
          },
          {
            id: 1,
            value: 1,
            label: t('text0111')
          },
          {
            id: 2,
            value: 2,
            label: t('text0112')
          }
        ]
        setListTypeGame(typeGame)
      }
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const getTimeEvent = (title) => {
    if (!title) return
    const stringTime = title.substring(title.indexOf("(") + 1, title.length - 1);
    let startTime = stringTime.split("-")[0];
    const monthStartTime = Number(startTime.split("/")[1]);
    startTime = `${startTime.replace("/", "-")}${monthStartTime >= 8 ? '-2024' : '-2025'}`
    let endTime = stringTime.split("-")[1];
    const monthEndTime = Number(endTime.split("/")[1])
    endTime = `${endTime.replace("/", "-")}${monthEndTime >= 8 ? '-2024' : '-2025'}`
    return `${startTime} đến ${endTime}`
  }

  const getPredStr = (listFilter, id1, id2) => {
    return listFilter.find(t => t.id === id1)?.name + ', ' + listFilter.find(t => t.id === id2)?.name
  }

  const onSearch = () => {
    let params = {}
    if (form.getFieldValue("usernameFilter")) params.username = form.getFieldValue("usernameFilter").trim();

    if (dateFrom) {
      params.startDate = dateFrom
      if (!dateTo) params.endDate = "2123-12-31"
    }
    if (dateTo) {
      if (!dateFrom) params.startDate= "2024-07-31"
      params.endDate = dateTo
    }

    if (titleSearch) {
      params.title = titleSearch
    }

    setDataFilter(params ? params : undefined)
    setPage(1)
  }

  const tableColums = [
    {
      title: 'STT',
      align: 'center',
      key: 'key',
      dataIndex: 'key',
      width: 100,
      render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>
    },
    {
      title: t('text0002'),
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Thời gian dự đoán',
      key: 'time',
      render: (record) =>
        moment(record?.time).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
    },
    {
      title: 'Thời gian diễn ra sự kiện',
      key: 'timeEvent',
      dataIndex: 'timeEvent',
    },
    {
      title: 'Dự đoán câu trả lời 1',
      dataIndex: 'predict1',
      key: 'predict1'
    },
    {
      title: 'Dự đoán câu trả lời 2',
      dataIndex: 'predict2',
      key: 'predict2'
    },
    {
      title: 'Kết quả chung cuộc',
      dataIndex: 'result',
      key: 'result'
    },
    {
      title: 'Thời gian cập nhật',
      key: 'timeEnd',
      render: (record) =>
        moment(record?.timeUpdate).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
    }
  ]

  const formatData = (record) => {
    // if (record) return

    if (record?.rank) {
      return `Top ${record?.rank} tháng ${record?.month} năm ${record?.year}`;
    } else {
      return listTypeGame?.find(g => g?.value === record?.type)?.label;
    }
  }

  const dollarUS = Intl.NumberFormat("en-US");

  const onChangeDateFrom = (date, dateString) => {
    setDateFrom(dateString)
  }

  const onChangeDateTo = (date, dateString) => {
    setDateTo(dateString)
  }

  const onChangeDateEndFrom = (date, dateString) => {
    setDateEndFrom(dateString)
  }

  const onChangeDateEndTo = (date, dateString) => {
    setDateEndTo(dateString)
  }

  const handleDownloadExcel = async () => {
    setLoading(false)

    let dataKey = {};
    if (dataFilter?.username) {
      dataKey.username = dataFilter?.username
    }

    if (dataFilter?.startDate) {
      dataKey.startDate = dataFilter?.startDate
    }

    if (dataFilter?.endDate) {
      dataKey.endDate = dataFilter?.endDate
    }

    if (dataFilter?.startDateEnd) {
      dataKey.startDateEnd = dataFilter?.startDateEnd
    }

    if (dataFilter?.endDateEnd) {
      dataKey.endDateEnd = dataFilter?.endDateEnd
    }

    const keyword = dataFilter ? encodeURIComponent(JSON.stringify(dataKey)) : null
    const URL = `${EVENT1}/search?take=100000000&skip=1&order=DESC&keyword=${keyword}`

    const listData1 = API.get(URL)

    await Promise.all([listData1]).then(response => {
      const res1 = response[0].data?.data?.result[0]
      setTotalRecord(response[0].data?.data?.result[1])

      if (res1) {
        let dataCV = []
        res1?.map((item) => {
          let itemGame = {
            username: item?.username || item?.user?.username,
            predict1: item?.answer1,
            predict2: item?.answer2,
            timeEvent: getTimeEvent(item?.event1Question?.title),
            result: 
              !item?.event1Question?.result1 ? "" :  
              item?.answer1 === item?.event1Question?.result1 &&
              item?.answer2 === item?.event1Question?.result2 ?
              "Đúng" : 'Sai',
            time: moment(item?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
            timeUpdate: moment(item?.event1Question?.updatedAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss')
          }
          dataCV.push(itemGame)
        });

        exportToExcel(dataCV)
      }
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const exportToExcel = (data) => {
    const currentTime = moment().format("DD-MM-YYYY HH-mm-ss");
    const name = `Lịch sử tham gia sự kiện 1_${currentTime}`;

    const formattedData = data.map(item => ({
      'Tên đăng nhập': item.username,
      'Thời gian dự đoán': item.time,
      'Thời gian diễn ra sự kiện': item.timeEvent,
      'Dự đoán câu trả lời 1': item?.predict1,
      'Dự đoán câu trả lời 2': item?.predict2,
      'Kết quả chung cuộc': item?.result,
      'Thời gian cập nhật': item.timeUpdate,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  const onChangePage = (page) => {
    setPage(page)
  }

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  return (
    <div className='col-12'>
      <div className='col-12'>
        <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
          <div className='col-12' style={{ marginBottom: 8, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="usernameFilter"
                label={t('text0002')}
              >
                <Input
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="titleFind"
                label="Thời gian diễn ra sự kiện:"
              >
                <Select onChange={(e) => setTitleSearch(e)} allowClear>
                  {listOpQuestion.map((item) => (
                    <Option key={item.id} value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className='w-full font-semibold mb-2'>Thời gian dự đoán: </div>
          <div className='col-12' style={{ marginBottom: 8, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="dateFrom"
                label={t('text0032')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateFrom}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                // disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="dateTo"
                label={t('text0033')}
              >
                <DatePicker
                  placeholder=''
                  onChange={onChangeDateTo}
                  format={"YYYY-MM-DD"}
                  style={{ width: '100%' }}
                // disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
          </div>

          <div className='payment-search'>
            <Button htmlType='submit' type="primary">{t('text0031')}</Button>
            {auth?.role === "supper" &&
              <Button style={{ marginLeft: 20 }} onClick={handleDownloadExcel} htmlType='button' type="primary">{t('text0050')}</Button>
            }
          </div>
        </Form>
      </div>
      <div className='col-12' style={{ margin: '15px 0px', fontWeight: 600 }}>
        <div className='col-12 flex'>
          <div className='col-4'>
            <p>{`Tổng số HV tham gia SK1: ${numbUserValid}`}</p>
          </div>
          <div className='col-4'>
            <p>{`Tổng số đáp án đã trả lời: ${totalRecord * 2}`}</p>
          </div>
        </div>
      </div>
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
          <Table
            key='miniGameHis'
            locale={locale}
            columns={tableColums}
            dataSource={dataList}
            pagination={false}
            bordered
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </Spin>
    </div>
  )
}

export default PredictHistory1;