import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "./style.css";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Select } from 'antd';
import API, { LEAGUE, PLAYER, TEAM } from '../../network/api';

const CreatePlayer = () => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [errorMess, setErrorMess] = useState()
  const [dataTeam, setDataTeam] = useState()
  const [teamId, setTeamId] = useState()
  const [isCreatedData, setIsCreatedData] = useState(false)

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getAllTeam = async () => {
      await API.get(`${TEAM}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id,
              value: data?.id,
              label: data?.name
            }
            dataCV.push(newI)
          })
          setDataTeam(dataCV);
        } else {
          setDataTeam([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    getAllTeam();
  }, []);

  const onCreate = async () => {
    setIsCreatedData(true)

    if (!isCreatedData) {
      const params = {
        teamId: teamId,
        name: form.getFieldValue('name'),
        // image: form.getFieldValue('image')
      }
  
      await API.post(`${PLAYER}/create`, params).then((response) => {
        const result = response.data.data.result;
        if (result) {
          if (result?.id) {
            navigate('/player')
          } else {
            const result = response.data.data.result
            if (result?.code === "ER_DUP_ENTRY") {
              setErrorMess('Tên cầu thủ đã tồn tại')
            }
          }
        }
        setIsCreatedData(false)
      }).catch(errors => {setIsCreatedData(false)})
    }
  }

  const backScreen = () => { navigate('/player') }

  const handleChangeTeam = (value) => {
    setTeamId(value)
  }

  const renderForm = () => {
    return (
      <Form className='form-create' form={form} layout="inline" autoComplete='off' onFinish={onCreate}>
        <div className='col-8'>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0074')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="teams"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Select
                  allowClear
                  showSearch
                  style={{ width: '100%' }}
                  placeholder=""
                  onChange={handleChangeTeam}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={dataTeam}
                />
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} />

          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0076')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} onChange={() => setErrorMess()} />
              </Form.Item>
            </div>
          </div>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
            <div className='col-3'></div>
            <div className='col-9'>{errorMess}</div>
          </div>
          <div style={{ marginBottom: 20 }} />

          {/* <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0066')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="image"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 30 }} /> */}

          <Form.Item shouldUpdate style={{ width: '100%' }}>
            {() => (
              <div className='lg-btn'>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                  loading={isCreatedData}
                >
                  {t('text0026')}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    )
  }

  return (
    <div className='col-12'>
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {t('text0034')}
      </Button>
      {
        renderForm()
      }
    </div>
  )

}

export default CreatePlayer;