import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "./style.css";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Select } from 'antd';
import API, { TEAM, PLAYER } from '../../network/api';

const UpdatePlayer = () => {

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [dataDetail, setDataDetal] = useState()
  const [errorMess, setErrorMess] = useState()
  const { id } = useParams();
  const [dataTeam, setDataTeam] = useState()
  const [teamId, setTeamId] = useState()
  const [leagueNameDf, setLeagueNameDf] = useState()
  const [isCreatedData, setIsCreatedData] = useState(false)

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    const getAllTeam = async () => {
      await API.get(`${TEAM}/all`).then(response => {
        const result = response.data?.data?.result;
        if (result.length > 0) {
          const dataCV = [];
          result.map((data) => {
            const newI = {
              id: data?.id, 
              value: data?.id,
              label: data?.name
            }
            dataCV.push(newI)
          })

          setDataTeam(dataCV);
        } else {
          setDataTeam([]);
        }
      }).catch(error => {
        console.log(error)
      })
    }

    const getDetail = async () => {
      await API.get(`${PLAYER}/${id}`).then((response) => {
        const result = response.data.data.result;
        if (result) {
          setDataDetal(result)
          form.setFieldValue("name", result?.name)
          setTeamId(result?.team?.id)
          getAllTeam();
          form.setFieldValue("team", result?.team?.id)
        }
      })
    }

    getDetail();
  }, []);

  const onUpdate = async () => {
    setIsCreatedData(true)

    if (!isCreatedData) {
      const params = {
        teamId: teamId,
        name: form.getFieldValue('name'),
      }

      await API.patch(`${PLAYER}/${id}`, params).then((response) => {
        const data = response.data?.data; 
        if (data?.code === 20004) {
            navigate('/player')
        } else {
          const result = response.data.data.result
          if (result?.code === "ER_DUP_ENTRY") {
            setErrorMess('Tên cầu thủ đã tồn tại')
          }
        }
        setIsCreatedData(false)
      }).catch(errror => setIsCreatedData(false))
    }
  }

  const backScreen = () => { navigate('/player') }

  const handleChangeTeam = (value) => {
    // if (value.length > 0) {
    //   const leagueIdStr = [] 
    //   value.map(v => {
    //     const findLeague = dataLeague.find(l => l.value === v)
    //     leagueIdStr.push(Number(findLeague?.id))
    //   })     
    //   setLeagueId(leagueIdStr.join(", "))
    // }
    setTeamId(value)
  }

  const renderForm = () => {
    return (
      <Form className='form-create' form={form} layout="inline" autoComplete='off' onFinish={onUpdate}>
        <div className='col-8'>
        <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0065')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="team"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                {dataTeam && <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder=""
                  onChange={handleChangeTeam}
                  // defaultValue={leagueNameDf}
                  options={dataTeam}
                />}
              </Form.Item>
            </div>
          </div>
          <div style={{ marginBottom: 20 }} />

          <div className='col-12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='col-3'>
              {t('text0076')} <span style={{ color: 'red' }}>*</span>
            </div>
            <div className='col-9'>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('text0004'),
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} onChange={() => setErrorMess()} />
              </Form.Item>
            </div>
          </div>
          <div className='col-12' style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
            <div className='col-3'></div>
            <div className='col-9'>{errorMess}</div>
          </div>

          <div style={{ marginBottom: 30 }} />

          <Form.Item shouldUpdate style={{ width: '100%' }}>
            {() => (
              <div className='lg-btn'>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    // !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                  loading={isCreatedData}
                >
                  {t('text0026')}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    )
  }

  return (
    <div className='col-12'>
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {t('text0034')}
      </Button>
      {
        renderForm()
      }
    </div>
  )

}

export default UpdatePlayer;