import React, { useState, useEffect } from 'react'
import { Form, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const Event1Result = () => {
    const auth = JSON.parse(localStorage.getItem('auth'))
    const { t } = useTranslation();
    const [listGame, setListGame] = useState([])
    const [resultUpdate, setresultUpdate] = useState()
    const [page, setPage] = useState(1);
    const [form] = Form.useForm();
    const [dataFilter, setDataFilter] = useState()

    const onSearch = () => {
        let params = {}
        setPage(1)
        // if () params. = leagueId;
        // if (teamId) params.teamId = teamId;

        setDataFilter(params ? params : undefined)
    }

    const renderEvent1Result = () => {
        return (
            <>
                <div className='col-12'>
                    <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
                        <div className='col-12' style={{ marginBottom: 15, display: 'flex', alignItems: 'baseline' }}>
                            <div className='col-4'>
                                <Form.Item
                                    name="weekFilter"
                                    label={'Tuần sự kiện'}
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder=""
                                        optionFilterProp="children"
                                        onChange={(e) => { }}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={[]}
                                    />
                                </Form.Item>
                            </div>
                            <div className='col-4'>
                                <Form.Item
                                    name="dateFilter"
                                    label={'Ngày'}
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder=""
                                        optionFilterProp="children"
                                        onChange={(e) => { }}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={[]}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='payment-search'>
                            <Button htmlType='submit' type="primary">{t('text0031')}</Button>

                            {auth?.role === "supper" &&
                                <span style={{ marginLeft: '5px' }} onClick={() => { }}>
                                    <Button ghost type="primary">Lấy kết quả trận đấu</Button>
                                </span>}
                        </div>
                    </Form>
                </div>
            </>
        )
    }

    return (
        <div>
            {renderEvent1Result()}
        </div>
    )
}

export default Event1Result
